import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ExcelJS from 'exceljs';
function Patientdetails() {
  const [fromDate, setfromtDate] = useState();
  const [toDate, settoDate] = useState();
  const [patientList, setpatientListData] = useState([]);
  const downloadName = "patientList";
  const [count, setCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [filterName, setFilterInput] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const [pageOffset, setPageOffset] = useState(0);
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  //function
  const getpatientsList = () => {
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
      cmbSelect: filterName,
      txtInput: searchValue,
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("reports/patientReport", PostData).then((response) => {
      if (response.data.success === "1") {
        setpatientListData(response.data.result.patientList);
        setCount(response.data.result.count);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    getpatientsList();
  }, []);
  //searchfunction
  const searchFunction = () => {
    var toastShown = false;
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        if (!toastShown) {
          toast.error('To date should be greater than or equal to from date');
          document.getElementById("todate").focus()
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    if (filterName !== "" && searchValue === "") {
      if (!toastShown) {
        var t = document.getElementById("selectFilterName");
        var selectedText = t.options[t.selectedIndex].text;
        document.getElementById("searchText").focus();
        toast.error("Enter" + " " + selectedText);
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    setbooliansearchValue(searchValue);
    setboolianfilterName(filterName);
    setboolianfromDate(fromDate);
    setbooliantoDate(toDate);
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: 10,
      offset: 0,
      cmbSelect: filterName,
      txtInput: searchValue,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
      hospitalId: localStorage.getItem("hospitalId"),
    };
    // setDataFlag(false);
    callingAPI("reports/patientReport", PostData).then((response) => {
      if (response.data.success === "1") {
        setpatientListData(response.data.result.patientList);
        setCount(response.data.result.count);
        setDataFlag(true);
        setActivePage(1);
      } else if (response.data.success === "0") {
        setpatientListData([]);
        setActivePage(0);
      }
    });
  };
  //Date functions
  function formatDate(date) {
    var dates = new Date(date),
      month = "" + (dates.getMonth() + 1),
      day = "" + dates.getDate(),
      year = dates.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  //list function
  function patientListDownload() {
    var toastShown = false;
    if (patientList.length === 0) {
      if (!toastShown) {
        toast.error("No Data to Export");
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    else {
      const csvRow = [];
      if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
        var patientlists = [

          [
            "Patient%20Name",
            "Registration%20Date",
            "MR%20Number",
            "Mobile%20Number",
            "Email",
          ],
        ];
      }
      else {
        var patientlists = [

          [
            "Patient%20Name",
            "Registration%20Date",
            "PR%20Number",
            "Mobile%20Number",
            "Email",
          ],
        ];
      }
      var name = patientlists;
      let PostData = {
        userId: window.sessionStorage.getItem("userId"),
        role: window.sessionStorage.getItem("role"),
        max: '',
        offset: 0,
        cmbSelect: filterName,
        txtInput: searchValue,
        hospitalId: localStorage.getItem("hospitalId"),
      };
      callingAPI("reports/patientReport", PostData).then((response) => {

        if (response.data.success === '1') {
          for (var item = 0; item < response.data.result.patientList.length; item++) {
            if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
              patientlists.push([
                response.data.result.patientList[item].patientName.replace(/ /g, '%20'),
                response.data.result.patientList[item].secUserRegistrationDate.replace(/[^a-zA-Z0-9]/g, "-"),
                response.data.result.patientList[item].memberMrNo,
                response.data.result.patientList[item].secUsermobNumber,
                response.data.result.patientList[item].secUseremail,
              ]);
            }
            else {
              patientlists.push([
                response.data.result.patientList[item].patientName.replace(/ /g, '%20'),
                response.data.result.patientList[item].secUserRegistrationDate.replace(/[^a-zA-Z0-9]/g, "-"),
                response.data.result.patientList[item].memberPrNo,
                response.data.result.patientList[item].secUsermobNumber,
                response.data.result.patientList[item].secUseremail,
              ]);
            }
          }
        }
        for (var i = 0; i < patientlists.length; ++i) {
          csvRow.push(patientlists[i].join(","));
        }
        var csvString = csvRow.join("%0A");
        var creat = document.createElement("a");
        creat.href = "data:attachment/csv," + csvString;
        creat.target = "_Blank";
        creat.download = downloadName + ".csv";
        document.body.appendChild(creat);
        creat.click();
      })
    }

  }

  //pagegination function
  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) { setFilterInput("") }
    if (!booliansearchValue) { setsearchValue("") }
    if (!boolianfromDate) { setfromtDate("") }
    if (!booliantoDate) { settoDate("") }
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: max,
      offset: offset,
      cmbSelect: boolianfilterName,
      txtInput: booliansearchValue,
      fromDate: boolianfromDate ? formatDate(fromDate) : "",
      toDate: booliantoDate ? formatDate(toDate) : "",
      hospitalId: localStorage.getItem("hospitalId"),
    };

    callingAPI("reports/patientReport", PostData).then((response) => {
      if (response.data.success === "1") {
        setpatientListData(response.data.result.patientList);
        setCount(response.data.result.count);
      }
    });
  };
  //filter function
  const filterByPatient = (value) => {
    var toastShown = false;
    if (filterName == "3" && value !== "") {
      if (/[^0-9.]/.test(value)) {
        if (!toastShown) {
          toast.error('Only Numbers Accepted');
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;

      }

    }
    setsearchValue(value);
  };
  //change text
  const changeText = (value) => {
    setFilterInput(value);
    setsearchValue("");
    if (value === "") {
      setsearchValue("");
      document.getElementById("searchText").disabled = true;
    } else {
      document.getElementById("searchText").disabled = false;
      document.getElementById("searchText").focus();
    }
  };
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchFunction();
    }
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const FromDateClear = () => {
    setfromtDate("")
    //document.getElementById("dashboardCover").click()
    // document.getElementById("fromdate").style.display ='none'
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>

        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  const ToDateClear = () => {
    settoDate("")
    // document.getElementById("fromdate").style.display ='none'
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  return (
    <div className="dashboardCover patientDetailsTable" id="dashboardCover">
      <ToastContainer position="bottom-right" />
      <div className="dashboardTitle">Patient Details</div>
      {dataFlag == false ? (
        <div align="center">
          <Loader />
        </div>
      ) : (
        <div>
          {dataFlag == true && (
            <div className="dashboardContent">
              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
              <div className="reportWrap">
                <div className="dashboardSearch">
                  <div className="row">
                    <div className="col-md-2 dashboardSearchList">
                      <select
                        className="inputField"
                        id="selectFilterName"
                        name="selectFilterName"
                        value={filterName}
                        onKeyDown={onKeyDown}
                        onChange={(e) => changeText(e.target.value)}
                      >
                        <option value="">Search In</option>
                        <option value="1">Patient Name</option>
                        {window.sessionStorage.getItem('isPrNumEnabled') === "false" && (<option value="2">MR Number</option>)}
                        <option value="3">Mobile Number</option>
                        <option value="4">Email</option>
                        {window.sessionStorage.getItem('isPrNumEnabled') === "true" && (<option value="5">PR Number</option>)}
                      </select>
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      {filterName == "" ? (
                        <input
                          type="text"
                          className="inputField"
                          value={searchValue}
                          autoComplete='off'
                          onKeyDown={onKeyDown}
                          onChange={(e) => filterByPatient(e.target.value)}
                          id="searchText"
                          style={{ background: "transparent" }}
                          name="searchText"
                          disabled
                        />
                      ) : (
                        <input
                          type="text"
                          className="inputField"
                          value={searchValue}
                          autoComplete='off'
                          onKeyDown={onKeyDown}
                          onChange={(e) => filterByPatient(e.target.value)}
                          id="searchText"
                          name="searchText"
                          maxLength={filterName == "3" && "10"}
                          autofocus="autofocus"
                        // disabled
                        />
                      )} </div>
                    <div className="col-md-2 dashboardSearchList">
                      {
                        <div>
                          <DatePicker
                            className="inputField"
                            autoComplete='off'
                            selected={fromDate}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="From"
                            maxDate={new Date()}
                            onChange={(date) => setfromtDate(date)}
                            onKeyDown={onKeyDown}
                            id="fromdate"
                            showMonthDropdown
                            showYearDropdown
                            readonly="readonly"
                            dropdownMode="select"
                            calendarContainer={MyContainer}
                            onChangeRaw={handleDateChangeRaw}
                          />
                          <label
                            className="icon iconCalendar calendar plReportsCalendar"
                            style={{ fontSize: "16px" }}
                            htmlFor="fromdate"
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      {
                        <div>
                          <DatePicker
                            className="inputField"
                            selected={toDate}
                            dateFormat="dd-MM-yyyy"
                            placeholderText="To"
                            maxDate={new Date()}
                            onChange={(date) => settoDate(date)}
                            onKeyDown={onKeyDown}
                            id="todate"
                            showMonthDropdown
                            autoComplete='off'
                            showYearDropdown
                            dropdownMode="select"
                            calendarContainer={MyContainerTo}
                            onChangeRaw={handleDateChangeRaw}
                          />
                          <label
                            className="icon iconCalendar calendar plReportsCalendar"
                            style={{ fontSize: "16px" }}
                            htmlFor="todate"
                          >
                            <FontAwesomeIcon icon={faCalendarAlt} />
                          </label>
                        </div>
                      }
                    </div>
                    <div className="col-md-2 dashboardSearchList">
                      <span
                        className="dashButtonBorder"
                        onClick={searchFunction}
                        onKeyDown={onKeyDown}
                      >
                        Search
                      </span>
                    </div>
                    {/* {!patientList.length ? (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey 111"
                          onClick={noSearchData}
                        >
                          Export
                        </button>
                      </div>
                    ) : (
                      <div className="col-md-2 dashboardSearchList">
                        <button
                          type="button"
                          style={{ width: "100%" }}
                          onClick={patientListDownload}
                          id="export"
                          className="dashButtonBorder dashButtonBorderGrey 222"
                        >
                          Export
                        </button>
                      </div>
                    )} */}
                    <div className="col-md-2 dashboardSearchList">
                      <button
                        type="button"
                        style={{ width: "100%" }}
                        onClick={patientListDownload}
                        id="export"
                        className="dashButtonBorder dashButtonBorderGrey "
                      >
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="searchCount">Total Patients : {count}</div>
                </div>
                <div className="dashboardtable">
                  <table>
                    {patientList.length !== -0 && (
                      <thead>
                        <tr>
                          <th>Patient Name</th>
                          {window.sessionStorage.getItem("role") !==
                            "ROLE_HOSPITALADMIN" ? (
                            <th>Hospital Name</th>
                          ) : (
                            ""
                          )}
                          <th >Registration Date</th>
                          {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<th>MR Number</th>) :
                            (<th>PR Number</th>)}
                          <th style={{ width: '135px' }} >Mobile Number</th>
                          <th>Email</th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {patientList.map((list, i) => (
                        <tr>
                          <td>{list.patientName}</td>
                          {window.sessionStorage.getItem("role") !==
                            "ROLE_HOSPITALADMIN" ? (
                            <td>{list.hospitalName}</td>
                          ) : (
                            ""
                          )}
                          <td>{list.secUserRegistrationDate}</td>
                          {window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<td>{list.memberMrNo}</td>) :
                            (<td>{list.memberPrNo}</td>)}
                          <td style={{ width: '135px' }} >{list.secUsermobNumber}</td>
                          <td>{list.secUseremail}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              {count > 10 && (
                <div className="paginationSection">
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                    totalItemsCount={count}
                    pageRangeDisplayed={process.env.REACT_APP_PAGINATION_COUNT}
                    onChange={handlePageChange}
                    //hideNavigation
                    hideDisabled
                  />
                </div>
              )}
            </div>
          )}
          {patientList.length == 0 && dataFlag == true && <NoData />}
        </div>
      )}
    </div>
  );
}
export default Patientdetails;

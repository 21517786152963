import React, { useState, useEffect } from 'react';
import Add from '../images/icons/add.png';
import Close from '../images/icons/close-sm.png';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import Active from '../images/icons/tick.png';
import InActive from '../images/icons/inactive.png';
import Pagination from 'react-js-pagination';
import { callingAPI, callingDeleteAPI } from '../config';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import $ from "jquery";
import zIndex from '@material-ui/core/styles/zIndex';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddLab() {
    const [addLabList, setAddLabList] = useState([]);
    const [contactName, setContactName] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [addLabListArray, setAddLabListArray] = useState([]);
    const [labName, setLabName] = useState("");
    const [labId, setLabId] = useState("");
    const [pageCount, setPageCount] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [pageOffset, setPageOffset] = useState(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const [dataFlag, setDataFlag] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);


    useEffect(() => {
        setActivePage(Number(window.sessionStorage.getItem("labTestactivePage")));
        addLabListing();
    }, []);

    const paginationOnChange = (pageNumber) => {

        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;

        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;

        }
        setActivePage(pageNumber);
        // setPageOffset(offset)
        // setActivePage(pageNumber);
        window.sessionStorage.setItem("labTestactivePage", pageNumber)
        window.sessionStorage.setItem('labTestoffset', offset)
        let postData = {
            dentalLabId: "",
            hospitalId: localStorage.getItem("hospitalId"),
            dentalLabName: "",
            dentalLabIsActive: "",
            dentalLabContactNumber: "",
            dentalLabContactName: "",
            max: max,
            offset: offset
        }
        callingAPI('commonUserFunctions/getDentalLabContactDetails', postData).then((response) => {

            if (response.data.success === "1") {
                setAddLabList(response.data.result.dentalLabDetails)
                setPageCount(response.data.result.totalCount)
                setDataFlag(true);
            }
            else {
                setDataFlag(true);
                setAddLabList([]);
            }

        })
    };

    const addLabListing = () => {
        let postData = {
            dentalLabId: "",
            hospitalId: localStorage.getItem("hospitalId"),
            dentalLabName: "",
            dentalLabIsActive: "",
            dentalLabContactNumber: "",
            dentalLabContactName: "",
            max: 10,
            offset: window.sessionStorage.getItem("labTestoffset")
        }
        callingAPI('commonUserFunctions/getDentalLabContactDetails', postData).then((response) => {

            if (response.data.success === "1") {
                setDataFlag(true);
                setAddLabList(response.data.result.dentalLabDetails)
                setPageCount(response.data.result.totalCount)
            }

            else {
                setDataFlag(true);
                setAddLabList([]);
            }

        })
    }

    const labSearch = () => {
        setActivePage(1)
        let postData = {
            dentalLabId: "",
            hospitalId: localStorage.getItem("hospitalId"),
            dentalLabName: document.getElementById('searchValue').value,
            dentalLabIsActive: "",
            dentalLabContactNumber: "",
            dentalLabContactName: "",
            max: process.env.REACT_APP_PAGINATION_COUNT,
            offset: 0
        }
        callingAPI('commonUserFunctions/getDentalLabContactDetails', postData).then((response) => {

            if (response.data.success === "1") {
                setDataFlag(true);
                setAddLabList(response.data.result.dentalLabDetails)
                setPageCount(response.data.result.totalCount)

                // window.sessionStorage.setItem("labTestactivePage", 1)
                // window.sessionStorage.setItem('labTestoffset', 0)
            }
            else {
                setDataFlag(true);
                setAddLabList([]);
            }

        })
    }

    const onChangeContactName = (event) => {
        setContactName(event.target.value);
    };

    const onChangeContactNumber = (event) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        const contactNumber = numericValue.slice(0, 10);
        setContactNumber(contactNumber)
    }
    const onChangeLabName = (event) => {
        setLabName(event.target.value)
    }

    const addRow = (e, index) => {
        var toastShown = false;
        var phNum = /^\d{10}$/;
        if (!contactName) {
            if (!toastShown) {
                toast.error("Enter Contact Name")
                document.getElementById('contactName').focus();
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        else if (!contactNumber) {
            if (!toastShown) {
                toast.error("Enter Contact Number")
                document.getElementById('contactNumber').focus();
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        else if (!contactNumber.match(phNum)) {
            if (!toastShown) {
                toast.error("Enter a Valid Mobile Number")
                document.getElementById('contactNumber').focus();
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        var addArray = [...addLabListArray]
        var contactDetails = {

            contactName: contactName,
            contactNumber: contactNumber,
            isDefault: "0"

        };
        addArray.push(contactDetails)
        setAddLabListArray(addArray)
        setContactName("");
        setContactNumber("");


    }

    const removeRow = (e, i) => {
        let appendedArray = [...addLabListArray];
        appendedArray.splice(i, 1);
        setAddLabListArray(appendedArray);
    };


    const addLabClick = () => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
    }
    const saveLab = () => {
        setIsButtonDisabled(true);
        var phNum = /^\d{10}$/;
        var toastShown = false;
        if (!labName) {
            if (!toastShown) {
                toast.error("Enter Lab Name")
                document.getElementById('labName').focus();
                setIsButtonDisabled(false);
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;

        }
        else if (!contactName) {
            if (labId === "") {
                if (!toastShown) {
                    toast.error("Enter Contact Name")
                    document.getElementById('contactName').focus();
                    setIsButtonDisabled(false);
                    toastShown = true;
                    setTimeout(() => {
                        toastShown = false;
                    }, 3000);
                }
                return;
            }
        }
        else if (!contactNumber) {
            if (labId === "") {
                if (!toastShown) {
                    toast.error("Enter Contact Number")
                    document.getElementById('contactNumber').focus();
                    setIsButtonDisabled(false);
                    toastShown = true;
                    setTimeout(() => {
                        toastShown = false;
                    }, 3000);
                }
                return;
            }
        }
        else if (!contactNumber.match(phNum)) {
            if (!toastShown) {
                toast.error("Enter a Valid Mobile Number")
                document.getElementById('contactNumber').focus();
                setIsButtonDisabled(false);
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        var appendedArray = [...addLabListArray]
        if (contactName !== "" || contactNumber !== "") {
            var contactDetails = {

                contactName: contactName,
                contactNumber: contactNumber,
                isDefault: "0"

            };
            appendedArray.push(contactDetails)
        }
        if (!contactNumber && !contactName && appendedArray.length === 0) {
            if (!toastShown) {
                toast.error("Enter Contact Name")
                document.getElementById('contactName').focus();
                setIsButtonDisabled(false);
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        let postData = {
            dentalLabId: labId,
            dentalLabName: labName,
            dentalLabIsActive: true,
            userId: window.sessionStorage.getItem("userId"),
            hospitalId: localStorage.getItem('hospitalId'),
            dentalLabContactDetails: appendedArray,

        }

        callingAPI('commonUserFunctions/saveDentalLabContactDetails', postData).then((response) => {

            if (response.data.success === "1") {
                if (labId === "") {
                    toast.success("Saved Successfully")
                    setIsButtonDisabled(false);
                }
                else {
                    toast.success("Updated Successfully")
                    setIsButtonDisabled(false);
                }
                addLabListing();
                closePopup();
                setLabName("");
                setContactName("");
                setContactNumber("");
                setAddLabListArray([]);
                setLabId("");
            }
            else {
                toast.error(response.data.errorMessage)
                setLabName("");
            }

        })
    }

    const editLabClick = (data) => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setLabName(data.dentalLabName);

        setAddLabListArray(data.dentalLabContactDetails)
        setLabId(data.dentalLabId)
    }
    const closePopup = () => {
        $("#plpopup1").css("visibility", "hidden");
        $("#plpopup1").css("opacity", "0");
        setLabName("");
        setContactName("");
        setContactNumber("");
        setAddLabListArray([]);
        setLabId("");
    }
    const labClear = () => {
        document.getElementById("searchValue").value = "";
        addLabListing();
    }
    const ActiveInActiveClick = (id, status) => {
        var x = false;
        if (status === true) {
            x = window.confirm('Do you want to Disable this?');
        } else {
            x = window.confirm('Do you want to Enable this?');
        }
        if (x === true) {
            const postData = {
                dentalLabId: id,
                dentalLabStatus: status == true ? false : true,
            };
            callingAPI('commonUserFunctions/dentalLabChangeStatus', postData).then((response) => {

                if (response.data.success === "1") {
                    addLabListing();
                }

            })
        }
    }

    return (
        <div className="dashboardCover">
            <ToastContainer position="bottom-right" style={{ zIndex: "999999999" }} />
            <div className="row">
                <div className="col-md-10">
                    <div className="dashboardTitle">Add Lab</div>
                </div>

                <div className="col-md-2">
                    <div onClick={() => { addLabClick(); }} style={{ color: '#fff', textDecoration: 'none' }}>
                        <span
                            className="dashButtonBorder"
                            style={{ background: '#22a2fd', border: 'none', color: '#fff' }}
                        >
                            Add New Lab
                        </span>
                    </div>
                </div>
            </div>
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <input
                                    id="searchValue"
                                    type="text"
                                    className="inputField"
                                    placeholder='Enter Lab Name'

                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span className="dashButtonBorder" title="Search" onClick={() => labSearch()}>
                                    Search
                                </span>
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span className="dashButtonBorder" title="Search" onClick={() => labClear()}>
                                    Clear
                                </span>
                            </div>
                        </div>
                    </div>
                    {pageCount > 10 && (
                        <div className="paginationSection">
                            <Pagination
                                hideDisabled
                                activePage={activePage}
                                itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                                totalItemsCount={pageCount}
                                pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                                onChange={paginationOnChange}
                            />
                        </div>
                    )}
                    {dataFlag == false ? (
                        <Loader />
                    ) : addLabList.length > 0 ? (
                        <div className="dashboardtable">
                            <table>
                                <thead style={{ borderBottom: "1px solid #d4d4d4" }}>
                                    <th>Lab Name</th>
                                    <th>Contact Name</th>
                                    <th>Contact Number</th>
                                    <th style={{ width: "10%" }}></th>
                                </thead>
                                <tbody style={{ border: "1px solid #d4d4d4" }}>
                                    {addLabList.map((data, index) =>
                                        <tr style={{ borderBottom: "1px solid #d4d4d4" }}>
                                            <td className='vertical-middle'>{data.dentalLabName}</td>
                                            <td className='vertical-middle'>
                                                {data.dentalLabContactDetails.map((item) => (
                                                    <tr className='innerRow-lab'>
                                                        <td>{item.contactName}</td>
                                                    </tr>
                                                )
                                                )}
                                            </td>
                                            <td className='vertical-middle'>
                                                {data.dentalLabContactDetails.map((item) => (
                                                    <tr className='innerRow-lab'>
                                                        <td>{item.contactNumber}</td>
                                                    </tr>
                                                )
                                                )}
                                            </td>
                                            <td className='vertical-middle' style={{ width: "10%" }}>
                                                <img src={Editicon} alt="Edit" className='img-fluid' title='Edit' onClick={() => { editLabClick(data); }} />
                                                {data.dentalLabIsActive === 1 ? (
                                                    <img src={Active} alt="Active" className='img-fluid' width={20} title='Click to InActivate' onClick={() => { ActiveInActiveClick(data.dentalLabId, true); }} />
                                                ) : (
                                                    <img src={InActive} alt="InActive" className='img-fluid' width={20} title='Click to Activate' onClick={() => { ActiveInActiveClick(data.dentalLabId, false); }} />
                                                )}
                                            </td>
                                        </tr>
                                    )}


                                </tbody>
                            </table>


                            <br />
                            {pageCount > 10 && (
                                <div className="paginationSection">
                                    <Pagination
                                        activePage={activePage}
                                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                                        totalItemsCount={pageCount}
                                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                                        onChange={paginationOnChange}
                                    />
                                </div>
                            )}


                        </div>
                    ) : (
                        <p>
                            <NoData />
                        </p>
                    )}
                </div>
            </div>
            {/* Popup Begins */}
            <div id="plpopup1" class="ploverlay">
                <div class="plpopup addLabpopup">
                    <h2>Add Lab</h2>
                    <div class="close" onClick={closePopup}><span class="popUpClose">x</span></div>
                    <div class="plcontent">
                        <div class="addDataContent">
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Lab Name<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input type="text" className='inputField' placeholder="Enter Lab Name" id="labName" value={labName} onChange={(e) => onChangeLabName(e)} />
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Contact Details<span class="mandatory">*</span></label>
                                </div>
                                <div className="row col-md-9">
                                    <>
                                        <div className="col-md-5">
                                            <input type="text" className='inputField' placeholder="Contact Name" value={contactName} id="contactName" onChange={(e) => onChangeContactName(e)} />
                                        </div>
                                        <div className="col-md-5">
                                            <input type="text" className='inputField' placeholder="Contact Number" value={contactNumber} id="contactNumber" onChange={(e) => onChangeContactNumber(e)} />
                                        </div>
                                        <div className="col-md-2 d-flex align-items-center">
                                            <img src={Add} alt="Add" className='img-fluid cursor-pointer' onClick={(e) => addRow(e)} />
                                        </div>
                                    </>
                                    {addLabListArray.map((data, index) => (
                                        <>
                                            <div className="col-md-5">
                                                <input type="text" className='inputField' placeholder="Contact Name" value={data.contactName} disabled />
                                            </div>
                                            <div className="col-md-5">
                                                <input type="text" className='inputField' placeholder="Contact Number" value={data.contactNumber} disabled />
                                            </div>
                                            <div className="col-md-2 d-flex align-items-center">
                                                <img src={Close} alt="Add" className='img-fluid cursor-pointer' width={22} height={22} onClick={(e) => removeRow(e, index)} />
                                            </div>
                                        </>
                                    ))}

                                </div>
                            </div>
                            <div class="summaryBtnDiv">
                                {labId === "" ?
                                    <button type="submit" class="practiceGreenButton" style={{ color: '#046b29' }} onClick={saveLab} disabled={isButtonDisabled}>Save</button>
                                    :
                                    <button type="submit" class="practiceGreenButton" style={{ color: '#046b29' }} onClick={saveLab} disabled={isButtonDisabled}>Update</button>
                                }
                                <button type="submit" class="practiceGreenButton" onClick={closePopup} style={{ color: 'red', border: "1px solid red" }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup Ends */}
        </div>
    );
}
export default AddLab;

import React, { useEffect, useState } from 'react';
import Add_img from '../images/icons/Add_img.png';
import Close from '../images/icons/close_img.png';
import { callingAPI } from '../config';
import Loader from '../Common/loader';
import { render } from '@testing-library/react';
import { Link, useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
function AddProcedure(props) {
	const location = useLocation();
	const [selectedSpeciality, setSelectedSpeciality] = useState('');
	const [procedureCode, setProcedureCode] = useState('');
	const [procedureName, setProcedureName] = useState('');
	const [procedureCost, setProcedureCost] = useState('');
	const [multiplyCostwithTeeth, setMultiplyCostwithTeeth] = useState(false);
	const [isShowTeeth, setisShowTeeth] = useState(false);
	const [isConsentForm, setisConsentForm] = useState(false);
	const [defaultNotes, setdefaultNotes] = useState('');
	const [errMsg, setErrmsg] = useState('');
	const [DataFlag, setDataFlag] = useState(true);
	const [flagOption, setflagOption] = useState(false);
	const [speciality, setSpeciality] = useState([]);

	const [categoryValues, setCategoryValues] = useState([]);
	const [brandName, setBrandName] = useState('');
	const [medicineId, setMedicineId] = useState('');
	const [medicineAuto, setMedicineAuto] = useState([]);
	const [quantity, setQuantity] = useState('');
	const [categoryType, setCategoryType] = useState(0);
	const re = /^\d+$/;
	const [itemDetails, setItemDetails] = useState([]);
	const [taxListdata, setTaxListData] = useState([]);
	const [TaxDeatailsForSaveAndUpdate, setTaxDeatailsForSaveAndUpdate] = useState([]);
	const [procedureCategoryId, setProcedureCategoryId] = useState("");
	const [procedureCategoryValues, setProcedureCategoryValues] = useState([]);
	var taxDeatilsIds = []
	const navigate = useNavigate();



	const categoryChange = (event) => {
		setCategoryType(event.target.value);
		setQuantity("");
		setBrandName("");
		setMedicineId("");
	};

	const categoryTypeChange = (e, i) => {
		const { name, value } = e.target;
		const values = [...itemDetails];
		values[i] = { ...values[i], [name]: value };
		setItemDetails(values);
	};
	const setMedicineClick = (medName, medId) => {
		document.getElementById('hdnMedicineName').value = medName;
		setBrandName(medName);
		document.getElementById('hdnMedicineId').value = medId;
		setMedicineId(medId);
		setMedicineAuto([]);
		document.getElementById('medicineList').style.display = 'none';

	};

	const onBlurBrandCategory = () => {
		document.getElementById('medicineList').style.display = 'none';
		setMedicineAuto([]);
	};

	const quantityChange = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			setQuantity(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};

	const onChangeBrandName = (event) => {
		setBrandName(event.target.value);
	};

	const procedureTypeChange = (event) => {
		setSelectedSpeciality(event.target.value);

	};
	const procedureCodeChange = (event) => {
		setProcedureCode(event.target.value);
	};

	const procedureNameChange = (event) => {
		setProcedureName(event.target.value);
	};
	const procedureCostChange = (event) => {
		setProcedureCost(event.target.value);

	};
	const multiplycostChange = (event) => {
		setMultiplyCostwithTeeth(event.target.checked);
	};
	const isShowTeethChange = (event) => {
		setisShowTeeth(event.target.checked);
	};
	const isConsentChange = (event) => {
		setisConsentForm(event.target.checked);
	};

	const defaultNotesChange = (event) => {
		setdefaultNotes(event.target.value);
	};

	const categoryDropdownValues = () => {

		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('treatmentProcedure/getCategoryAndTaxDropdown', postdata)
			.then((res) => {

				setTaxListData(res.data.result.taxData);
				setCategoryValues(res.data.result.categoryData);
			})
			.catch();


	};

	function selectSpeciality() {
		const postdata = {
			hospitalId: localStorage.getItem("hospitalId"),
		};
		callingAPI('commonUserFunctions/doctorsMedicineBranch', postdata)
			.then((res) => {
				setSpeciality(res.data.result);
				if (res.data.result.length > 1 || res.data.result.length == 1) {

					for (let i = 0; i < res.data.result.length; i++) {
						// array.push(speciality[i].medicineBranchId == 4?'true':'false')
						if (res.data.result[i].medicineBranchId === 4) {
							setflagOption(true)
						}
						else {
							setflagOption(false)
						}
					}
				}
			})
			.catch();


	};

	const getInventoryAutoComplete = () => {

		let postData = {
			hospitalId: localStorage.getItem("hospitalId"),
			searchKeyword: document.getElementById('hdnMedicineName').value,
			selectDropdownSearchType: categoryType,
		};
		if (document.getElementById('hdnMedicineName').value.length > 1) {
			callingAPI('treatmentProcedure/getDisposableItemsByCategory', postData).then((data) => {
				if (data.data.success === '1') {
					document.getElementById('medicineList').style.display = 'block';
					setMedicineAuto(data.data.result);

				} else {
					document.getElementById('medicineList').style.display = 'none';
					setMedicineAuto([]);


				}
			});
		}
	};

	const addRow = (e, index) => {
		let flags = true;
		if (categoryType === '0') {
			toast.error('Category Required');
			flags = false;
		} else if (!brandName) {
			toast.error('Disposable/Item Name Required');
			document.getElementById('hdnMedicineName').focus();
			flags = false;
		} else if (!quantity) {
			toast.error('Quantity Required ');
			document.getElementById('quantity').focus();
			flags = false;
		}

		if (flags === true) {
			var copyitemDetails = [...itemDetails];
			var newItemDetails = {

				hospitalInventoryCategoryId: categoryType,
				itemName: brandName,
				pmHospitalTreatmentInventoryQuantity: quantity,
				itemId: medicineId

			};
			setItemDetails([...copyitemDetails, newItemDetails]);
			setCategoryType(0);
			setQuantity("");
			setBrandName("");
			setMedicineId("");
		}
	};


	const removeRow = (e, i) => {
		let copyAppendValues = [...itemDetails];
		copyAppendValues.splice(i, 1);
		setItemDetails(copyAppendValues);
	};
	const taxesChange = (e, dataflagvalue, idx) => {

		const value = dataflagvalue.hospitalTaxDetailsId
		setTaxDeatailsForSaveAndUpdate((item) =>
			TaxDeatailsForSaveAndUpdate.includes(value)
				? item.filter((data) => data !== value)
				: [...item, dataflagvalue.hospitalTaxDetailsId]
		);
	};

	////////////////////////////////////////////////////////////////////////////

	const saveProcedure = () => {
		var flag = true;

		let copyAppendValues = [...itemDetails];
		if (document.getElementById('cmbSpetiality').value == "0") {
			toast.error(' Speciality Required');
			document.getElementById('cmbSpetiality').focus();
			window.scroll(0, 0)
			return false;
		}
		// if (!categoryType) {
		// 	alert('Select Category')
		// 	document.getElementById('hospitalInventoryCategoryId').focus();
		// 	window.scroll(0, 0)
		// 	return false;
		// }
		if (!procedureCode) {
			toast.error('Procedure Code Required')
			document.getElementById('hndProcedureCode').focus();
			window.scroll(0, 0)
			return false;
		} else if (!procedureName) {
			toast.error('Procedure Name Required ')
			document.getElementById('txtProcedureName').focus();
			window.scroll(0, 0)
			return false;
		} else if (!procedureCost) {
			toast.error('Procedure Cost Required')
			document.getElementById('txtProcedureCost').focus();
			window.scroll(0, 0)
			return false;
		}
		// if (copyAppendValues.length === 0) {
		// 	if (categoryType === '0') {
		// 		alert('Category Required');
		// 		flag = false;
		// 	} else if (!brandName) {
		// 		alert('Disposable/Item Name Required');
		// 		document.getElementById('hdnMedicineName').focus();
		// 		flag = false;
		// 	} else if (!quantity) {
		// 		alert('Quantity Required ');
		// 		document.getElementById('quantity').focus();
		// 		flag = false;
		// 	} else if (categoryType !== '0' && brandName != "" && quantity !== "") {
		// 		var a = {
		// 			hospitalInventoryCategoryId: categoryType,
		// 			itemName: brandName,
		// 			pmHospitalTreatmentInventoryQuantity: quantity,
		// 			itemId: medicineId
		// 		};
		// 		copyAppendValues.push(a)
		// 		flag = true;
		// 	}

		// }
		// else {
		if (categoryType !== '0' && brandName != "" && quantity !== "") {
			var a = {
				hospitalInventoryCategoryId: categoryType,
				itemName: brandName,
				pmHospitalTreatmentInventoryQuantity: quantity,
				itemId: medicineId
			};
			copyAppendValues.push(a)
			flag = true;
		}

		//}
		const pmTreatmentsId = location && location.state && location.state.data
			? location.state.data.pmTreatmentsId
			: ''

		if (flag == true) {

			const postdata = {
				hospitalId: localStorage.getItem("hospitalId"),
				pmTreatmentsId:
					location && location.state && location.state.data
						? location.state.data.pmTreatmentsId
						: '',
				pmTreatmentsMedicalSpeciality: document.getElementById('cmbSpetiality').value,
				pmHospitalTreatmentDetailsProcedureCode: procedureCode,
				pmTreatmentsName: procedureName,
				pmHospitalTreatmentDetailsPrice: procedureCost,
				pmHospitalTreatmentDetailsMultiplyCostWithTeeth: multiplyCostwithTeeth,
				pmHospitalTreatmentDetailsShowTeeth: isShowTeeth,
				pmHospitalTreatmentDetailsConsentForm: isConsentForm,
				pmHospitalTreatmentDetailsDefaultNotes: defaultNotes,

				itemName: brandName,
				pmHospitalTreatmentInventoryQuantity: quantity,
				itemId: medicineId,
				taxDetails: TaxDeatailsForSaveAndUpdate,
				categoryData: copyAppendValues,
				pmTreatmentsBranchId: procedureCategoryId,
			};
			setDataFlag(false);
			callingAPI('treatmentProcedure/saveTreatmentProcedure', postdata).then((response) => {
				if (response.data.success === '1') {
					setCategoryType("");
					setQuantity("");
					setBrandName("");
					setDataFlag(true);
					if (pmTreatmentsId === "") {
						toast.success('Treatment Procedure Saved Successfully');
					}
					else {
						toast.success('Treatment Procedure Updated Successfully');
					}
					window.location.href = '/settings/treatmentProcedure';
					// navigate('/settings/treatmentProcedure');

				} else if (response.data.success === '0') {
					setDataFlag(true);
					toast.error(response.data.errorMessage);
					window.scroll(0, 0)

				}
			});
		}

	};
	const getEditedValues = () => {

		setTimeout(function () {
			const valuesToSend = {
				pmTreatmentsId:
					location && location.state && location.state.data
						? location.state.data.pmTreatmentsId
						: '',
				hospitalId: localStorage.getItem("hospitalId"),
			};
			callingAPI('treatmentProcedure/editTreatmentProcedure', valuesToSend)
				.then((res) => {
					{ window.sessionStorage.getItem("siteId") === "1" && (setProcedureCategoryId(res.data.resultData.treatmentProcedureData[0].pmTreatmentsBranchId)) }
					setSelectedSpeciality(res.data.resultData.treatmentProcedureData[0].pmTreatmentsMedicalSpeciality);
					setProcedureName(res.data.resultData.treatmentProcedureData[0].pmTreatmentsName);
					setProcedureCode(res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsProcedureCode);
					setProcedureCost(res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsPrice);
					setMultiplyCostwithTeeth(
						res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsMultiplyCostWithTeeth
					);
					setisShowTeeth(res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsShowTeeth);
					setisConsentForm(res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsConsentForm);
					setdefaultNotes(res.data.resultData.treatmentProcedureData[0].pmHospitalTreatmentDetailsDefaultNotes);
					setItemDetails(res.data.resultData.medicineCategory)

					for (let i = 0; i < res.data.resultData.medicineCategory.length; i++) {
						// setCategoryType(res.data.resultData.medicineCategory[i].hospitalInventoryCategoryId)
						// setBrandName(res.data.resultData.medicineCategory[i].itemName);
						setMedicineId(res.data.resultData.medicineCategory[i].itemId);
						// setQuantity(res.data.resultData.medicineCategory[i].pmHospitalTreatmentInventoryQuantity);
					}
					for (var s = 0; s < res.data.resultData.applicableTaxes.length; s++) {
						taxDeatilsIds.push(res.data.resultData.applicableTaxes[s].hospitalTaxDetailsId)
						// setTaxDeatailsForSaveAndUpdate(res.data.resultData.applicableTaxes[s].hospitalTaxDetailsId)
						if (res.data.resultData.applicableTaxes.length !== "") {
							document.getElementById("tax" + res.data.resultData.applicableTaxes[s].hospitalTaxDetailsId).checked = true
						}
					}
					setTaxDeatailsForSaveAndUpdate(taxDeatilsIds)
				})
				.catch();
		}, 2000);


	};

	const procedureCategoryChange = (event) => {
		setProcedureCategoryId(event.target.value);
	};

	useEffect(async () => {
		selectSpeciality();
		const PostData = {};
		callingAPI('commonUserFunctions/getTreatmentCategoryList', PostData)
			.then((data) => {
				if (data.data.success === '1') {
					setProcedureCategoryValues(data.data.result.categoryListArray);
				}
				else {
					setProcedureCategoryValues([]);
				}

			})
			.catch();
		if (location.state !== null) {
			await getEditedValues();
		}

		categoryDropdownValues();

	}, []);


	return (
		<div class="practiceManagementForm addProcedureSectn"  >
			<ToastContainer position="bottom-right" />
			{DataFlag == false ? (
				<div align="center">
					<Loader />
				</div>
			) : (
				<div>
					<p style={{ color: 'red' }}>{errMsg}</p>
					<form action="/practiceManagementHealthRecord/index" method="post">
						<h3 class="practiceHead">
							{location &&
								location.state &&
								location.state.data &&
								location.state.data.pmTreatmentsId ? (
								'Edit Procedure Item'
							) : (
								'Add Procedure Item'
							)}
						</h3>
						<div class="practiceManagementFormBlcok">
							<div class="prodecureCatalogWrap">
								<div class="procedureCatSec">
									<div class="procedureCatList">
										<div class="procedureCatName">Select Speciality*</div>
										<div class="procedureCatType">

											<select
												// value="Dentistry"
												id="cmbSpetiality"
												value={selectedSpeciality}

												name="cmbSpetiality"
												onChange={procedureTypeChange}
											>

												{/* <option value="Dentistry">Dentistry</option> */}
												{/* <option value="Other">Other</option> */}

												{(speciality.length > 1) ?
													flagOption === true ?
														<><option value="0">Select Speciality</option>
															<option value="Dentistry">Dentistry</option>
															<option value="Other">Medical</option></>
														:

														<><option value="Other" selected="selected">Medical</option></>
													:

													flagOption === true ?
														<><option value="Dentistry" selected="selected">Dentistry</option></>
														:
														<><option value="Other" selected="selected">Medical</option></>}

											</select>
										</div>
									</div>
									{window.sessionStorage.getItem("siteBranchId") === "4" &&
										(<div class="procedureCatList">
											<div class="procedureCatName">Select Branch</div>
											<div class="procedureCatType">
												<select name="procedureCategoryId"
													id="accountHead"
													className='inputField'
													value={procedureCategoryId}
													onChange={procedureCategoryChange}
												>
													<option value="">Select</option>
													{procedureCategoryValues.map((item, idix) => (
														<option value={item.id}>
															{item.branchName}
														</option>
													))}
												</select>
											</div>
										</div>)
									}



									<div class="procedureCatList">
										<div class="procedureCatName">Procedure Code*</div>
										<div class="procedureCatType">
											<input
												type="text"
												name="hndProcedureCode"
												id="hndProcedureCode"
												value={procedureCode}
												onChange={procedureCodeChange}
											/>
										</div>
									</div>
									<div class="procedureCatList">
										<div class="procedureCatName">Procedure Name*</div>
										<input
											type="hidden"
											name="hndProcedure"
											id="hndProcedure"
											value={procedureName}
											onChange={procedureNameChange}
										/>
										<div class="procedureCatType">
											<input
												type="text"
												id="txtProcedureName"
												name="txtProcedureName"
												value={procedureName}
												onChange={procedureNameChange}
											/>
										</div>
									</div>
									<div class="procedureCatList">
										<div class="procedureCatName">Procedure Cost*</div>
										<div class="procedureCatType">
											<div class="procedureCatSub">
												<input
													autoComplete="off"
													type="text"
													placeholder="0.00"
													id="txtProcedureCost"
													name="txtProcedureCost"
													value={procedureCost}
													onChange={procedureCostChange}
													onKeyPress={(event) => {
														if (!/[0-9.]/.test(event.key)) {
															event.preventDefault();
															toast.error("Only Numbers Accepted")
														}
													}}
												/>
												<span>INR</span>
											</div>
											<div class="procedureCatSubMul">
												<input
													type="checkbox"
													id="chkMultipleCost"
													checked={multiplyCostwithTeeth}
													name="chkMultipleCost"
													onChange={multiplycostChange}
												/>
												<label id="lblMultipleCost">Multiply Cost with Tooth</label>
											</div>
										</div>
									</div>
									<div class="procedureCatList">
										<div class="procedureCatName">
											<label id="lblShowTeeth">Show Teeth</label>
										</div>
										<div class="procedureCatType">
											<input
												type="checkbox"
												id="chkShowTeeth"
												name="chkShowTeeth"
												checked={isShowTeeth}
												onChange={isShowTeethChange}
											/>
										</div>
									</div>
									<div class="procedureCatList">
										<div class="procedureCatName">
											<label>Consent Form</label>
										</div>
										<div class="procedureCatType">
											<input
												type="checkbox"
												id="chkConsentForm"
												name="chkConsentForm"
												checked={isConsentForm}
												onChange={isConsentChange}
											/>
										</div>
									</div>
									<div class="procedureCatList">
										<div class="procedureCatName">Default Notes</div>
										<div class="procedureCatType">
											<textarea
												maxlength="5000"
												id="txtDefaultNotes"
												name="txtDefaultNotes"
												value={defaultNotes}
												onChange={defaultNotesChange}
											/>
										</div>
									</div>
									{ /* TAx */}
									<div class="procedureCatList" id="divTaxDetails">
										<div
											class="applicabletaxDiv pl_applicableTax_scroll row"
											style={{ flexWrap: 'nowrap', margin: '0px' }}
										>
											<div class="col-lg-3 procedureCatName">Applicable Taxes</div>

											<div class=" col-lg-9 procedureCatType procedureCatListWrap">
												<div className="row">
													{taxListdata.map((option, idx) => {
														return (
															<div class="col-lg-4 procedureCatType1  procedureCatListWrap ">
																<input
																	type="checkbox"
																	id={"tax" + option.hospitalTaxDetailsId}
																	onClick={(e) => taxesChange(e, option, idx)} tabindex="-1"
																/>&nbsp;
																<label>{option.hospitalTaxDetailsName}</label>
															</div>
														);
													})}
												</div>
											</div>
										</div>
									</div>

									{ /* TAx */}
									<div class="borderSeparate">
										<span />
									</div>
									{ /* Disposable/Items used */}


									<div class="taxDetailWrapper taxDetailWrapperCvr" style={{ padding: '0px' }}>
										<label class="smalLbl">Disposable/Items used</label>

										<div class="taxMainContentWrap">
											<div class="taxMainWrap">
												<div className="row">
													<div className="col-md-4">
														<select
															name="hospitalInventoryCategoryId"
															id="hospitalInventoryCategoryId"
															value={categoryType}
															onChange={(e) => categoryChange(e)}
															style={{ width: '100%' }}
														>
															<option value="">Select Category</option>
															{categoryValues.map((item, idix) => (
																<option value={item.hospitalInventoryCategoryId}>
																	{item.hospitalInventoryCategoryName}
																</option>
															))}
														</select>
													</div>
													<div className="col-md-4">

														<input
															autoComplete="off"
															type="text"
															id="hdnMedicineName"
															name="hdnMedicineName"
															// autofocus="autofocus"
															value={brandName}
															onChange={onChangeBrandName}
															placeholder="Enter Disposable/Item Name"
															onKeyUp={() => getInventoryAutoComplete()}
															tabindex="-1" style={{ width: '100%' }}
														//	onBlur={onBlurBrandCategory}
														/>
														<ul
															className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
															id="medicineList"
															style={{ display: 'none' }}
														>
															{medicineAuto.map((item, i) => (
																<li onClick={() => setMedicineClick(item.itemName, item.itemId)}>
																	{item.itemName}
																</li>
															))}
														</ul>
														<input type="hidden" id="hdnMedicineId" />

													</div>
													<div className="col-md-2">
														<input
															autocomplete="off"
															type="text"

															placeholder="Quantity"
															maxlength="100"
															id="quantity"
															name="quantity"
															value={quantity}
															onChange={quantityChange}
															style={{ width: '100%' }}
														/>




													</div>
													<div className="col-md-1">
														<img
															title="Add More"
															style={{ cursor: 'pointer' }}
															src={Add_img}
															onClick={(e) => addRow(e)}
														/>
													</div>
												</div>
											</div>
											<div id="divItem" />
										</div>
										{itemDetails.map((item, idx) => (

											<div class="taxMainContentWrap">
												<div class="taxMainWrap">
													<div className="row">
														<div className="col-md-4">
															<select
																name="hospitalInventoryCategoryId"
																id={"hospitalInventoryCategoryId" + idx}
																value={item.hospitalInventoryCategoryId}
																onChange={(e) => categoryChange(e)}
																style={{ width: '100%' }}
															>
																<option value="">Select Category</option>
																{categoryValues.map((item, idix) => (
																	<option value={item.hospitalInventoryCategoryId}>
																		{item.hospitalInventoryCategoryName}
																	</option>
																))}
															</select>
														</div>
														<div className="col-md-4">

															<input
																autoComplete="off"
																type="text"
																id={"hdnMedicineName" + idx}
																name="hdnMedicineName"
																autofocus="autofocus"
																value={item.itemName}
																onChange={(e) => categoryChange(e)}
																placeholder="Enter Disposable/Item Name"
																onKeyUp={() => getInventoryAutoComplete()}
																tabindex="-1" style={{ width: '100%' }}
															//	onBlur={onBlurBrandCategory}
															/>
															<ul
																className="autoSearch autoSearch-p0 appointmentNumberSearch itemNameSearch"
																id="medicineList"
																style={{ display: 'none' }}
															>
																{medicineAuto.map((item, i) => (
																	<li onClick={() => setMedicineClick(item.itemName, item.itemId)}>
																		{item.itemName}
																	</li>
																))}
															</ul>
															<input type="hidden" id="hdnMedicineId" />

														</div>
														<div className="col-md-2">
															<input
																autocomplete="off"
																type="text"

																placeholder="Quantity"
																maxlength="100"
																id={"quantity" + idx}
																name="quantity"
																value={item.pmHospitalTreatmentInventoryQuantity}
																onChange={(e) => categoryTypeChange(e, idx)}
																style={{ width: '100%' }}
															/>

														</div>
														<div className="col-md-1">
															<img
																title="Add More"
																style={{ cursor: 'pointer' }}
																src={Close}
																onClick={(e) => removeRow(e, idx)} tabindex="-1"
															/>
														</div>
													</div>
												</div>
												<div id="divItem" />
											</div>
										))}

									</div>





								</div>
							</div>
						</div>
						<input type="hidden" name="max" id="maxId" value="10" />
						<input type="hidden" name="offset" id="offsetId" value="0" />
						<input type="hidden" name="hidProcedure_id" id="hidProcedure_id" value="" />
						<input type="hidden" name="hidChkId" id="hidChkId" value="" />
					</form>
					<div class="procedureCatList">
						<div class="col-md-12 col-lg-12 pull-right">
							<button onClick={saveProcedure} class="practiceGreenButton">
								{
									location &&
										location.state &&
										location.state.data &&
										location.state.data.pmTreatmentsId ? (
										'Update'
									) : (
										'Save'
									)}
							</button>
							<a
								href="/settings/treatmentProcedure"
								tabindex="9"
								class="practiceGreyButton"
								style={{ fontWeight: '600', fontFamily: 'Montserrat-Regular' }}
							>
								Cancel
							</a>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
export default AddProcedure;
import React, { useEffect, useState, useRef } from "react";
import { callingAPI } from "../config";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import Pagination from "react-js-pagination";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ExpiredMedicineStockDetails() {
  const csvDownload = "Expire Medicine Stock Details";
  const [expiredData, setExpiredData] = useState([]);
  const [grandTotalUnit, setgrandTotalUnit] = useState("");
  const [grandTotal, setgrandTotal] = useState("");
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [pageOffset, setPageOffset] = useState(0);
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [filterName, setFilterInput] = useState("");
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [totalAvailableQuantity, setTotalAvailableQuantity] = useState("");
  const [enable, setEnable] = useState(true);
  const [searchDate, setSearchDate] = useState("");
  const [searchDateValue, setSearchDateValue] = useState("");
  const [dateEnable, setDateEnable] = useState(true);
  const [dateFalag, setdateFalag] = useState(false);
  const [count, setTotalCount] = useState("");
  const textInput = useRef(null);
  const searchValueChange = (event) => {
    if (event.target.value === "0") {
      setEnable(true);
      setSearchValue("");
      setSearch(event.target.value);
    } else {
      setEnable(false);
      setSearchValue(event.target.value);
    }
  };
  const changeDate = (event) => {
    if (event.target.value === "0") {
      setDateEnable(true);
      setFromDate("");
      setToDate("");
      document.getElementById("datePickerOne").style.backgroundColor =
        "transparent";
      document.getElementById("datePickerTwo").style.backgroundColor =
        "transparent";
      setSearchDateValue("");
      setSearchDate(event.target.value);
    } else {
      setDateEnable(false);
      setSearchDate(event.target.value);
      document.getElementById("datePickerOne").style.backgroundColor = "WHITE";
      document.getElementById("datePickerTwo").style.backgroundColor = "WHITE";
    }
  };
  const changeText = (event) => {
    if (event.target.value === "0") {
      document.getElementById("searchText").style.backgroundColor =
        "transparent";
      document.getElementById("searchText").value = "";
      document.getElementById("searchText").readOnly = true;
      setEnable(true);
      setSearchValue("");
      setSearch(event.target.value);
    } else {
      document.getElementById("searchText").readOnly = false;
      textInput.current.focus();
      setEnable(false);
      document.getElementById("searchText").style.backgroundColor = "WHITE";
      setSearch(event.target.value);
    }
  };
  const expireExport = () => {
    var toastShown = false;
    if (expiredData.length === 0) {
      if (!toastShown) {
        toast.error("No Data to Export");
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    else {

      const csvRow = [];
      var medicList = [
        [
          "Medicine%20Name ",
          " Total%20Available%20Quantity",
          " Batch%20Number ",
          " Available%20Quantity ",
          " Expiry%20Date ",
          " Selling%20Price ",
          " Selling%20Unit%20Price ",
          " Stock%20Updated%20Date ",
        ],
      ];
      var name = expiredData;
      let postData = {
        // role: window.sessionStorage.getItem("role"),
        // userId: window.sessionStorage.getItem("userId"),
        // max: "",
        // offset: 0,
        role: window.sessionStorage.getItem("role"),
        userId: window.sessionStorage.getItem("userId"),
        cmbSelect: search,
        cmbSelect1: searchDate,
        max: process.env.REACT_APP_PAGINATION_COUNT,
        offset: pageOffset,
        txtInput: document.getElementById("searchText").value,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
        max: "",
        offset: 0,
      };
      callingAPI("clinicalBackground/expiredMedicineStockDetails", postData).then(
        (response) => {
          for (var item = 0; item < response.data.result.expireStockData.length; item++) {
            for (var j = 0; j < response.data.result.expireStockData[item].expiredMedicine.length; j++) {

              if (j == 0) {
                medicList.push([
                  response.data.result.expireStockData[item].medicineName,
                  response.data.result.expireStockData[item].totalAvailableQuantity,
                  response.data.result.expireStockData[item].expiredMedicine[j]
                    .batchNumber,
                  response.data.result.expireStockData[item].expiredMedicine[j]
                    .availableQuantity,
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].expiryDate
                    .replace(/,/g, "%20")
                    .replace(/ /g, "%20"),
                  "Rs " +
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].sellingPrice.toFixed(2),
                  "Rs " +
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].sellingUnitPrice.toFixed(2),
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].stockUpdatedDate
                    .replace(/,/g, "%20")
                    .replace(/ /g, "%20"),
                ]);
              }

              else {
                medicList.push([
                  "%20",
                  "%20",
                  response.data.result.expireStockData[item].expiredMedicine[j]
                    .batchNumber,
                  response.data.result.expireStockData[item].expiredMedicine[j]
                    .availableQuantity,
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].expiryDate
                    .replace(/,/g, "%20")
                    .replace(/ /g, "%20"),
                  "Rs " +
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].sellingPrice.toFixed(2),
                  "Rs " +
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].sellingUnitPrice.toFixed(2),
                  response.data.result.expireStockData[
                    item
                  ].expiredMedicine[j].stockUpdatedDate
                    .replace(/,/g, "%20")
                    .replace(/ /g, "%20"),
                ]);
              }

            }
          }
          for (var i = 0; i < medicList.length; ++i) {
            csvRow.push(medicList[i].join(","));
          }
          var csvString = csvRow.join("%0A");
          var create = document.createElement("a");
          create.href = "data:attachment/csv," + csvString;
          create.download = csvDownload + ".csv";
          document.body.appendChild(create);
          create.click();
        }
      );

    }
  };
  const paginationView = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    if (!boolianfilterName) {
      setFilterInput("");
    }
    if (!booliansearchValue) {
      setSearchValue("");
    }
    if (!boolianfromDate) {
      setFromDate("");
    }
    if (!booliantoDate) {
      setToDate("");
    }
    const medicParams = {
      role: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      max: max,
      offset: offset,
    };
    callingAPI("clinicalBackground/expiredMedicineStockDetails", medicParams)
      .then((response) => {
        if (response.data.result.expireStockData.length > 0) {
          setExpiredData(response.data.result.expireStockData);
          setgrandTotalUnit(response.data.result.grandTotalUnit);
          setgrandTotal(response.data.result.grandTotal);
          setTotalCount(response.data.result.totalCount);
        }
      })
      .catch();
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const expiredSearch = () => {
    var toastShown = false;
    if (
      document.querySelector("#selectName").value !== "0" &&
      document.querySelector("#searchText").value === ""
    ) {
      if (!toastShown) {
        var t = document.getElementById("selectName");
        var selectedText = t.options[t.selectedIndex].text;
        toast.error(selectedText + " Required");
        document.querySelector("#searchText").focus();
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    setbooliansearchValue(searchValue);
    setboolianfilterName(search);
    setboolianfromDate(fromDate);
    setbooliantoDate(toDate);
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        if (!toastShown) {
          toast.error("End date must be greater than start date");
          document.getElementById("datePickerTwo").focus()
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    setActivePage(1);
    const medicParams = {
      role: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      cmbSelect: search,
      cmbSelect1: searchDate,
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
      txtInput: document.getElementById("searchText").value,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };
    callingAPI("clinicalBackground/expiredMedicineStockDetails", medicParams)
      .then((response) => {
        if (response.data.result.expireStockData.length == 0) {
          setExpiredData([]);
          setTotalCount(0);
        }
        if (response.data.result.expireStockData.length > 0) {
          setExpiredData(response.data.result.expireStockData);
          setgrandTotalUnit(response.data.result.grandTotalUnit);
          setgrandTotal(response.data.result.grandTotal);
          setTotalAvailableQuantity(
            response.data.result.expireStockData[0].totalAvailableQuantity
          );
          setTotalCount(response.data.result.totalCount);
        }
      })
      .catch();
  };
  const expiredMedicList = () => {
    const medicParams = {
      role: window.sessionStorage.getItem("role"),
      userId: window.sessionStorage.getItem("userId"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
    };
    callingAPI("clinicalBackground/expiredMedicineStockDetails", medicParams)
      .then((response) => {
        if (response.data.result.expireStockData.length > 0) {
          setExpiredData(response.data.result.expireStockData);
          setgrandTotalUnit(response.data.result.grandTotalUnit);
          setgrandTotal(response.data.result.grandTotal);
          setTotalAvailableQuantity(
            response.data.result.expireStockData[0].totalAvailableQuantity
          );
          setTotalCount(response.data.result.totalCount);
          setdateFalag(true);
        } else {
          setdateFalag(true);
          setTotalCount(0);
          setExpiredData([]);
        }
      })
      .catch();
  };
  const FromDateClear = () => {
    setFromDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const ToDateClear = () => {
    setToDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  useEffect(() => {
    expiredMedicList();
  }, []);
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <ToastContainer position="bottom-right" />
      <div className="row">
        <div className="col-md-8">
          <div className="dashboardTitle"> Expired Medicine Stock Details</div>
        </div>
      </div>
      {count > 10 ? (
        <div className="paginationSection">
          <Pagination
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={count}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={paginationView}
          />
        </div>
      ) : (
        ""
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch">
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  value={search}
                  onChange={changeText}
                >
                  <option value="0">Search In</option>
                  <option value="1">Medicine Name</option>
                  <option value="2">Batch Number</option>
                </select>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <input
                  ref={textInput}
                  type="text"
                  className="inputField"
                  id="searchText"
                  name="searchText"
                  style={{ background: "transparent" }}
                  value={searchValue}
                  onChange={searchValueChange}
                  readOnly
                />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  value={searchDate}
                  onChange={changeDate}
                >
                  <option value="0">Search In</option>
                  <option value="1">Stock Update</option>
                  <option value="2">Expiry Date</option>
                </select>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    autoComplete="off"
                    required
                    disabled={dateEnable}
                    placeholderText="From Date"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    selected={fromDate}
                    showYearDropdown
                    dropdownMode="select"
                    id="datePickerOne"
                    onChange={(date) => setFromDate(date)}
                    className="disabledField"
                    style={{ background: "transparent" }}
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    id="datePickerTwo"
                    required
                    disabled={dateEnable}
                    placeholderText="To Date"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onChange={(date) => setToDate(date)}
                    selected={toDate}
                    className="disabledField"
                    style={{ background: "transparent" }}
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-1 dashboardSearchList">
                <span
                  onClick={expiredSearch}
                  className="dashButtonBorder"
                  style={{ border: "1px solid gainsboro", padding: "6px 5px" }}
                >
                  Search
                </span>
              </div>
              <div className="col-md-1 dashboardSearchList">
                <span
                  onClick={expireExport}
                  className="dashButtonBorder dashButtonBorderGrey"
                  style={{ border: "1px solid gainsboro", padding: "6px 5px" }}
                >
                  Export
                </span>
              </div>
            </div>
            <div className="row  ">
              <div className="col-md-5">
                <div className="searchTxt">
                  Total Selling Unit Price : &#8377; {grandTotal}
                </div>
              </div>
              <div className="col-md-4">
                <div className="searchTxt">
                  Total Selling Price : &#8377; {grandTotalUnit}
                </div>
              </div>
              <div className="col-md-3">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total Medicines : {count}
                </div>
              </div>
            </div>
          </div>
          {dateFalag === true ? (
            <>
              {expiredData.length > 0 ? (
                <div className="dashboardtable">
                  <table>
                    <thead>
                      <tr>
                        <th>Medicine Name </th>
                        <th>Total Available Quantity </th>
                        <th>Batch Number</th>
                        <th>Available Quantity</th>
                        <th>Expiry Date</th>
                        <th style={{ textAlign: "right" }}>Selling Price</th>
                        <th style={{ textAlign: "right" }}>
                          Selling Unit Price
                        </th>
                        <th>Stock Updated Date</th>
                      </tr>
                    </thead>

                    <tbody>
                      {expiredData.map((data) => (
                        <tr>
                          <td>{data.medicineName}</td>
                          <td>{data.totalAvailableQuantity}</td>
                          <td>
                            {data.expiredMedicine.map((item) => (
                              <div> {item.batchNumber}</div>
                            ))}
                          </td>

                          <td>
                            {data.expiredMedicine.map((item) => (
                              <div> {item.availableQuantity}</div>
                            ))}
                          </td>
                          <td>
                            {data.expiredMedicine.map((item) => (
                              <div> {item.expiryDate}</div>
                            ))}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data.expiredMedicine.map((item) => (
                              <div>
                                {" "}
                                &#8377;
                                {parseFloat(item.sellingPrice).toFixed(2)}
                              </div>
                            ))}
                          </td>
                          <td style={{ textAlign: "right" }}>
                            {data.expiredMedicine.map((item) => (
                              <div>
                                &#8377;
                                {parseFloat(item.sellingUnitPrice).toFixed(2)}
                              </div>
                            ))}
                          </td>
                          <td>
                            {data.expiredMedicine.map((item) => (
                              <div>{item.stockUpdatedDate}</div>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <NoData />
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
        {count > 10 ? (
          <div className="paginationSection">
            <Pagination
              activePage={activePage}
              hideDisabled
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={count}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={paginationView}
            />
          </div>
        ) : (
          ""
        )}
        <div id="itemsReturn" class="modalDialog">
          <div>
            <a href="#close" title="Close" class="close exportClose">
              X
            </a>
            <div
              class="modal-header"
              style={{
                display: "block",
                border: "none",
                paddingBottom: "0px",
                padding: "0px",
              }}
            >
              <div class="addDataHead">
                <span className="labTitle" style={{ color: "#3498db" }}>
                  Items(s) Return
                </span>
              </div>
              <div className="itemsReturnWrap">
                <div className="row dflexend">
                  <div className="col-md-3 dashboardSearchList">
                    <div>
                      <Datepicker
                        required
                        className="Datepicker pa2 inputField"
                        placeholderText="From "
                        calendarClassName="rasta-stripes"
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <label
                        className="icon iconCalendar calendar plReportsCalendar"
                        style={{ fontSize: "16px" }}
                        htmlFor="fromdate"
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 dashboardSearchList">
                    <div>
                      <Datepicker
                        required
                        className="Datepicker pa2 inputField"
                        placeholderText="To "
                        calendarClassName="rasta-stripes"
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <label
                        className="icon iconCalendar calendar plReportsCalendar"
                        style={{ fontSize: "16px" }}
                        htmlFor="todate"
                      >
                        <FontAwesomeIcon icon={faCalendarAlt} />
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2 dashboardSearchList">
                    <span
                      className="dashButtonBorder"
                      style={{
                        border: "1px solid #046b29",
                        padding: "6px 5px",
                      }}
                    >
                      Search
                    </span>
                  </div>
                </div>
                <div className="row">
                  <p style={{ color: "red", margin: "0px" }}>
                    No data(s) found matching the criteria
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ExpiredMedicineStockDetails;

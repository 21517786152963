import PharmacyHeader from "./layout/header";
import Footer from "./layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "./pharmacyLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import Eyeicon from "../images/icons/eye.png";
import Datepicker, { CalendarContainer } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import React, { useState, useEffect } from "react";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { callingAPI } from "../config";
import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PatientMedicine() {
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [memberPlanData, setMemberPlanData] = useState([]);
  const [memberPlanCountData, setMemberPlanCountData] = useState();
  const [functionName, setFunctionName] = useState("Patient Medicine");
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [dataFlag, setDataFlag] = useState(false);
  const [doctorId, setDoctorId] = useState(
    window.sessionStorage.getItem("patientDoctorId")
  );
  const [doctorName, setDoctor] = useState(
    window.sessionStorage.getItem("patientDoctorName")
  );
  const [doctorList, setDoctorList] = useState([]);
  const [isData, setIsData] = useState(0);
  const [freeArticleData123, setFreeArticleData123] = useState([]);
  const [freeArticleData1, setFreeArticleData1] = useState([]);
  const [DataFlagSearch, setDataFlagSearch] = useState("0");
  const [getHidDocId, setHidDocId] = useState(
    window.sessionStorage.getItem("role") === "ROLE_DOCTOR"
      ? window.sessionStorage.getItem("userId")
      : ""
  );
  const [getSelectDoctor, setSelectDoctor] = useState(
    window.sessionStorage.getItem("role") === "ROLE_DOCTOR"
      ? window.sessionStorage.getItem("userName") +
      "(" +
      window.sessionStorage.getItem("specializationName") +
      ")"
      : ""
  );
  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      doctorName: getSelectDoctor,
      max: max,
      offset: offset,
      cmbSelect: document.querySelector("#selectName").value,
      txtInput: document.querySelector("#searchText").value,
    };
    callingAPI("patientMedicine/patientMedicine", PostData).then((data) => {
      if (data.data.success === "1") {
        setMemberPlanData(data.data.result.memberPlanData);
        setMemberPlanCountData(data.data.result.memberPlanCountData);
        setIsData(1);
      } else {
        setIsData(1);
        setMemberPlanData([]);
        setMemberPlanCountData([]);
      }
    });
  };
  const changeText = () => {
    setDataFlagSearch(document.querySelector("#selectName").value);
    if (document.querySelector("#selectName").value !== "0") {
      document.getElementById("searchText").disabled = false;
      document.querySelector("input[name=searchText]").focus();
    } else {
      document.getElementById("searchText").disabled = true;
    }
    document.getElementById("searchText").value = "";
  };
  function formatDate(date) {
    var date = new Date(date),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  function csvFileDownload() {
    var toastShown = false;
    if (memberPlanData.length === 0) {
      if (!toastShown) {
        toast.error("No Data to Export");
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    else {
      const csvRow = [];
      var fileDownloadArray = [["Date", "Patient", "Doctor"]];
      var arrayDetails = memberPlanData;
      let PostData = {
        userId: window.sessionStorage.getItem("userId"),
        roleName: window.sessionStorage.getItem("role"),
        max: "",
        offset: 0,
        doctorName: getSelectDoctor,
        // As per discussion, replace(/ /g, "") code removed, becuase sometimes search not working.
        // doctorName: getSelectDoctor.replace(/ /g, ""),
        cmbSelect: document.querySelector("#selectName").value,
        txtInput: document.querySelector("#searchText").value,
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
      };
      callingAPI("patientMedicine/patientMedicine", PostData).then((response) => {
        if (response.data.success === "1") {
          for (var j = 0; j < response.data.result.memberPlanData.length; j++) {
            fileDownloadArray.push([
              response.data.result.memberPlanData[j].cdate.replace(", ", "-").replace(" ", "-"),
              response.data.result.memberPlanData[j].pateint,
              response.data.result.memberPlanData[j].doctname,
            ]);
          }
          for (var i = 0; i < fileDownloadArray.length; ++i) {
            csvRow.push(fileDownloadArray[i].join("|"));
          }
          var csvString = csvRow.join("%0A");
          var a = document.createElement("a");
          a.href = "data:attachment/csv," + csvString;
          a.target = "_Blank";
          a.download = functionName + ".csv";
          document.body.appendChild(a);
          a.click();
        }
      });
    }

  }
  const searchFunction = () => {
    var toastShown = false;
    if (
      document.querySelector("#selectName").value !== "0" &&
      document.querySelector("#searchText").value === ""
    ) {
      if (!toastShown) {
        var t = document.getElementById("selectName");
        var selectedText = t.options[t.selectedIndex].text;
        toast.error("Enter" + " " + selectedText);
        document.querySelector("#searchText").focus();
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        if (!toastShown) {
          toast.error("End date must be greater than start date");
          document.getElementById("toDate").focus();
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      max: "10",
      offset: 0,
      doctorName: getSelectDoctor,
      cmbSelect: document.querySelector("#selectName").value,
      txtInput: document.querySelector("#searchText").value,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
      searchValue: document
        .querySelector("#searchTextDr")
        .value.replace(/ /g, ""),
    };
    callingAPI("patientMedicine/patientMedicine", PostData).then((data) => {
      if (data.data.success === "1") {
        setMemberPlanData(data.data.result.memberPlanData);
        setMemberPlanCountData(data.data.result.memberPlanCountData);
        setIsData(1);
      } else {
        setMemberPlanData([]);
        setMemberPlanCountData([]);
        setIsData(1);
      }
    });
  };
  useEffect(() => {
    changeText();
    const PostData = {
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      doctorName: getSelectDoctor,
      cmbSelect: document.querySelector("#selectName").value,
      txtInput: document.querySelector("#searchText").value,
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
      fromDate: "",
      toDate: "",
    };
    callingAPI("patientMedicine/patientMedicine", PostData).then((data) => {
      if (data.data.success === "1") {
        setMemberPlanData(data.data.result.memberPlanData);
        setMemberPlanCountData(data.data.result.memberPlanCountData);
        setIsData(1);
      } else {
        setIsData(1);
        setMemberPlanData([]);
        setMemberPlanCountData([]);
      }
    });
  }, []);
  const doctorDropdown = () => {
    const apiJson = {
      hospitalId: localStorage.getItem("hospitalId"),
      selectCombo: "",
      inputSearch: "",
      max: 100,
      offset: 0,
    };
    document.getElementById("doctorListDiv").style.display = "block";
    callingAPI("commonUserFunctions/doctorsList", apiJson).then((data) => {
      if (
        data.data.success === "1" &&
        document.querySelector("#searchTextDr").value !== ""
      ) {
        setDoctorList(data.data.result.doctorsListArray);
      } else {
        toast.error("no doctors found")
        setDoctorList([]);
      }
    });
  };
  const onBlurItemCategory = (e) => {
    if (!["react-datepicker__month-select", "react-datepicker__year-select"].includes(String(e.target.className))) {
      document.getElementById("doctorListDiv").style.display = "none";
      setDoctorList([]);
    }
  };
  const setDoctorName = (doctorObj, i) => {
    setSelectDoctor(doctorObj.doctorName);
    setDoctor(doctorObj.doctorName);
    setDoctorId(doctorObj.doctorId);
    document.getElementById("doctorListDiv").style.display = "none";
  };
  const FromDateClear = () => {
    setFromDate("");
  };
  const patientMedicineClick = (data) => {
    navigate('/pharmacyLogin/patientMedicineView', { state: data })
  }
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };

  const ToDateClear = () => {
    setToDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <ToastContainer position="bottom-right" />
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle"> Patient Medicine</div>
        </div>
      </div>
      {memberPlanCountData > 10 && (
        <div className="paginationSection">
          <Pagination
            // hideNavigation
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={memberPlanCountData}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={handlePagination}
          />
        </div>
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch" onClick={onBlurItemCategory}>
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  value={getSelectDoctor}
                  id="searchTextDr"
                  name="searchTextDr"
                  autoComplete="off"
                  class="ui-autocomplete-input inputField"
                  placeholder="Select Doctor"
                  onKeyUp={doctorDropdown}
                  onChange={(e) => setSelectDoctor(e.target.value)}
                />
                <div>
                  <ul
                    className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
                    id="doctorListDiv"
                    style={{ display: "none" }}
                  >
                    {doctorList.map((doctorObj, i) => (
                      <ul>
                        <li onClick={(e) => setDoctorName(doctorObj, i)}>
                          <span id={"doctorNameLabel" + i}>
                            {doctorObj.doctorName}
                          </span>
                        </li>
                      </ul>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <select
                  className="inputField"
                  id="selectName"
                  name="selectName"
                  title="Search In"
                  onChange={changeText}
                >
                  <option value="0">Search In</option>
                  <option value="1">Patient Name</option>
                </select>
              </div>
              <div className="col-md-2 dashboardSearchList">
                {DataFlagSearch == "0" ? (
                  <input
                    type="text"
                    className="inputField"
                    id="searchText"
                    name="searchText"
                    style={{ background: "transparent" }}
                  />
                ) : (
                  <input
                    type="text"
                    className="inputField"
                    id="searchText"
                    name="searchText"
                    style={{ background: "white" }}
                  />
                )}
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    required
                    className="Datepicker pa2 inputField"
                    calendarClassName="rasta-stripes"
                    maxDate={new Date()}
                    placeholderText="From Date"
                    selected={fromDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setFromDate(date)}
                    id="fromDate"
                    peekNextMonth
                    showMonthDropdown
                    autoComplete="off"
                    showYearDropdown
                    dropdownMode="select"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    required
                    className="Datepicker pa2 inputField"
                    placeholderText="To Date"
                    maxDate={new Date()}
                    calendarClassName="rasta-stripes"
                    selected={toDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setToDate(date)}
                    id="toDate"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    autoComplete="off"
                    dropdownMode="select"
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span className="dashButtonBorder" onClick={searchFunction}>
                  Search
                </span>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder dashButtonBorderGrey"
                  onClick={csvFileDownload}
                >
                  Export
                </span>
              </div>
            </div>
            <div className="row dflexend ">
              <div className="col-md-3">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total patient medicine :{" "}
                  {memberPlanData.length == 0 ? 0 : memberPlanCountData}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboardtable">
            {isData == 0 ? (
              <Loader />
            ) : memberPlanData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Patient Name </th>
                    <th>Doctor </th>
                    <th>Medicine List </th>
                  </tr>
                </thead>
                <tbody>
                  {memberPlanData.map((index) => (
                    <tr>
                      <td>{index.cdate}</td>
                      <td>{index.pateint}</td>
                      <td>{index.doctname}</td>{" "}
                      <td>
                        <nav onClick={() => patientMedicineClick({
                          index: index.doctorvisitid,
                          index1: index.mid,
                          medname: index.medname,
                          doctorid: index.did,
                        })}
                        >
                          <img src={Eyeicon} title="Medicines List" />
                        </nav>{" "}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="paginationSection">
          {memberPlanCountData > 10 && (
            <Pagination
              // hideNavigation
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={memberPlanCountData}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export default PatientMedicine;

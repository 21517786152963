import React, { useEffect, useState, Suspense } from 'react';
import Editicon from '../images/icons/edit-bg.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ImageUploadEdit from '../images/icons/editing.png';
import PhoneImage from '../images/icons/phoneImage.png';
import {
	faEdit,
	faSearch,
	faMale,
	faPhoneAlt,
	faExclamation,
	faCalendarAlt,
	faEnvelope,
	faTint,
	faTabletAlt,
} from '@fortawesome/free-solid-svg-icons';
import girl1 from '../images/media/noImage.png';
import girl from '../images/media/noimageGirl.png';
import boy from '../images/media/noimageBoy.png';
import moment from 'moment';
import { callingAPI } from '../config';
import Datepicker from 'react-datepicker';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AWS, { GlobalAccelerator } from 'aws-sdk';
let Header = React.lazy(() => import("../layout/header"))
let Footer = React.lazy(() => import("../layout/footer"))
let NoPatientletterFound = React.lazy(() => import("../Common/noPatientletterFound"))
let WebcamCapture = React.lazy(() => import("./webcam"))
var s3;
var getS3folder =
	process.env.REACT_APP_S3_URI +
	process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE +
	process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
const letters = [
	"ALL", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"
]
function PatientListing(props) {
	const [getHospitalListByDoctorApp, setHospitalListByDoctorApp] = useState([]);
	const [state, setState] = useState({ imageName: '', mobileNumber: '' });
	const [getHospitalNameChangeApp, hospitalNameChangeApp] = useState(window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN'
		? localStorage.getItem("hospitalId")
		: '');
	const [capturedData, setData] = useState({});
	const [isFetching, setIsFetching] = useState(false);
	const [patientsListing, setPatientsListing] = useState([]);
	const [imagePath, setImagePath] = useState('');
	const [file, setFile] = useState('');
	const [web, setWeb] = useState('');
	const [getMrFlag, setMrFlag] = useState(true);
	const [freeArticleData1234567, setFreeArticleData1234567] = useState([]);
	const [picture, setPicture] = useState(null);
	const [loaderFlag, setLoaderFlag] = useState(false);
	const [display, setDisplay] = useState(false);
	const [memberIdNew, setMemberIdNew] = useState('');
	const [imgData, setImgData] = useState(null);
	const [getOldMr, setOldMr] = useState('');
	const [getTimeImage, setTimeImage] = useState('');
	const [getIsRescheduled, setIsRescheduled] = useState(false);
	const [freeArticleData12345, setFreeArticleData12345] = useState([]);
	const [freeArticleData123456Listing, setFreeArticleData123456Listing] = useState([]);
	const [freeArticleData123456Listing1, setFreeArticleData123456Listing1] = useState([]);
	const [imageName, setImageName] = useState('');
	const [getHospitalNameChange, setHospitalNameChange] = useState(localStorage.getItem('hospitalId'))
	const [image, setImage] = useState('');
	const [getHidisNewUser, setHidisNewUser] = useState('newuser');
	const [base64, setBase64] = useState('');
	const [getHospitalListByDoctor, setHospitalListByDoctor] = useState([]);
	const [getCount, setCount] = useState(0);
	const [popaler, setpopaler] = useState([]);
	const [flag, setFlag] = useState(false);
	const [Dataflag, setFlag2] = useState(false);
	const [getMemberId, setMemberId] = useState('');
	const [popUpDoctorList, setPopUpDoactorList] = useState(false);
	const [imagePreview, setImagePreview] = useState(girl1);
	const [isData] = useState(0);
	const [freeArticleData123, setFreeArticleData123] = useState([]);
	const [newDate, setDate] = useState(new Date());
	const [areaCode, setAreaCode] = useState('+91');
	const [getLastMrNo, setLastMrNo] = useState('');
	const [mobileNumber, setMobileNumber] = useState('');
	const [serch, setSerch] = useState('')
	const [mrNo, setMrNo] = useState('');
	const [prNo, setPrNo] = useState('');
	const [prNoAutoComplete, setPrNoAutoComplete] = useState('');
	const [isSavingClick, setIsSavingClick] = useState(true);
	const MacAddress = require('get-mac-address');
	// var macaddress = require('macaddress');
	const [getHidDocId, setHidDocId] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
	);
	const [getSelectDoctor, setSelectDoctor] = useState(
		window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
			? window.sessionStorage.getItem('userName') +
			'(' +
			window.sessionStorage.getItem('specializationName') +
			')'
			: ''
	);
	const [patientFirstName, setPatientFirstName] = useState('');
	const [patientLastName, setPatientLastName] = useState('');
	const [patientBloodGroup, setPatientBloodGroup] = useState('3');
	const [zip, setZip] = useState('Consultation');
	const [freeArticleData123456, setFreeArticleData123456] = useState([]);
	const [getFlagClear, setFlagClear] = useState(false);
	const [patientGender, setPatientGender] = useState('');
	const [emailId, setEmailId] = useState('');
	const [getdoctorvisitId, setdoctorvisitId] = useState('');
	const [getPatientAppointmentId, setPatientAppointmentId] = useState('');
	const [getSearchLetter, setSearchLetter] = useState('');
	const [activePage, setActivePage] = useState('ALL');
	const [getStatus, setStatus] = useState([]);
	const [getSave, setSave] = useState(false);

	const [smsFlag, setSmsFlag] = useState(0);

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
		secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
		region: 'us-east-1',
	});
	var bucketParams = {
		Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
	};
	s3 = new AWS.S3({ apiVersion: '2006-03-01', params: bucketParams });
	const abcA = (a) => {
		if (a === "true") {
			setState({
				isData: 0,
			});
		}
		setWeb(a)
	};
	const getIsPrNoEnableFlag = () => {
		const PostData = {
			"hospitalId": localStorage.getItem("hospitalId"),

		};
		callingAPI('commonUserFunctions/getIsPrNoEnableFlag', PostData).then((response) => {
			if (response.data.success === '1') {
				window.sessionStorage.setItem("isPrNumEnabled", response.data.result[0].is_prno_enabled);
			}
			else {
				// alert(response.data.errorMessage)
				toast.error(response.data.errorMessage)
			}
		})
			.catch();
	}
	const handleImageUploadOnChange = (e) => {
		setData({});
		setImage("");
		setFile(e.target.files[0]);
		setImageName(e.target.files[0].name);
		setState({
			imageName: e.target.files[0].name,
			mobileNumber: mobileNumber,
		});
		setImagePreview(URL.createObjectURL(e.target.files[0]));
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('uploadId').style.display = 'none';
	};
	const getNewMr = (id) => {
		const apiJson = {
			"hospitalId": id,
			"memberId": "0"
		};
		setLoaderFlag(false)
		callingAPI('calenderListing/autoCompleteGetMrNo', apiJson).then((data) => {
			if (data.data.success === '1') {
				if (data.data.result[0].isMrNoExist === false) {
					setLastMrNo(data.data.result[0].mrNo)
					setLoaderFlag(true)
				}
			}
		});
	};

	const setFreeArticleData234567 = (data, id2) => {
		document.getElementById('mobileNumber').value = data.secUserMobileNumber;
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setOldMr(data.mrNo)
		setPrNoAutoComplete(data.prNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setMemberId(data.memberId);
		setPatientGender(data.memberGender);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setImageName(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' || data.memberImageName !== 'null' || data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(girl1);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';
		setFreeArticleData1234567([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};
	const cityAutocomplete23456 = (mode, dropdownId) => {
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
			setMrNo('')
		}
		else {
			setMrNo(getLastMrNo)
		}
		document.getElementById('locationSearch1234567').style.display = 'none';
		document.getElementById('locationSearch12345').style.display = 'none';
		setMrFlag(true);
		if (getMrFlag === true) {
			setMrFlag(false);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrNoId').style.display = 'block';
				document.getElementById('mrNo').style.display = 'none';
				document.getElementById('mrBorderId').style.display = 'none';
				document.getElementById('editiconId').style.display = 'block';
			}
			document.getElementById('newUsertextId').style.display = 'block';

		}
		if (getFlagClear === true) {
			document.getElementById('mobileNumber').value = '';
			setAreaCode('+91');
			setPatientLastName('');
			setEmailId('');
			setPrNoAutoComplete("")
			setState({
				mobileNumber: '',
			});
			setImagePreview(girl1);
			setMrNo(getLastMrNo);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			setDate(new Date());
			setTimeImage('');
			setFlagClear(false);
			setHidisNewUser('newuser');
		}
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123456(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			setFreeArticleData123456([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		setFreeArticleData1234567([]);
		setFreeArticleData12345([]);
	};
	const EditiconShow = () => {
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('editiconId').style.display = 'none';
			document.getElementById('mrNo').style.display = 'block';
			document.getElementById('mrNoId').style.display = 'none';
			document.getElementById('mrBorderId').style.display = 'none';
		}
	};
	const cancel = () => {
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('savePopUpId').style.display = 'none';
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setPrNoAutoComplete("")
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setSelectDoctor('');
		setMrNo('');
		setHidDocId('');
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
		setIsRescheduled(false);
	};
	const cityAutocomplete23456Listing = (mode, dropdownId) => {
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		const apiJson = {
			hospitalId: getHospitalNameChange,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123456Listing(data.data.result);
					setFreeArticleData123456Listing1([])
				} else {
					setFreeArticleData123456Listing([]);
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData123456Listing1(data.data.errorMessage);
				}
			});
		} else {
			document.getElementById(dropdownId).style.display = 'none';
		}
	};
	const setFreeArticleData23456Listing = (id) => {
		if (window.sessionStorage.getItem("role") !== "ROLE_DOCTOR") { popup(id); }
		else {
			window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
			window.sessionStorage.setItem('memberId', id);
			localStorage.setItem('hospitalId', getHospitalNameChange);
			window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
			window.location.href = '/patients/patientconsult';
		}
	};
	const cityAutocomplete2345 = (mode, dropdownId) => {
		var flag = true;
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
			// alert('Please Select Hospital');
			toast.error('Please Select Hospital');
			flag = false;
		}
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
			setMrNo('')
		} else {
			setMrNo(getLastMrNo)
		}
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData12345(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			setFreeArticleData12345([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
	};

	const setFreeArticleData2345 = (data, id2) => {
		document.getElementById('mobileNumber').value = data.secUserMobileNumber;
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setOldMr(data.mrNo)
		setPrNoAutoComplete(data.prNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setMemberId(data.memberId);
		setPatientGender(data.memberGender);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setImageName(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' || data.memberImageName !== 'null' || data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(girl1);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';
		setFreeArticleData123456([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};
	const onCaptureHandler = (captureData) => {
		setData(captureData);
	};
	const editAppointment = (data, id, memberId1) => {

		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'block';
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrBorderId').style.display = 'none';
		}
		document.getElementById('savePopUpIdNew').style.display = 'block';
		document.getElementById('newUsertextId').style.display = 'none';
		if (
			data.gender === 'male' ||
			data.gender === 'Male'
		) {
			document.getElementById('radio1').checked = true;
		}
		if (
			data.gender === 'female' ||
			data.gender === 'Female'
		) {
			document.getElementById('radio2').checked = true;
		}
		setPatientAppointmentId(id);
		setImagePreview(girl1);
		setAreaCode(data.secUserAreaCode)
		setMobileNumber(data.mobileNumber)
		setState({
			mobileNumber: data.mobileNumber
		});
		setOldMr(data.memberMrNo)
		setMrNo(data.memberMrNo)
		setPrNo(data.memberPrNo)
		setPatientFirstName(data.memberFirstName)
		setMemberId(memberId1)
		setPatientLastName(data.memberLastName)
		setEmailId(data.emailId)
		setZip('Consultation')
		setImageName(data.imageName)
		if (data.imageName !== '' && data.imageName !== 'null' && data.imageName !== null) {
			setImagePreview(getS3folder + '/' + data.imageName);
		} else {
			setImagePreview(girl1);
		}
		hospitalNameChangeApp(getHospitalNameChange);

		setdoctorvisitId('')
		setHidDocId(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
		);
		if (popaler.length == 1) { setHidDocId(popaler[0].doctorId) }
		else
			if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') { setHidDocId(window.sessionStorage.getItem('userId')) }
			else { setHidDocId('') }
		setPatientGender(data.gender)
		setPatientGender(data.gender)
		setSelectDoctor(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? window.sessionStorage.getItem('userName') +
				'(' +
				window.sessionStorage.getItem('specializationName') +
				')'
				: ''
		);
		document.getElementById('zip').style.border = '1px solid red';
		document.getElementById('appoinmentDateTime').style.border = '1px solid red';
		setPatientBloodGroup('3');
		setDate(new Date());
		setHidisNewUser('');
		setIsRescheduled(false);
		document.getElementById('mobileNumber').value = data.mobileNumber;
	};
	const handleChange = (event) => {
		if (event.target.id === 'mobileNumber') {
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			setMrFlag(true);
			if (getMrFlag === true) {
				var flag1 = true;
				if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
					// alert('Please Select Hospital');
					toast.error('Please Select Hospital');
					setMrNo('');
					if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
						document.getElementById('mrBorderId').style.display = 'block';
					}
					flag1 = false;
				} else {
					setMrFlag(false);
					if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
						document.getElementById('mrNoId').style.display = 'block';
						document.getElementById('mrNo').style.display = 'none';
						document.getElementById('editiconId').style.display = 'block';
					}
					document.getElementById('newUsertextId').style.display = 'block';

				}
			}
			if (getFlagClear === true) {
				setPatientFirstName('');
				setAreaCode('+91');
				setPatientLastName('');
				setEmailId('');

				setMrNo(getLastMrNo);
				if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
					document.getElementById('mrNoId').style.display = 'block';
					document.getElementById('mrBorderId').style.display = 'none';
				}
				document.getElementById('radio1').checked = false;
				document.getElementById('radio2').checked = false;
				setDate(new Date());
				setTimeImage('');
				setImagePreview('');
				setFlagClear(false);
				setHidisNewUser('newuser');
				setImagePath('');
				setPrNoAutoComplete('')
				setImagePreview(girl1);
			}
			setFreeArticleData1234567([]);
			setFreeArticleData123456([]);
		}
		var flag = true;
		const re = /^[0-9\b]+$/;
		if (re.test(event.target.value) || event.target.value === '') {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = event.target.value;
				setMobileNumber(document.getElementById('mobileNumber').value);
			}
		} else {
			if (event.target.id === 'mobileNumber') {
				document.getElementById('mobileNumber').value = mobileNumber;
				flag = false;
			}
		}
		const { name, value } = event.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
	};
	const fileUpload = () => {
		if (Object.keys(capturedData).length === 0) {
			var S3BucketMainFolder =
				process.env.REACT_APP_S3_ENVIRONMEWNT_TYPE + process.env.REACT_APP_PATIENT_PROFILE_S3_IMAGE_PATH;
			var fullFilePath = S3BucketMainFolder + '/' + imageName;
			s3.upload(
				{
					Key: fullFilePath,
					Body: file,
					ACL: 'public-read',
				},
				(err, data) => {
					if (err) {
						// alert('There was an error uploading your photo: ' + err.message);
						toast.error('There was an error uploading your photo: ' + err.message);
						return null;
					} else {
					}
				}
			);
		} else {
			s3.putObject(capturedData, (err, data) => {
				if (err) {
					// alert('There was an error uploading your photo: ' + err.message);
					toast.error('There was an error uploading your photo: ' + err.message);
				} else {
				}
			});
		}
	};
	const setFreeArticleData23456 = (data, id2) => {
		document.getElementById('mobileNumber').value = data.secUserMobileNumber;
		setState({
			mobileNumber: data.secUserMobileNumber,
		});
		setMrNo(data.mrNo);
		setOldMr(data.mrNo)
		setPrNoAutoComplete(data.prNo)
		setPatientFirstName(data.memberFirstName);
		setAreaCode(data.secUserAreaCode);
		setPatientLastName(data.memberLastName);
		setEmailId(data.secUserEmailId);
		setPatientGender(data.memberGender);
		setMemberId(data.memberId);
		if (data.memberGender === 'Male' || data.memberGender === 'male') {
			document.getElementById('radio1').checked = true;
		}
		if (data.memberGender === 'Female' || data.memberGender === 'female') {
			document.getElementById('radio2').checked = true;
		}
		setDate(new Date());
		setImageName(data.memberImageName);
		setHidisNewUser('');
		if (data.memberImageName !== '' || data.memberImageName !== 'null' || data.memberImageName !== null) {
			setImagePreview(getS3folder + '/' + data.memberImageName);
		} else {
			setImagePreview(girl1);
		}
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			document.getElementById('mrNoId').style.display = 'block';
			document.getElementById('mrNo').style.display = 'none';
			document.getElementById('editiconId').style.display = 'none';
		}
		document.getElementById('newUsertextId').style.display = 'none';
		setFreeArticleData123456([]);
		document.getElementById(id2).style.display = 'none';
		setFlagClear(true);
	};

	const cityAutocomplete234567 = (mode, dropdownId) => {
		if (document.getElementById(mode).value === '') {
			document.getElementById(dropdownId).style.display = 'none';
		}
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch12345').style.display = 'none';
		setMrFlag(true);
		if (getMrFlag === true) {
			setMrFlag(false);
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrNoId').style.display = 'block';
				document.getElementById('mrBorderId').style.display = 'none';
				document.getElementById('mrNo').style.display = 'none';
				document.getElementById('editiconId').style.display = 'block';
			}
			document.getElementById('newUsertextId').style.display = 'block';

		}
		if (getFlagClear === true) {
			document.getElementById('mobileNumber').value = '';
			setState({
				mobileNumber: '',
			});
			setAreaCode('+91');
			setPatientFirstName('');
			setEmailId('');
			setMrNo(getLastMrNo);
			setPrNoAutoComplete('')
			if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
				document.getElementById('mrBorderId').style.display = 'none';
			}
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			setDate(new Date());
			setTimeImage('');
			setImagePreview(girl1);
			setFlagClear(false);
			setHidisNewUser('newuser');
		}
		const apiJson = {
			hospitalId: getHospitalNameChangeApp,
			searchKeyword: document.getElementById(mode).value,
		};
		if (document.getElementById(mode).value.length > 2) {
			callingAPI('patientAppointment/autoCompleteByPatientNameAndMobileNo', apiJson).then((data) => {
				if (data.data.success === '1') {
					document.getElementById(dropdownId).style.display = 'block';
					setFreeArticleData1234567(data.data.result);
				} else {
					document.getElementById(dropdownId).style.display = 'none';
				}
			});
		} else {
			setFreeArticleData1234567([]);
			document.getElementById(dropdownId).style.display = 'none';
		}
		setFreeArticleData123456([]);
		setFreeArticleData12345([]);
	};
	const setFreeArticleData23 = (dropdownId) => {
		setSelectDoctor(dropdownId);
		setHidDocId(dropdownId);
		setFreeArticleData123([]);
	};
	const cityAutocomplete23 = (mode, dropdownId) => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			userId: '',
			searchKeyWord: '',
		};
		callingAPI('patientAppointment/doctorAutoComplteByName', apiJson).then((data) => {
			if (data.data.success === '1') {
				setpopaler(data.data.result);
			} else {
			}
		});
	};

	const addPatientSave = async () => {
		setLoaderFlag(false)
		if (file != '' || image != '') {
			await fileUpload();
		}
		await addPatientSaveNew();
	};
	const addPatientSaveNew = () => {
		setLoaderFlag(false)
		var isMrNoEdit = false
		if (mrNo === getOldMr) {
			isMrNoEdit = false
		}
		else {
			isMrNoEdit = true
		}
		const apiJson1 = {
			"hospitalId": getHospitalNameChangeApp,
			"mrNo": mrNo,
			"memberId": getMemberId,
			"Newuser": getHidisNewUser === "newuser" ? "1" : "0"
		};
		callingAPI('addPatient/checkMrNo', apiJson1).then((data) => {
			if (data.data.success === '1') {
				var dataNew = Date.parse(document.getElementById('appoinmentDateTime').value);
				var flag = true;
				var characters = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				var email = document.getElementById('emailId').value;
				var splitemail = email.split(/\r|\r\n|\n/);
				var emailcount = splitemail.length;
				setLoaderFlag(true)

				if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalNameChangeApp === '') {
					// alert('Please Select Hospital');
					toast.error('Please Select Hospital');
					flag = false;
				} else if (document.getElementById('mobileNumber').value === '') {
					// alert('Please Fill Mobile Number');
					toast.error('Please Fill Mobile Number');
					document.getElementById('mobileNumber').focus();
					flag = false;
				}
				else if (mrNo === '') {
					// alert('Mr Number Required');
					toast.error('Mr Number Required');
					document.getElementById('mrNo').focus();
					flag = false;
				}
				else if (document.getElementById('appoinmentDateTime').value === '') {
					// alert('Please select date and time for appointment');
					toast.error('Please select date and time for appointment');
					document.getElementById('appoinmentDateTime').focus();
					flag = false;
				}
				else if (document.getElementById('mobileNumber').value.length < 10) {
					// alert('Mobile number must be 10 digits');
					toast.error('Mobile number must be 10 digits');
					document.getElementById('mobileNumber').focus();
					flag = false;
				} else if (patientFirstName === '') {
					// alert('Please Fill First Name  ');
					toast.error('Please Fill First Name  ');
					document.getElementById('patientFirstName').focus();
					flag = false;
				} else if (getHidDocId == '') {
					// alert('Please Select Doctor Name ');
					toast.error('Please Select Doctor Name ');
					flag = false;
				} else if (patientGender === '') {
					// alert('Gender Required');
					toast.error('Gender Required');
					flag = false;
				} else if (
					characters.test(splitemail[emailcount - 1]) === false &&
					document.getElementById('emailId').value !== ''
				) {
					// alert('Invalid Email Id');
					toast.error('Invalid Email Id');
					document.getElementById('emailId').focus();
					flag = false;
				} else if (zip === '') {
					// alert('Please Fill Reason For Visit');
					toast.error('Please Fill Reason For Visit');
					document.getElementById('zip').focus();
					flag = false;
				}
				if (flag === true) {
					setIsSavingClick(false);
					setLoaderFlag(false);
					const apiJson = {
						hidisNewUser: getHidisNewUser,
						//hidisNewUser: '',
						memberId: getMemberId,
						siteId: window.sessionStorage.getItem('siteId'),
						patientAppointmentDateTime: moment(newDate).format('yyyy-MM-DD hh:mm A'), //document.getElementById('appoinmentDateTime').value,
						memberFirstName: patientFirstName,
						memberLastName: patientLastName,
						memberEmailId: emailId,
						secUserAreaCode: areaCode,
						memberMobileNumber: document.getElementById('mobileNumber').value,
						memberGender: patientGender,
						// "memberImageName":image||imageName,
						memberImageName: image ? image : imageName,
						patientAppointmentId: getPatientAppointmentId,
						doctorVisitId: getdoctorvisitId,
						userId: window.sessionStorage.getItem('userId'),
						patientAppointmentType: "Walk In",
						reScheduledAppointment: [],
						doctorId: getHidDocId,
						hospitalId: getHospitalNameChangeApp,
						firstReason: zip,
						secondReason: '',
						patientAppointmentStartTime: newDate != '' ? Date.parse(newDate) : dataNew,
						patientAppointmentEndTime: newDate != '' ? Date.parse(newDate) + 1800000 : dataNew + 1800000, //Date.parse(newDate)+1800000,
						doctorVisitReasonForVisitByPatient: zip,
						doctorVisitReasonByDoctor: '',
						doctorVisitDischargeSummary: '',
						doctorVisitDetails: '',
						mrNo: mrNo,
						patientAppointmentStatusId: document.getElementById("patientBloodGroup").value,
						patientAppointmentLastVisitedDate: '',
						patientAppointmentLastVisitedReason: '',
						patientAppointmentTokenNo: '',
						isRescheduled: getIsRescheduled,
						isMrNoEdit: isMrNoEdit,
						memberIsSmsAlert: 'false',
						memberIsEmailAlert: 'false',
						fromCalender: ""
					};
					callingAPI('clinicalBackground/saveOrUpdateAppointments', apiJson).then((data) => {
						if (data.data.success === '1') {
							setIsSavingClick(true);
							setLoaderFlag(true);
							// alert(data.data.successMessage);
							toast.success(data.data.successMessage);
							setSmsFlag(data.data.result.smsFlag)
							let statusIdForSMS = (document.getElementById("patientBloodGroup").value)
							sendSmsEmail(moment(newDate).format('yyyy-MM-DD hh:mm A'), data.data.result.newPatientAppointmentId)
							document.getElementById('savePopUpId').style.display = 'none';
							document.getElementById('savePopUpIdNew').style.display = 'none';
							setFile('');
							setImageName('');
							setImagePreview('');
							setImage('');
							setBase64('');
							setDisplay(false);
							setImagePath('');
							setAreaCode('');
							setMobileNumber('');
							setSelectDoctor('');
							setMrNo('');
							setHidDocId('');
							setPatientFirstName('');
							setPatientLastName('');
							setPatientGender('');
							setEmailId('');
							setPatientBloodGroup('');
							setZip('');
							setMemberId('');
							setIsRescheduled(false);
							setTimeout(() => {
								window.location.href = "/patients/appointment";
							}, 2000);
						} else {
							setLoaderFlag(true)
							// alert(data.data.errorMessage)
							toast.error(data.data.errorMessage)
							document.getElementById('savePopUpId').style.display = 'block';
						}
					});
				}
			} else {
				// alert(data.data.errorMessage);
				toast.error(data.data.errorMessage);
				setLoaderFlag(true)
				setMrNo(getLastMrNo)
				document.getElementById('mrNo').focus()
			}
		});
	};

	const sendSmsEmail = (appointmentDate, newPatientAppointmentId) => {
		const postData = {
			"hospitalId": getHospitalNameChangeApp,
			"patientAppointmentId": getPatientAppointmentId,
			"siteId": window.sessionStorage.getItem('siteId'),
			"changedAppointmentDateTime": appointmentDate,
			"hidisNewUser": getHidisNewUser,
			"smsFlag": smsFlag,
			"memberEmailId": emailId,
			"memberMobileNumber": getHidisNewUser === "newuser" ? mobileNumber : state.mobileNumber,
			"newPatientAppointmentId": newPatientAppointmentId,
			"changeDate": moment(appointmentDate).format('YYYY-MM-DD'),
			"changeTime": moment(appointmentDate).format('hh : mm A'),
			"isRescheduled": getPatientAppointmentId == "" ? false : true,
			"patientAppointmentType": ""
		};
		callingAPI('commonUserFunctions/saveOrUpdateAppointmentEmailAndSms', postData).then((data) => {
			if (data.data.success === '1') {

			}
		})
			.catch();
	};

	const add = () => {
		setState({
			isData: 1,
		});
		document.getElementById('imageThumb1').style.display = 'none';
		document.getElementById('imageThumb2').style.display = 'none';
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('uploadId1').style.display = 'none';
		document.getElementById('takeSnapshotId1').style.display = 'none';
	};
	const webCamImageFunction = (image, base64) => {
		setFile('')
		setImageName('')
		setImage(image);
		setImagePreview(base64);
		setState({
			isData: 0,
		});
	};
	const changeImage = () => {
		setDisplay(true);
		document.getElementById('uploadId').style.display = 'block';
		document.getElementById('takeSnapshotId').style.display = 'block';
		document.getElementById('uploadId1').style.display = 'block';
		document.getElementById('takeSnapshotId1').style.display = 'block';
	};
	const closePopUp1 = () => {
		document.getElementById('uploadId').style.display = 'none';
		document.getElementById('takeSnapshotId').style.display = 'none';
		document.getElementById('savePopUpId').style.display = 'none';
		if (window.sessionStorage.getItem('isPrNumEnabled') === "false") {
			var a = document.getElementById('mrNo').value = ""
		}
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setMrNo(a);
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
	};
	const getStatusNew = () => {
		callingAPI('patientAppointment/patientStatusDropdown', '').then((data) => {
			if (data.data.success === '1') {
				setStatus(data.data.result);
			} else {
			}
		});
	};
	const addAppointment = () => {
		setLoaderFlag(false)
		document.getElementById('savePopUpId').style.display = 'block';
		setImagePreview(girl1);
		getStatusNew();
		hospitalNameChangeApp(
			window.sessionStorage.getItem('role') === 'ROLE_HOSPITALADMIN'
				? localStorage.getItem('hospitalId')
				: getHospitalListByDoctorApp.length === 1 ? localStorage.getItem('hospitalId') : getHospitalNameChange
		);
		setPatientGender('');
		document.getElementById('radio1').checked = false;
		document.getElementById('radio2').checked = false;
		setDate(new Date());
		fetchMyAPIApp();
		setAreaCode('+91');
		setState({ mobileNumber: '' });
		setHidisNewUser('newuser');
		setPatientBloodGroup('3');
		setZip('Consultation');
		setIsRescheduled(false);
		setdoctorvisitId('');
		setPatientAppointmentId('');
		setHidDocId(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
		);
		if (popaler.length == 1) { setHidDocId(popaler[0].doctorId) }
		else
			if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') { setHidDocId(window.sessionStorage.getItem('userId')) }
			else { setHidDocId('') }
		setSelectDoctor(
			window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
				? window.sessionStorage.getItem('userName') +
				'(' +
				window.sessionStorage.getItem('specializationName') +
				')'
				: ''
		);

		setLoaderFlag(true)
	};
	var e = document.querySelectorAll('option');
	e.forEach((x) => {
		if (x.textContent.length > 40) x.textContent = x.textContent.substring(0, 30) + '...';
	});
	const listing = (a) => {
		const apiJson = {
			hospitalId: localStorage.getItem('hospitalId'),
			memberId: '',
			alphaSearch: a,
			maxlimit: 20,
			offset: '0',
		};
		callingAPI('patientsListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPatientsListing(data.data.result);
				setCount(data.data.count);
				fetchMyAPI();
				setFlag2(true);
			}
		});
	};

	async function fetchMyAPIApp() {
		const apiJson = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('patientsListing/getHospitalListByDoctor', apiJson).then((data) => {
			if (data.data.success === '1') {
				//fetchMyAPI();
				setHospitalListByDoctorApp(data.data.result);
				if (data.data.result.length === 1) {
					hospitalNameChangeApp(localStorage.getItem('hospitalId'));
					getNewMr(localStorage.getItem('hospitalId'));
				} else {
					if (window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR') {
						getNewMr(localStorage.getItem("hospitalId"));
						hospitalNameChangeApp(localStorage.getItem("hospitalId"));
					}
					else {
						getNewMr(data.data.result[0].hospitalId);
						hospitalNameChangeApp(data.data.result[0].hospitalId);
					}
				}
			}
		});
	}

	const hospitalNameChangeApp1 = (data) => {
		hospitalNameChangeApp(data)
		setFlagClear(true)
		if (data != "") {
			getNewMr(data)
		}
		if (data !== getHospitalNameChangeApp) {
			setImagePreview(girl1);
			setFile('');
			setImageName('');
			document.getElementById('mrBorderId').style.display = 'block';
			setImage('');
			setBase64('');
			setDisplay(false);
			setImagePath('');
			setMobileNumber('');
			setPatientFirstName('');
			setPatientLastName('');
			setPatientGender('');
			setEmailId('');
			document.getElementById('radio1').checked = false;
			document.getElementById('radio2').checked = false;
			setMemberId('');
			getStatusNew();
			setDate(new Date());
			//	fetchMyAPIApp();
			setAreaCode('+91');
			setState({
				mobileNumber: '',
			});
			setHidisNewUser('newuser');
			setPatientBloodGroup('3');
			setZip('Consultation');
			setIsRescheduled(false);
			setdoctorvisitId('');
			setPatientAppointmentId('');
			setMrNo('');
			setHidDocId(
				window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? window.sessionStorage.getItem('userId') : ''
			);
			setSelectDoctor(
				window.sessionStorage.getItem('role') === 'ROLE_DOCTOR'
					? window.sessionStorage.getItem('userName') +
					'(' +
					window.sessionStorage.getItem('specializationName') +
					')'
					: ''
			);
		}
		document.getElementById('mobileNumber').focus()
	};
	useEffect(() => {
		getIsPrNoEnableFlag();
		cityAutocomplete23()
		window.sessionStorage.setItem("qaz", "none");
		window.sessionStorage.setItem("qaz1", "none");
		// macaddress.all(function (err, all) {
		// });
		//  Sticky Header
		window.onscroll = function () { myFunction() };

		var sticky = header ? header.offsetTop : 0;
		var header = document.getElementById("myDIV");
		function myFunction() {

			if (header && window.pageYOffset > header.offsetTop) {
				header.classList.add("sticky");
			} else if (header) {
				header.classList.remove("sticky");
			}
		}
		//  Sticky Header
		getNewMr(localStorage.getItem("hospitalId"));
		listing('');
		getStatusNew();
		fetchMyAPIApp();
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
		getNewMr(localStorage.getItem("hospitalId"));
	}, []);
	useEffect(() => {
		cityAutocomplete23();
		getNewMr(localStorage.getItem("hospitalId"));
		if (!isFetching) return;
		fetchMoreListItems();
	}, [isFetching]);
	const handleScroll = () => {
		if (
			window.innerHeight + document.documentElement.scrollTop + 1 >
			document.documentElement.scrollHeight

		)
			setIsFetching(true);
		return;
	};

	const fetchMoreListItems = (a) => {

		const apiJson = {
			hospitalId: getHospitalNameChange ? getHospitalNameChange : localStorage.getItem('hospitalId'),
			memberId: '',
			alphaSearch: serch,
			maxlimit: 20,
			offset: patientsListing.length,
		};
		callingAPI('patientsListing', apiJson).then((data) => {
			if (data.data.result.length > 0) {
				setCount(data.data.count);
				setTimeout(() => {
					setPatientsListing(patientsListing.concat(data.data.result))
					setIsFetching(false);
				}, 200);
			}
			else {
				setIsFetching(true);
			}
		});
	};
	async function fetchMyAPI() {
		const apiJson = {
			doctorId: window.sessionStorage.getItem('doctorId'),
		};
		callingAPI('patientsListing/getHospitalListByDoctor', apiJson).then((data) => {
			if (data.data.success === '1') {
				if (data.data.result.length === 1) {
					setHospitalNameChange(localStorage.getItem("hospitalId"));
				}
				else {
					if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') {
						setHospitalNameChange(data.data.result[0].hospitalId);
					}
					else {
						setHospitalNameChange(localStorage.getItem("hospitalId"));
					}
					setHospitalListByDoctor(data.data.result);
				}
			}
		});
	}
	const hospitalNameChange = (id) => {
		setActivePage('ALL')
		document.getElementById("patientFirstNameListing").value = ""
		setHospitalNameChange(id);
		const apiJson = {
			hospitalId: id,
			memberId: '',
			alphaSearch: '',
			maxlimit: '20',
			offset: '0',
		};
		callingAPI('patientsListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPatientsListing(data.data.result);
				setCount(data.data.count);
			}
		});

	};

	const setAlpha = (a) => {
		setActivePage(a === "" ? 'ALL' : a)
		setSerch(a)
		setSearchLetter(a.toUpperCase());
		var btnContainer = document.getElementById('myDIV');
		var btns = btnContainer.getElementsByClassName('cd-dot');
		const apiJson = {
			hospitalId: getHospitalNameChange ? getHospitalNameChange : localStorage.getItem('hospitalId'),
			memberId: '',
			alphaSearch: a,
			maxlimit: 20,
			offset: 0,
		};
		callingAPI('patientsListing', apiJson).then((data) => {
			if (data.data.success === '1') {
				setPatientsListing(data.data.result);
				setCount(data.data.count);
				window.addEventListener("scroll", handleScroll);
				return () => window.removeEventListener("scroll", handleScroll);
			} else {
				// alert('error');
				toast.error('error');
			}
		});
	};
	const popup = (memberId) => {
		if (window.sessionStorage.getItem('role') === 'ROLE_DOCTOR') {
			window.sessionStorage.setItem('memberId', memberId);
			window.sessionStorage.setItem('patientDoctorId', window.sessionStorage.getItem('userId'));
			localStorage.setItem('hospitalId', getHospitalNameChange);
			window.sessionStorage.setItem('patientDoctorName', window.sessionStorage.getItem('userName'));
			window.location.href = '/patients/patientconsult';
		} else {
			setMemberIdNew(memberId);
			setFlag(false);
			let PostData = {
				hospitalId: localStorage.getItem('hospitalId'),
				selectCombo: '',
				inputSearch: '',
			};
			callingAPI('commonUserFunctions/doctorsList', PostData).then((response) => {
				if (response.data.success === '1') {
					setFlag(true);
					setpopaler(response.data.result.doctorsListArray);
					setPopUpDoactorList(true);
				}
				else {
					// alert("no doctors found")
					toast.error("no doctors found")
				}
			});
		}
	};
	const closePopUp = () => {
		setPopUpDoactorList(false);
	};
	const hospitalpageChange = (index) => {
		if (index !== '') {
			window.sessionStorage.setItem('memberId', memberIdNew);
			window.sessionStorage.setItem('doctorId', popaler[index].doctorId);
			window.sessionStorage.setItem('medicineBranchId', popaler[index].medicineBranchId);
			window.sessionStorage.setItem('patientDoctorId', popaler[index].doctorId);
			localStorage.setItem('hospitalId', popaler[index].hospitalId);
			window.sessionStorage.setItem('patientDoctorName', popaler[index].doctorName);
			window.sessionStorage.setItem('patientDoctorSpecialation', popaler[index].specialization);
			window.location.href = '/patients/patientconsult';
		}
	};

	const clearData = (e) => {
		document.getElementById('locationSearch12345').style.display = 'none';
		document.getElementById('locationSearch123456').style.display = 'none';
		document.getElementById('locationSearch1234567').style.display = 'none';
	};
	const clearDataListing = (e) => {
		document.getElementById('locationSearch123456Listing').style.display = 'none';
	};
	const filterPassedTime = (time) => {
		const currentDate = new Date();
		const selectedDate = new Date(time);

		return currentDate.getTime() < selectedDate.getTime();
	};
	const closePopUpEdit = () => {
		document.getElementById('savePopUpIdNew').style.display = 'none';
		setFile('');
		setImageName('');
		setImagePreview('');
		setImage('');
		setBase64('');
		setPrNoAutoComplete("")
		setDisplay(false);
		setImagePath('');
		setAreaCode('');
		setMobileNumber('');
		setState({
			mobileNumber: '',
		});
		setPrNo('')
		setMrNo('');
		setPatientFirstName('');
		setPatientLastName('');
		setPatientGender('');
		setEmailId('');
		setPatientBloodGroup('');
		setZip('');
		setMemberId('');
	};
	return (
		<Suspense fallback={<div></div>}>
			<div className="coverWraper" onClick={(e) => clearDataListing()}>
				<ToastContainer position="bottom-right" />
				<Header />
				<div className="contentWraper contentWraperScroll patientList ptList">
					<div className="patientWrapper">
						<div className="patientTopSection">
							<div className="breadcrumbDoc">
								<div className="breadcrumbDocSect">
									<ul>
										<li>
											<a href="/patients/appointment" class="blueLink">
												Practice Management
											</a>
										</li>{' '}
										<div class="arrow-right" />
										<li> Patient Listing </li>
									</ul>
								</div>
							</div>
							<div className="col-md-2 pl_vdo_dpdwn">
								{/* <span>
								<a>
									<img src={Youtube} />{' '}
								</a>{' '}
							</span> */}
								{/* ========== Youtube Begins here =========== */}
								{/* <div className=" pl_vdo_dpdwnAddPatient" style={{ margin: '0px' }}>
								<span>
									<a>
										<img src={Youtube} />{' '}
									</a>{' '}
								</span>
								<div className="selectLanguage" style={{ display: 'none' }}>
									<ul>
										<li>English</li>
										<li>Malayalam</li>
									</ul>
								</div>
							</div> */}
								{/* ========== Youtube Ends here =========== */}
							</div>
						</div>
						<div>
							<div class="selectMenu" id="myDIV">
								<ul>
									{letters.map((item) => (
										<li>
											<span>
												<span className={"cd-dot " + (activePage === item ? "listAct" : "")} id="id" onClick={() => setAlpha(item === "ALL" ? "" : item)}>
													{item}
												</span>
											</span>
										</li>
									))}

								</ul>
							</div>
						</div>

						<div className="container">
							<div className="appointment">

								<div className="titleSection">
									<h4>Patient Listing</h4>
									<span className="borderButton" title="Add Appointment" onClick={() => addAppointment()} tabIndex='21'>
										Add Appointment <span style={{ fontSize: '20px' }}>+</span>
									</span>
								</div>
								<div className="patientSearch">
									{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' && getHospitalListByDoctor.length > 1 ? (
										<div className="patientSearchitem">
											<select
												name="hospitalNameId"
												className="hospitalSelectDropdown  col-md-7 ml-2"
												id="hospitalNameId"
												onChange={(e) => hospitalNameChange(e.target.value)} tabIndex='22'
											>
												{getHospitalListByDoctor.map((data, i) => (
													localStorage.getItem('hospitalId').toString() == data.hospitalId.toString() ?
														<option value={data.hospitalId} selected={true}>{data.hospitalName}</option>
														:
														<option value={data.hospitalId}>{data.hospitalName}</option>
												))}
											</select>
										</div>
									) : (
										''
									)}

									<div className="patientSearchitem">
										<input
											type="text"
											id="patientFirstNameListing"
											name="patientFirstNameListing"
											className="inputField patientSearchBox "
											placeholder="Search Patient"
											autoComplete="off"
											onKeyUp={() =>
												cityAutocomplete23456Listing(
													'patientFirstNameListing',
													'locationSearch123456Listing'
												)} tabIndex='23'
										/>
										<FontAwesomeIcon icon={faSearch} />
										<ul
											className="ui-menu ui-widget ui-widget-content ui-autocomplete ui-front autoSearch1 autoSearch-p0 appointmentNumberSearch "
											id="locationSearch123456Listing"
											style={{ display: 'none' }}
										>
											{freeArticleData123456Listing.length > 0 ? (
												<div>
													{freeArticleData123456Listing.map((freeArticleDataObj, i) => (
														<li
															class="memberListAuto ui-menu-item"
															onClick={() =>
																setFreeArticleData23456Listing(freeArticleDataObj.memberId)}
														>

															<div
																class="memberNAme  ui-menu-item-wrapper"
																id="ui-id-2"
																tabindex="-1" style={{ width: "100%", float: "inherit", maxWidth: "100%" }}
															>
																<span className='PtListDpdwn'>Name </span>: {freeArticleDataObj.memberFirstName}{' '}{freeArticleDataObj.memberLastName}
															</div>
															<div
																class="memberMObile ui-menu-item-wrapper"
																id="ui-id-5"
																tabindex="-1"
															>
																<span className='PtListDpdwn'>Mobile No.</span> : 	{freeArticleDataObj.secUserMobileNumber}
															</div>
															<div
																class="memberEMail elipsis ui-menu-item-wrapper"
																id="ui-id-3"
																tabindex="-1"
															>
																{freeArticleDataObj.secUserEmailId.length > 0 ?
																	<div>
																		<span className='PtListDpdwn'>E-Mail Id</span> : 	{freeArticleDataObj.secUserEmailId}{' '}
																	</div> : ""}
															</div>
															{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																(<div
																	class="memberEMail elipsis ui-menu-item-wrapper"
																	id="ui-id-4"
																	tabindex="-1"
																	style={{ color: "rgb(52, 152, 219)" }}
																>
																	<span className='PtListDpdwn'>MR No.</span> : 	{freeArticleDataObj.mrNo}{' '}
																</div>) :
																(<div
																	class="memberEMail elipsis ui-menu-item-wrapper"
																	id="ui-id-6"
																	tabindex="-1"
																	style={{ color: "rgb(52, 152, 219)" }}
																>
																	{freeArticleDataObj.prNo.length > 0 ?
																		<div>
																			<span className='PtListDpdwn'>PR No.</span> : 	{freeArticleDataObj.prNo}{' '}</div> : ""}
																</div>)
															}
														</li>
													))}
												</div>
											) : ("")}
											{freeArticleData123456Listing1.length > 0 ? (
												<li>
													<div
														id="ui-id-5"
														tabindex="-1" style={{
															fontWeight: "600",
															fontSize: "12px",
															lineHeight: "16px",
															color: "#525252", textTransform: "uppercase"
														}}
													>
														{freeArticleData123456Listing1}
													</div>
												</li>
											) : ("")}
										</ul>
									</div>
									<div className="patientCount">Total Patients : {getCount}</div>
								</div>{' '}
								{/* {loaderFlag === false ? <Loader /> : ''} */}
								{Dataflag == true && (
									<div>
										<div className="gridListSection">
											{patientsListing.map((data, i) => (
												<div className="gridList patentListingg" tabIndex='14' key={i}>
													<div className="gridImage" onClick={(e) => popup(data.memberId, data.medicineBranchId)}>
														<img
															alt='' src={data.imageName === '' || data.imageName === 'null' || data.imageName === null ? data.gender === 'Male' ? (boy) : (girl) : (getS3folder + '/' + data.imageName)}
														/>
													</div>
													<div className="gridContent">
														<div className="gridTop" onClick={(e) => popup(data.memberId, data.medicineBranchId)}>
															<span className="gridPatientName" style={{ color: '#3498db' }}>{data.patientName}</span>
															<br />
															{data.date_of_visit !== '' ? (
																<span
																	style={{ color: '#1d7075', cursor: "default" }}
																	className="gridPatientName visitDate"
																>
																	Last Visited Date : {data.date_of_visit} {data.days > 0 ? <span>( {data.days} days before )</span> : ""}
																</span>
															) : (
																''
															)}
														</div>
														<div className="gridIconsList">
															<div className="gridIconItem  ">
																<FontAwesomeIcon icon={faMale} />
																Gender : {data.gender}
															</div>
															{data.age !== '' && data.dob !== "01-Jan-1900" ? (
																<div className="gridIconItem">
																	<FontAwesomeIcon icon={faExclamation} />
																	Age : {data.age === 0 ? (data._month !== 0 ? (data._month === 1 ? (data._month + " " + "month") : (data._month + " " + "months ")) : (data._day === 1 ? (data._day + " " + "day") : (data._day + " " + "days"))) : (data.age === 1 ? data.age + " " + "year" : data.age + " " + "years")}
																</div>
															) : (
																''
															)}
															{data.dob !== '' && data.dob !== "01-Jan-1900" ? (
																<div className="gridIconItem">
																	<FontAwesomeIcon icon={faCalendarAlt} />

																	DOB : {data.dob}
																</div>
															) : (
																''
															)}
														</div>
														<div className="gridIconsList">
															{window.sessionStorage.getItem('isPrNumEnabled') === "false" ? (<div className="gridIconItem mrNumberView" title={data.memberMrNo}>MR No : {data.memberMrNo}</div>) :
																(data.memberPrNo !== '' &&
																	<div className="gridIconItem mrNumberView" title={data.memberPrNo}>PR No : {data.memberPrNo}</div>
																)}
															<div className="gridIconItem">
																<img alt='' src={PhoneImage} />
																{data.mobileNumber}
															</div>
															{data.emailId !== '' ? (
																<div className="gridIconItem gridIconItemLarge" style={{ width: "100%", maxWidth: "max-content" }}>
																	<FontAwesomeIcon icon={faEnvelope} />
																	{data.emailId}
																</div>
															) : (
																''
															)}
														</div>
													</div>
													{/* </Link> */}
													<div
														title="Add Appointment"
														className="gridEdit"
														onClick={(e) => editAppointment(data, data.appointmentId, data.memberId)} style={{ color: '#3498db' }}
													>
														<FontAwesomeIcon icon={faEdit} />Add Appointment
													</div>
												</div>
											))}
										</div>
										{isFetching}
									</div>
								)}
								{patientsListing == 0 &&
									Dataflag == true && <NoPatientletterFound getSearchLetter={getSearchLetter} />}
								<div />
							</div>
						</div>
					</div>
				</div>
				<Footer />
				<div>
					{popUpDoctorList == true && (
						<div>
							{popaler.length > 1 ?
								<div className="ploverlayy">

									<div className="plpopup plpopupList selectDr " style={{ width: '400px' }}>
										<h2>
											<a class="close" href="#" onClick={closePopUp}>
												&times;
											</a>
										</h2>

										<div className="plcontent">
											<label>
												Select Doctor<span className="mandatory">*</span>
											</label>
											<div className="addDataContent" style={{ padding: '4px 0 0' }}>
												<div className="addDataDiv" id="styled-select">
													{popaler.length > 1 ?
														<select
															id="default"
															style={{ width: '100%' }}
															onChange={(e) => hospitalpageChange(e.target.value)}
														>
															<option value="">Doctor Name</option>
															{popaler.map((Popuplist, i) => (
																<option value={i}>
																	{Popuplist.doctorName}

																</option>
															))}
														</select>
														:
														<select
															id="default"
															style={{ width: '100%' }}
															onChange={(e) => hospitalpageChange(e.target.value)}
														>
															{popaler.map((Popuplist, i) => (
																<option value={i}>
																	{Popuplist.doctorName}
																</option>
															))}
														</select>}

												</div>
											</div>
										</div>

									</div>

								</div>
								: hospitalpageChange(0)}
						</div>
					)}
				</div>
				<div>
					<div className="ploverlayy" id="savePopUpId" style={{ display: 'none' }} onClick={(e) => clearData()}>
						<div className="plpopup plpopupList plAppointmenPopup " id="formId">
							<div class="addDataHead">
								{getSave === false ? (
									<span id="spanAddSupplier" style={{ color: '#3498db' }}>
										Patient Appointment
									</span>
								) : (
									<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
										Edit Appointment
									</span>
								)}
								<a id="btnCancel" title="Close" class="close" onClick={closePopUp1}>
									<span class="popUpClose">x</span>
								</a>
							</div>
							<div className="popBack" id="popup1">
								<div className="popOverlay" />
								<div className="row">
									<div className="customizationPop">
										<div className="customizationPopContentSec">
											<input type="hidden" id="txtHiddenCategoryId" />
											<div
												className="contentWraper contentWraperScroll whiteBg"
												style={{ minHeight: 'auto' }}
											>
												<div className="container">
													<div className="innerContent plAppContent">
														<div className="addpatientContent">
															<div className="row">
																<div className="col-md-2">
																	<div
																		className="profileImg"
																		style={{ width: 'auto' }}
																	>
																		<div className="webCamSection">
																			<div className="webcamOpen1" id="results1" style={{ display: (imagePath) ? 'block' : 'none' }}>
																				<img
																					src={base64 || imagePath || image}
																					alt=''
																					id="imageThumb1"
																					className="webOpenImage1"
																					onClick={changeImage}
																				/>
																				<span
																					className="changeImage"
																					title="Upload Images"
																					onClick={changeImage}
																				>
																					<img alt='' src={ImageUploadEdit} style={{ width: '8px', margin: '0px 3px' }} />Image
																					Upload {' '}
																				</span>
																			</div>
																			<div className="webcamOpen" id="results" style={{ display: (imagePreview) ? 'block' : 'none' }}>
																				<img
																					src={imagePreview}
																					alt=''
																					hover={{ cursor: 'hand' }}
																					id="imageThumb"
																					title="Upload Images"
																					className="webOpenImage"
																					onClick={changeImage}
																				/>
																				<span
																					className="changeImage"
																					title="Upload Images"
																					onClick={changeImage}
																				>
																					<img alt='' style={{ width: '8px', margin: '0px 3px' }} />{' '}
																					{' '}
																				</span>
																			</div>
																			<div
																				className="webcamDiv"
																				style={{
																					display: display ? '' : 'block',
																				}}
																			>
																				<div
																					className="myCamera"
																					id="my_camera"
																				/>
																				<div
																					className="webCamButton"
																					id="imageDivId"
																				>
																					{state.isData === 1 ? (
																						<WebcamCapture
																							onCapture={onCaptureHandler}
																							webCamImage={
																								webCamImageFunction
																							}
																							web={abcA}
																						/>
																					) : (
																						''
																					)}
																					<span
																						style={{ display: 'none' }}
																						id="takeSnapshotId"
																						className="borderButton borderButtonSmall"
																						onClick={add}
																					>
																						Take Snapshot
																					</span>
																					<span
																						style={{ display: 'none' }}
																						id="uploadId"
																						className="uploadButton uploadButtonSmall"
																					>
																						<input
																							type="file"
																							id="file1"
																							onChange={(e) =>
																								handleImageUploadOnChange(
																									e
																								)}
																						/>
																						<label for="file1">
																							Upload
																						</label>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-md-10">
																	<div className="appointmentForm">
																		{window.sessionStorage.getItem('role') ===
																			'ROLE_DOCTOR' ? (
																			<div
																				class="newAppoinmentRightList"
																				style={{
																					display:
																						getHospitalListByDoctorApp.length ===
																							1
																							? 'none'
																							: 'inline-block',
																				}}
																			>
																				<label className="formLabel">
																					Hospital Name<sup>*</sup>
																				</label>
																				<div className="appointmentFormList">
																					<select autofocus="autofocus"
																						name="hospitalNameIdApp"
																						className="formInput"
																						id="hospitalNameIdApp"
																						//onChange={hospitalNameChhospitalNameIdange}
																						value={getHospitalNameChangeApp}
																						onChange={(e) =>
																							hospitalNameChangeApp1(
																								e.target.value
																							)} tabIndex="0" autoFocus
																					>
																						{getHospitalListByDoctorApp.map(
																							(data, i) => (
																								<option
																									value={
																										data.hospitalId
																									}
																								>
																									{data.hospitalName}
																								</option>
																							)
																						)}
																					</select>
																				</div>
																			</div>
																		) : (
																			''
																		)}
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Mobile No<sup>*</sup>
																			</label>
																			<div className="appointmentFormList">
																				<span class="mobileCountrySelect">
																					<input
																						type="text"
																						value={areaCode}
																						id="areaCode"
																						name="areaCode"
																						className="formInput"
																						placeholder="Mobile Number"
																						onChange={(e) =>
																							setAreaCode(e.target.value)}
																					/>
																				</span>
																				<span class="mobileNumberField">
																					<input
																						type="text"
																						value={state.mobileNumber}
																						id="mobileNumber"

																						name="mobileNumber"
																						placeholder="Mobile Number"
																						maxlength="10"
																						onKeyPress={(event) => {
																							if (
																								!/[0-9]/.test(event.key)
																							) {
																								event.preventDefault();
																							}
																						}}
																						className="formInput"
																						onChange={handleChange}
																						autoComplete="off"
																						onKeyUp={() =>
																							cityAutocomplete2345(
																								'mobileNumber',
																								'locationSearch12345'
																							)}
																					/>
																					<ul
																						className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																						id="locationSearch12345"
																						style={{ display: 'none' }}
																					>
																						{freeArticleData12345.map(
																							(freeArticleDataObj, i) => (
																								<li
																									onClick={() =>
																										setFreeArticleData2345(
																											freeArticleDataObj,
																											'locationSearch12345'
																										)}
																								>
																									{
																										freeArticleDataObj.memberDetailsWithMrNo
																									}
																								</li>
																							)
																						)}
																					</ul>
																				</span>
																			</div>
																		</div>
																		{window.sessionStorage.getItem('isPrNumEnabled') === "true" && (
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					PR Number
																				</label>
																				<div className="appointmentFormList" >
																					<input
																						// style={{ display: 'none' }}
																						type="text"
																						className="formInput"
																						value={prNoAutoComplete}
																						id="prNoAutoComplete"
																						maxlength="50"
																						name="prNoAutoComplete"
																						readOnly
																						// onBlur={(e) =>
																						// 	check(e.target.value)}
																						placeholder="PR Number"
																					/>
																				</div>
																			</div>)}
																		{window.sessionStorage.getItem('isPrNumEnabled') === "false" &&
																			(<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					MR Number<sup>*</sup>
																				</label>
																				<div className="appointmentFormList">
																					<input
																						style={{ display: 'none' }}
																						type="text"
																						className="formInput"
																						value={mrNo}
																						id="mrNo"
																						name="mrNo"
																						placeholder="MR Number"
																						onChange={(e) =>
																							setMrNo(e.target.value)}
																					/>

																					<input
																						style={{
																							display: 'block',
																							border: 'none',
																						}}
																						className="formInput"
																						placeholder="MR Number"
																						id="mrBorderId"
																					/>
																					<span
																						class="mrNumberEdit" title={mrNo}
																						id="mrNoId"
																						style={{ display: 'none' }}
																					>
																						{mrNo}
																						<button type='button' className='btn_mrno'>
																							<img
																								title="click here for edit Mr Number"
																								id="editiconId"
																								alt=''
																								style={{ display: 'none' }}
																								src={Editicon}
																								onClick={(e) => EditiconShow()}
																							/>
																						</button>
																					</span>
																				</div>
																			</div>)}
																		{window.sessionStorage.getItem('role') ===
																			'ROLE_DOCTOR' ? (
																			<div
																				class="newAppoinmentRightList plEmptyLabel "
																				style={{
																					display:
																						getHospitalListByDoctorApp.length ===
																							1
																							? 'inline-block'
																							: 'none',
																				}}
																			>
																				<label className="formLabel">
																					&nbsp;
																				</label>
																			</div>
																		) : (
																			''
																		)}
																		{window.sessionStorage.getItem('role') ===
																			'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																			<div class="newAppoinmentRightList plEmptyLabel ">
																				<label className="formLabel">
																					&nbsp;
																				</label>
																			</div>
																		) : (
																			''
																		)}
																		<br />
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				First Name<sup>*</sup>{' '}
																				<span
																					className="blue-text"
																					id="newUsertextId"
																					style={{
																						color: 'green',
																						display: 'block',
																					}}
																				>
																					<b> &nbsp;New User </b>
																				</span>
																			</label>

																			<div className="appointmentFormList">
																				<input
																					type="text"
																					value={patientFirstName}
																					id="patientFirstName"
																					name="patientFirstName"
																					placeholder="FIRST NAME"
																					onChange={(e) =>
																						setPatientFirstName(
																							e.target.value.toUpperCase()
																						)}
																					className="input-design formInput"
																					autoComplete="off"
																					onKeyUp={() =>
																						cityAutocomplete23456(
																							'patientFirstName',
																							'locationSearch123456'
																						)}
																				/>
																				<ul
																					className="autoSearch1 autoSearch-p0 appointmentNumberSearch appointmentNumberSearchW"
																					id="locationSearch123456"
																					style={{ display: 'none' }}
																				>
																					{freeArticleData123456.map(
																						(freeArticleDataObj, i) => (
																							<li
																								onClick={() =>
																									setFreeArticleData23456(
																										freeArticleDataObj,
																										'locationSearch123456'
																									)}
																							>
																								{
																									freeArticleDataObj.memberDetailsWithMrNo
																								}
																							</li>
																						)
																					)}
																				</ul>
																			</div>
																		</div>
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Last Name
																			</label>
																			<div className="appointmentFormList">
																				<input
																					type="text"
																					value={patientLastName}
																					id="patientLastName"
																					name="patientLastName"
																					placeholder="LAST NAME"
																					onChange={(e) =>
																						setPatientLastName(
																							e.target.value.toUpperCase()
																						)}
																					className="input-design formInput"
																					autoComplete="off"
																					onKeyUp={() =>
																						cityAutocomplete234567(
																							'patientLastName',
																							'locationSearch1234567'
																						)}
																				/>
																				<ul
																					className="autoSearch1 autoSearch-p0 appointmentNumberSearch appointmentNumberSearchW"
																					id="locationSearch1234567"
																					style={{ display: 'none' }}
																				>
																					{freeArticleData1234567.map(
																						(freeArticleDataObj, i) => (
																							<li
																								onClick={() =>
																									setFreeArticleData234567(
																										freeArticleDataObj,
																										'locationSearch1234567'
																									)}
																							>
																								{
																									freeArticleDataObj.memberDetailsWithMrNo
																								}
																							</li>
																						)
																					)}
																				</ul>
																			</div>
																		</div>
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Email Id
																			</label>
																			<div className="appointmentFormList">
																				<input
																					type="text"
																					value={emailId}
																					id="emailId"
																					name="emailId"
																					className="formInput"
																					placeholder="Email Id"
																					onChange={(e) =>
																						setEmailId(e.target.value)}
																				/>
																			</div>
																		</div>
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Gender<sup>*</sup>
																			</label>
																			<div className="appointmentFormList">
																				<span className="radioSection1">
																					<input
																						name="patientLastName"
																						type="radio"
																						onChange={(e) =>
																							setPatientGender(
																								e.target.value
																							)}
																						value="Male"
																						id="radio1"
																					/>
																					<label for="radio1" />Male
																				</span>
																				<span className="radioSection1">
																					<input
																						name="patientLastName"
																						onChange={(e) =>
																							setPatientGender(
																								e.target.value
																							)}
																						type="radio"
																						value="Female"
																						id="radio2"
																					/>
																					<label for="radio2" />Female
																				</span>
																			</div>
																		</div>

																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Doctor Name {window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR' ? <sup>*</sup> : ""}
																			</label>
																			<div className="appointmentFormList">
																				{window.sessionStorage.getItem(
																					'role'
																				) === 'ROLE_DOCTOR' ? (
																					<input
																						type="text"
																						value={getSelectDoctor}
																						id="freeArticleData123"
																						name="cityName"
																						placeholder="Doctor Name"
																						className="input-design formInput"
																						autoComplete="off"
																						readOnly
																					/>
																				) : (
																					''
																				)}
																				{window.sessionStorage.getItem(
																					'role'
																				) === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																					<>
																						{popaler.length > 1 ?
																							<select
																								className="input-design formInput"
																								id="default"
																								style={{ width: '100%' }}
																								onChange={(e) => setFreeArticleData23(e.target.value)}
																							>
																								<option value="">Doctor Name</option>
																								{popaler.map((Popuplist, i) => (
																									<option value={Popuplist.doctorId}>
																										{Popuplist.doctorName}
																									</option>
																								))}
																							</select>
																							:
																							<select
																								id="default" className='input-design formInput'
																								style={{ width: '100%' }}
																								onChange={(e) => setFreeArticleData23(e.target.value)}
																							>
																								{popaler.map((Popuplist, i) => (
																									<option value={Popuplist.doctorId}>
																										{Popuplist.doctorName}
																									</option>
																								))}
																							</select>}
																					</>

																				) : (
																					''
																				)}
																			</div>
																		</div>
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Appointment Date and Time<sup>*</sup>
																			</label>
																			<div className="appointmentFormList customDatePicker">
																				<Datepicker
																					required
																					id="appoinmentDateTime"
																					className="Datepicker pa2 inputField appmntDateTime "
																					minDate={new Date()}
																					selected={newDate}
																					placeholderText="Date  Time"
																					dateFormat="dd/MM/yyyy h:mm aa"
																					//timeFormat="HH:mm"
																					calendarClassName="rasta-stripes"
																					//showTimeInput
																					// showTimeSelect
																					filterTime={filterPassedTime}
																					showTimeSelect
																					onChange={(date) => setDate(date)}
																				/>
																			</div>
																		</div>
																		<input
																			type="hidden"
																			id="hidDocId"
																			value={getHidDocId}
																		/>

																		<div class="newAppoinmentRightList">
																			<label className="formLabel">Status</label>
																			<div className="appointmentFormList">
																				<select
																					className="formInput"
																					value={Date.parse(moment(new Date).format('YYYY-MM-DD')) < Date.parse(moment(newDate).format('YYYY-MM-DD')) ? "2" : patientBloodGroup}
																					onChange={(e) =>
																						setPatientBloodGroup(
																							e.target.value
																						)}
																					id="patientBloodGroup"
																					name="patientBloodGroup"
																				>
																					<option value="">Select</option>
																					<option value="1">OPEN</option>
																					<option value="2">CONFIRM</option>
																					<option value="3">ARRIVED</option>
																					<option value="4">DOCTOR CHANGED</option>
																					<option value="5">ADMITTED</option>
																					{Date.parse(moment(newDate).format('YYYY-MM-DD')) > Date.parse(moment(new Date()).format('YYYY-MM-DD')) ? "" : <option value="6">COMPLETED</option>}
																					<option value="9">CANCELLED</option>

																				</select>
																			</div>
																		</div>
																		{prNo !== "" ? (
																			<div class="newAppoinmentRightList" id="prNo1">
																				<label className="formLabel">
																					PR Number
																				</label>
																				<div className="appointmentFormList" title={prNo}>
																					<input
																						// style={{ display: 'block' }}
																						disabled
																						type="text"
																						className="formInput"
																						value={prNo}
																						id="prNo"
																						maxlength="50"
																						name="prNo"
																						placeholder="PR Number"
																					/>
																				</div>
																			</div>
																		) : ("")}
																		<div class="newAppoinmentRightList">
																			<label className="formLabel">
																				Reason For Visit<sup>*</sup>
																			</label>
																			<div className="appointmentFormList">
																				<textarea
																					maxlength="50"
																					value={zip}
																					id="zip"
																					name="zip"
																					onChange={(e) =>
																						setZip(e.target.value)}
																					className="formInput"
																					placeholder="Reason For Visit"
																					style={{ height: 'auto' }}
																				>
																					Consultation
																				</textarea>
																			</div>
																		</div>

																		<div class="newAppoinmentButton">
																			{getSave === false ? (
																				<button type='button'
																					id="addPatientSaveId"
																					className="borderButton"
																					onClick={() => { isSavingClick == true && addPatientSave() }}
																				>
																					Save
																				</button>
																			) : (
																				<button type='button'
																					title="Update"
																					className="borderButton"
																					onClick={() => addPatientSave()}
																				>
																					Update
																				</button>
																			)}
																			<button type='button'
																				className="borderButton borderCancelButton"
																				onClick={() => cancel()}
																			>
																				Cancel
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{/* )} */}
				</div>


				{/*  */}
				{/*  ========================== NEW POPUP BEGINS HERE ===================== */}
				<div>

					<div className="ploverlayy" id="savePopUpIdNew" style={{ display: 'none' }} onClick={(e) => clearData()}>
						<div className="plpopup plpopupList plAppointmenPopup " id="formId">

							<div class="addDataHead">
								{getSave === false ? (
									<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
										Patient Appointment
									</span>
								) : (
									<span id="spanAddSupplier1" style={{ color: '#3498db', display: 'block' }}>
										Edit Appointment
									</span>
								)}
								<a id="btnCancel" title="Close" class="close" onClick={closePopUpEdit}>
									<span class="popUpClose"> &times;</span>
								</a>
							</div>
							<div className="popBack" id="popup1">
								<div className="popOverlay" />
								<div className="row">
									<div className="customizationPop">
										<div className="customizationPopContentSec">
											<input type="hidden" id="txtHiddenCategoryId" />
											<div
												className="contentWraper contentWraperScroll whiteBg"
												style={{ minHeight: 'auto' }}
											>
												<div className="container">
													<div className="innerContent plAppContent">

														<div className="addpatientContent">
															<div className="row">
																<div className="col-md-2">
																	<div
																		className="profileImg"
																		style={{ width: 'auto' }}
																	>
																		<div className="webCamSection">
																			<div
																				className="webcamOpen1"
																				id="results1" style={{ display: (base64 || imagePath || image) ? 'block' : 'none' }}
																			>
																				<img
																					src={
																						base64 || imagePath || image
																					}
																					alt=''
																					id="imageThumb2"
																					className="webOpenImage1"
																					onClick={changeImage}
																				/>
																				<span
																					className="changeImage"
																					onClick={changeImage}
																				>
																					{' '}
																				</span>
																			</div>
																			<div
																				className="webcamOpen"
																				id="results"
																			>
																				<img
																					src={imagePreview}
																					alt=''
																					id="imageThumb"
																					className="webOpenImage"
																					title="Upload Images"
																					onClick={changeImage}
																				/>
																				<span
																					className="changeImage"
																					style={{
																						display: display
																							? ''
																							: 'block',
																					}}
																					onClick={changeImage}
																				>
																					{' '}
																				</span>
																			</div>
																			<div
																				className="webcamDiv"
																				style={{
																					display: display ? '' : 'none',
																				}}
																			>
																				<div
																					className="myCamera"
																					id="my_camera"
																				/>
																				<div
																					className="webCamButton"
																					id="imageDivId"
																				>
																					{state.isData === 1 ? (
																						<WebcamCapture
																							onCapture={
																								onCaptureHandler
																							}
																							webCamImage={
																								webCamImageFunction
																							}
																							web={abcA}
																						/>
																					) : (
																						''
																					)}
																					<span
																						className="borderButton borderButtonSmall"
																						onClick={add}
																						style={{ display: 'none' }}
																						id="takeSnapshotId1"
																					>
																						Take Snapshot
																					</span>
																					<span
																						style={{ display: 'none' }}
																						id="uploadId1"
																						className="uploadButton uploadButtonSmall"
																					>
																						<input
																							type="file"
																							id="file1"
																							onChange={(e) =>
																								handleImageUploadOnChange(
																									e
																								)}
																						/>
																						<label for="file1">
																							Upload
																						</label>
																					</span>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="col-md-10">
																	<div className="appointmentForm">
																		{window.sessionStorage.getItem('role') === 'ROLE_DOCTOR' ? (
																			<div
																				class="newAppoinmentRightList"
																				style={{
																					display:
																						getHospitalListByDoctorApp.length ===
																							1
																							? 'none'
																							: 'inline-block', width: '100%'
																				}}
																			>
																				<label className="formLabel">
																					<b>Hospital Name </b>
																				</label>
																				<div className="appointmentFormList">
																					<select
																						disabled
																						name="hospitalNameIdApp"
																						className="formInput"
																						id="hospitalNameIdApp"

																						value={
																							getHospitalNameChangeApp
																						}
																						onChange={(e) =>
																							hospitalNameChangeApp1(
																								e.target.value
																							)} style={{ width: "33.33%" }}
																					>

																						{getHospitalListByDoctorApp.map(
																							(data, i) => (
																								<option
																									value={
																										data.hospitalId
																									}
																								>
																									{
																										data.hospitalName
																									}
																								</option>
																							)
																						)}
																					</select>
																				</div>
																			</div>
																		) : (
																			''
																		)}
																		<div className='EditAppontmntWraper'>
																			<div className='MRPRNUM'>

																				{window.sessionStorage.getItem('isPrNumEnabled') === "false" ?
																					(<div class="newAppoinmentRightList">
																						<label className="formLabel" style={{ color: "rgb(52, 152, 219)" }}>
																							<b>MR Number </b>
																						</label>
																						<div className="appointmentFormList" title={mrNo}>
																							<label
																								style={{ display: 'block' }}

																								id="mrNo"
																								maxlength="50"
																								name="mrNo"

																							>{mrNo} </label>


																						</div>
																					</div>) :
																					(prNo !== '' ?
																						<div class="newAppoinmentRightList" id="prNo1">
																							<label className="formLabel" style={{ color: "rgb(52, 152, 219)" }}>
																								<b>PR Number</b>
																							</label>
																							<div className="appointmentFormList" title={prNo}>
																								<label

																									id="prNo"
																									maxlength="50"
																									name="prNo"
																									placeholder="PR Number"

																								>{prNo}</label>

																							</div>
																						</div> : '')}
																				{window.sessionStorage.getItem('role') ===
																					'ROLE_DOCTOR' ? (
																					<div
																						class="newAppoinmentRightList plEmptyLabel "
																						style={{
																							display:
																								getHospitalListByDoctorApp.length === 1
																									? 'inline-block'
																									: 'none',
																						}}
																					>
																						<label className="formLabel">
																							&nbsp;
																						</label>
																					</div>
																				) : (
																					''
																				)}
																				{window.sessionStorage.getItem('role') ===
																					'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																					<div class="newAppoinmentRightList plEmptyLabel ">
																						<label className="formLabel">
																							&nbsp;
																						</label>
																					</div>
																				) : (
																					''
																				)}
																			</div>

																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>First Name</b> {' '}

																				</label>
																				<div className="appointmentFormList">
																					<label

																						id="patientFirstName"
																						name="patientFirstName"
																						placeholder="First Name"
																						onChange={(e) =>
																							setPatientFirstName(
																								e.target.value.toUpperCase()
																							)}

																						autoComplete="off"
																						onKeyUp={() =>
																							cityAutocomplete23456(
																								'patientFirstName',
																								'locationSearch123456'
																							)}
																					>{patientFirstName} </label>
																					<ul
																						className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																						id="locationSearch123456"
																						style={{ display: 'none' }}
																					>
																						{freeArticleData123456.map(
																							(freeArticleDataObj, i) => (
																								<li
																									onClick={() =>
																										setFreeArticleData23456(
																											freeArticleDataObj,
																											'locationSearch123456'
																										)}
																								>
																									{
																										freeArticleDataObj.memberDetailsWithMrNo
																									}
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Last Name</b>
																				</label>
																				<div className="appointmentFormList">
																					<label

																						id="patientLastName"
																						name="patientLastName"
																						placeholder="Last Name"
																						onChange={(e) =>
																							setPatientLastName(
																								e.target.value.toUpperCase()
																							)}

																						autoComplete="off"
																						onKeyUp={() =>
																							cityAutocomplete234567(
																								'patientLastName',
																								'locationSearch1234567'
																							)}
																					> {patientLastName}</label>
																					<ul
																						className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																						id="locationSearch1234567"
																						style={{ display: 'none' }}
																					>
																						{freeArticleData1234567.map(
																							(freeArticleDataObj, i) => (
																								<li
																									onClick={() =>
																										setFreeArticleData234567(
																											freeArticleDataObj,
																											'locationSearch1234567'
																										)}
																								>
																									{
																										freeArticleDataObj.memberDetailsWithMrNo
																									}
																								</li>
																							)
																						)}
																					</ul>
																				</div>
																			</div>

																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Gender</b>
																				</label>
																				<div className="appointmentFormList1">
																					<span className="radioSection1">

																						<label for="radio11">{patientGender}</label>
																					</span>

																				</div>
																			</div>

																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Mobile Number</b>
																				</label>
																				<div className="appointmentFormList">

																					<span class="mobileNumberField">
																						<label

																							autoComplete="off"

																							onKeyPress={(event) => {
																								if (
																									!/[0-9]/.test(
																										event.key
																									)
																								) {
																									event.preventDefault();
																								}
																							}}
																							id="mobileNumber"
																							name="mobileNumber"
																							placeholder="Mobile Number"
																							maxlength="10"

																							onChange={handleChange}
																							onKeyUp={() =>
																								cityAutocomplete2345(
																									'mobileNumber',
																									'locationSearch12345'
																								)} autofocus="autofocus"
																						>{areaCode}-{mobileNumber}</label>
																						<ul
																							className="autoSearch1 autoSearch-p0 appointmentNumberSearch"
																							id="locationSearch12345"
																							style={{ display: 'none' }}
																						>
																							{freeArticleData12345.map(
																								(
																									freeArticleDataObj,
																									i
																								) => (
																									<li
																										onClick={() =>
																											setFreeArticleData2345(
																												freeArticleDataObj,
																												'locationSearch12345'
																											)}
																									>
																										{
																											freeArticleDataObj.memberDetailsWithMrNo
																										}
																									</li>
																								)
																							)}
																						</ul>
																					</span>
																				</div>
																			</div>
																			<div class="newAppoinmentRightList" style={{ margin: '0px' }}>
																				<label className="formLabel">
																					<b>Email Id</b>
																				</label>
																				<div className="appointmentFormList">
																					<label

																						id="emailId"
																						name="emailId"

																						placeholder="Email Id"
																						onChange={(e) =>
																							setEmailId(e.target.value)}
																					>{emailId}</label>
																				</div>
																			</div>
																		</div>
																		<div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Doctor Name{window.sessionStorage.getItem('role') !== 'ROLE_DOCTOR' ? <sup>*</sup> : ""}</b>
																				</label>
																				<div className="appointmentFormList">
																					{window.sessionStorage.getItem(
																						'role'
																					) === 'ROLE_DOCTOR' ? (
																						<input
																							type="text"
																							value={getSelectDoctor}
																							id="freeArticleData123"
																							name="cityName"
																							placeholder="Doctor Name"
																							className="inputField input-design formInput"
																							autoComplete="off"
																							readOnly
																						/>
																					) : (
																						''
																					)}
																					{window.sessionStorage.getItem(
																						'role'
																					) === 'ROLE_HOSPITALADMIN' || window.sessionStorage.getItem("role") === "ROLE_NURSE" || window.sessionStorage.getItem("role") === "ROLE_RECEPTION" ? (
																						<>
																							{popaler.length > 1 ?
																								<select
																									style={{ background: "#f2f2f2" }}
																									className="inputField input-design formInput"
																									id="selectDropdownDoctor"
																									// onKeyDown={enterKey}
																									tabIndex="6"
																									name="selectDropdownDoctor"
																									value={getSelectDoctor}
																									onChange={(e) => setFreeArticleData23(e.target.value)}
																								// onKeyUp={() =>
																								// 	 		cityAutocomplete23(
																								// 	 			'freeArticleData123',
																								// 	 			'locationSearch123'
																								// 	 		)}
																								>
																									<option value="">Doctor Name</option>
																									{popaler.map((data, k) => (
																										<option value={data.doctorId}>
																											{data.doctorName}

																										</option>
																									))}
																								</select>
																								:
																								<select
																									style={{ background: "#f2f2f2" }}
																									className="inputField"
																									id="selectDropdownDoctor"
																									// onKeyDown={enterKey}
																									tabIndex="6"
																									name="selectDropdownDoctor"
																									value={getSelectDoctor}
																									onChange={(e) => setFreeArticleData23(e.target.value)}
																								// onKeyUp={() =>
																								// 	 		cityAutocomplete23(
																								// 	 			'freeArticleData123',
																								// 	 			'locationSearch123'
																								// 	 		)}
																								>
																									{popaler.map((data, k) => (
																										<option value={data.doctorId}>
																											{data.doctorName}

																										</option>
																									))}
																								</select>
																							}
																						</>
																					) : (
																						''
																					)}
																				</div>
																			</div>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Appointment Date and Time </b>
																				</label>
																				<div className="appointmentFormList customDatePicker">
																					<Datepicker
																						required
																						id="appoinmentDateTime"
																						className="Datepicker pa2 inputField appmntDateTime"
																						minDate={new Date()}
																						selected={newDate}
																						filterTime={filterPassedTime}

																						placeholderText="Date  Time"
																						dateFormat="dd/MM/yyyy h:mm aa"
																						timeFormat="h:mm aa"
																						calendarClassName="rasta-stripes"
																						dropdownMode="scroll"
																						autoComplete="off"
																						maxDate='+5D'

																						showTimeSelect
																						onChange={(date) =>
																							setDate(date)}
																					/>
																				</div>
																			</div>
																			<input
																				type="hidden"
																				id="hidDocId"
																				value={getHidDocId}
																			/>
																			<div class="newAppoinmentRightList">
																				<label className="formLabel">
																					<b>Status</b>
																				</label>
																				<div className="appointmentFormList">
																					<select
																						className="formInput"
																						value={Date.parse(moment(new Date).format('YYYY-MM-DD')) < Date.parse(moment(newDate).format('YYYY-MM-DD')) ? "2" : patientBloodGroup}
																						onChange={(e) =>
																							setPatientBloodGroup(
																								e.target.value
																							)}
																						id="patientBloodGroup"
																						name="patientBloodGclosePopUpEditroup"
																					>

																						<option value="1">OPEN</option>
																						<option value="2">CONFIRM</option>
																						<option value="3">ARRIVED</option>
																						<option value="4">DOCTOR CHANGED</option>
																						<option value="5">ADMITTED</option>
																						{Date.parse(moment(newDate).format('YYYY-MM-DD')) > Date.parse(moment(new Date()).format('YYYY-MM-DD')) ? "" : <option value="6">COMPLETED</option>}
																						<option value="7">DISCHARGE</option>
																						<option value="8">ENGAGED</option>
																						<option value="9">CANCELLED</option>
																						<option value="10">CALL COMPLETED-DOCTOR</option>

																					</select>
																				</div>
																			</div>

																			<div class="newAppoinmentRightList" style={{ width: '100%' }}>
																				<label className="formLabel">
																					<b>Reason For Visit </b>
																				</label>
																				<div className="appointmentFormList">

																					<textarea
																						maxlength="50"
																						value={zip}
																						id="zip"
																						name="zip"
																						onChange={(e) =>
																							setZip(e.target.value)}

																						className="formInput"
																						placeholder="Reason For Visit"
																						style={{ height: 'auto' }}
																					>
																						Consultation
																					</textarea>
																				</div>
																			</div>
																		</div>
																		<div class="newAppoinmentButton" style={{ padding: "15px 10px" }}>
																			{getSave === false ? (
																				<button type='button'
																					id="saveId"
																					title="Save"
																					className="borderButton"
																					onClick={() => addPatientSave()}
																				>
																					Save
																				</button>
																			) : (
																				<button type='button'
																					title="Update"
																					className="borderButton"
																					onClick={() => addPatientSave()}
																				>
																					Update
																				</button>
																			)}
																			<button type='button'
																				title="Cancel"
																				className="borderButton borderCancelButton"
																				onClick={closePopUpEdit}
																			>
																				Cancel
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/*  ========================== NEW POPUP ends HERE ===================== */}
			</div>
		</Suspense>
	);
}
export default PatientListing;

import React, { useState, useEffect } from 'react';
import Add_img from '../images/icons/Add_img.png';
import Close from '../images/icons/close_img.png';
import { callingAPI, callingGetAPI } from '../config';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../Common/loader';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function AddHospitalExpenses() {
	const [isData, setIsData] = useState(true);
	const [categoryValues, setCategoryValues] = useState([]);
	const [hospitalExpenseDetails, setHospitalExpenseDetails] = useState([]);
	const [hospitalExpenseId, sethospitalExpenseId] = useState('');
	const [hospitalExpenseCategoryId, sethospitalExpenseCategoryId] = useState('0');
	const [hospitalExpenseCreatedBy, sethospitalExpenseCreatedBy] = useState('1');
	const [hospitalExpenseitemName, sethospitalExpenseitemName] = useState('');
	const [hospitalExpensecreatedOn, sethospitalExpensecreatedOn] = useState('');
	const [hospitalExpenseunitPrice, sethospitalExpenseunitPrice] = useState('');
	const [hospitalExpenseqty, sethospitalExpenseqty] = useState('');
	const [hospitalExpensetotalCost, sethospitalExpenseTotalCost] = useState('');
	const [hospitalExpensedescription, sethospitalExpensedescription] = useState('');
	const navigate = useNavigate();

	var date = new Date();
	var requiredDate = moment(date).format('YYYY-MM-DD');

	const categoryChange = (event) => {
		sethospitalExpenseCategoryId(event.target.value);
	};
	const itemNameChange = (event) => {
		sethospitalExpenseitemName(event.target.value);
	};
	const dateChange = (event) => {
		sethospitalExpensecreatedOn(event.target.value);
	};
	const priceChange = (event) => {
		const re = /^[0-9.\b]+$/;

		if (event.target.value === '' || re.test(event.target.value)) {
			sethospitalExpenseunitPrice(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const quantityChange = (event) => {
		const re = /^[0-9\b]+$/;
		if (event.target.value === '' || re.test(event.target.value)) {
			sethospitalExpenseqty(event.target.value);
		} else {
			toast.error('Only Numbers Accepted');
		}
	};
	const totalCostChange = (event) => {
		sethospitalExpenseTotalCost(event.target.value);
	};
	const descriptionChange = (event) => {
		sethospitalExpensedescription(event.target.value);
	};

	useEffect(() => {
		callingGetAPI('hospitalInventory/getExpenseCategory')
			.then((data) => {
				var result = [];
				setCategoryValues(data.data.result.data);
			})
			.catch();
	}, []);

	const addRow = (e, index) => {
		let flags = true;
		if (hospitalExpenseCategoryId === '0') {
			toast.error('Select the Account Head');
			document.getElementById('accountHead').focus();
			flags = false;
		} else if (!hospitalExpenseitemName) {
			toast.error('Enter Item Name ');
			document.getElementById('itemName').focus();
			flags = false;
		} else if (!hospitalExpensecreatedOn) {
			toast.error('Select Date Incurred ');
			const inputDate = document.getElementById('hospitalExpensecreatedOn');
			inputDate.addEventListener("focus", function (evt) {
				if (this.getAttribute("type") === "date") {
					this.showPicker();
				}
			});
			document.getElementById('hospitalExpensecreatedOn').focus();
			flags = false;
		} else if (!hospitalExpenseunitPrice) {
			toast.error('Enter Unit Price');
			document.getElementById('price').focus();
			flags = false;
		} else if (!hospitalExpenseqty) {
			toast.error('Enter Quantity');
			document.getElementById('quantity').focus();
			flags = false;
		}

		if (flags === true) {
			var copyHospitalExpenseDetails = [...hospitalExpenseDetails];
			var newHospitalExpenseDetails = {
				hospitalExpenseId: '',
				hospitalExpenseCategoryId: hospitalExpenseCategoryId,
				hospitalExpenseCreatedBy: hospitalExpenseCreatedBy,
				hospitalExpenseitemName: hospitalExpenseitemName,
				hospitalExpensecreatedOn: hospitalExpensecreatedOn,
				hospitalExpenseunitPrice: hospitalExpenseunitPrice,
				hospitalExpenseqty: hospitalExpenseqty,
				hospitalExpensetotalCost: hospitalExpensetotalCost,
				hospitalExpensedescription: hospitalExpensedescription,
			};
			setHospitalExpenseDetails([...copyHospitalExpenseDetails, newHospitalExpenseDetails]);
			sethospitalExpenseCategoryId('0');
			sethospitalExpenseCreatedBy('1');
			sethospitalExpenseitemName('');
			sethospitalExpensecreatedOn('');
			sethospitalExpenseunitPrice('');
			sethospitalExpenseqty('');
			sethospitalExpenseTotalCost('');
			sethospitalExpensedescription('');
		}
	};

	const removeRow = (e, i) => {
		let copyAppendValues = [...hospitalExpenseDetails];
		copyAppendValues.splice(i, 1);
		setHospitalExpenseDetails(copyAppendValues);
	};

	const categoryTypeChange = (e, i) => {
		const { name, value } = e.target;
		const values = [...hospitalExpenseDetails];
		values[i] = { ...values[i], [name]: value };
		setHospitalExpenseDetails(values);
	};

	const multiplyBy = () => {
		var price = hospitalExpenseunitPrice;
		var quantity = hospitalExpenseqty;
		var totalCost = price * quantity;
		document.getElementById('totalCost').value = totalCost;
		sethospitalExpenseTotalCost(totalCost);
	};

	const addHospitalExpenses = () => {
		setIsData(false)
		let flaghospital = true;
		let copyAppendValues = [...hospitalExpenseDetails];
		if (copyAppendValues.length === 0) {
			if (hospitalExpenseCategoryId === '0') {
				toast.error('Select the Account Head');
				document.getElementById('accountHead').focus();
				flaghospital = false;
				setIsData(true)
			} else if (!hospitalExpenseitemName) {
				toast.error('Enter Item Name ');
				document.getElementById('itemName').focus();
				flaghospital = false;
				setIsData(true)
			} else if (!hospitalExpensecreatedOn) {
				toast.error('Select Date Incurred ');
				const inputDate = document.getElementById('hospitalExpensecreatedOn');
				inputDate.addEventListener("focus", function (evt) {
					if (this.getAttribute("type") === "date") {
						this.showPicker();
					}
				});
				document.getElementById('hospitalExpensecreatedOn').focus();
				flaghospital = false;
				setIsData(true)
			} else if (!hospitalExpenseunitPrice) {
				toast.error('Enter Unit Price');
				document.getElementById('price').focus();
				flaghospital = false;
				setIsData(true)
			} else if (!hospitalExpenseqty) {
				toast.error('Enter Quantity');
				document.getElementById('quantity').focus();
				flaghospital = false;
				setIsData(true)
			}
			else if (hospitalExpenseCategoryId !== '0' && hospitalExpenseitemName != "" && hospitalExpensecreatedOn !== "" && hospitalExpenseunitPrice !== "" && hospitalExpenseqty !== "") {
				var a = {
					hospitalExpenseId: '',
					hospitalExpenseCategoryId: hospitalExpenseCategoryId,
					hospitalExpenseCreatedBy: hospitalExpenseCreatedBy,
					hospitalExpenseitemName: hospitalExpenseitemName,
					hospitalExpensecreatedOn: hospitalExpensecreatedOn,
					hospitalExpenseunitPrice: hospitalExpenseunitPrice,
					hospitalExpenseqty: hospitalExpenseqty,
					hospitalExpensetotalCost: hospitalExpensetotalCost,
					hospitalExpensedescription: hospitalExpensedescription,
				};
				copyAppendValues.push(a)
				flaghospital = true;
			}
		}
		else {
			if (hospitalExpenseCategoryId !== '0' && hospitalExpenseitemName != "" && hospitalExpensecreatedOn !== "" && hospitalExpenseunitPrice !== "" && hospitalExpenseqty !== "") {
				var a = {
					hospitalExpenseId: '',
					hospitalExpenseCategoryId: hospitalExpenseCategoryId,
					hospitalExpenseCreatedBy: hospitalExpenseCreatedBy,
					hospitalExpenseitemName: hospitalExpenseitemName,
					hospitalExpensecreatedOn: hospitalExpensecreatedOn,
					hospitalExpenseunitPrice: hospitalExpenseunitPrice,
					hospitalExpenseqty: hospitalExpenseqty,
					hospitalExpensetotalCost: hospitalExpensetotalCost,
					hospitalExpensedescription: hospitalExpensedescription,
				};
				copyAppendValues.push(a)
				flaghospital = true;
			}
		}
		if (flaghospital === true) {
			var categoryValuesArr = [];
			var intTotalExpenseCost = '';
			categoryValuesArr = hospitalExpenseDetails.map((val, ind) => {
				intTotalExpenseCost = val.hospitalExpenseunitPrice * val.hospitalExpenseqty;
				val.hospitalExpensetotalCost = '' + intTotalExpenseCost;
				return val;
			});
			const postdata = {
				hospitalId: localStorage.getItem("hospitalId"),
				hospitalExpenseId: '',
				expenses: copyAppendValues,
			};

			callingAPI('hospitalInventory/addHospitalExpense', postdata)
				.then((response) => {
					setIsData(true)
					toast.success('Hospital Expense Added Successfully');
					setTimeout(() => {
						// window.location.href = '/settings/hospitalExpenses';
						navigate('/settings/hospitalExpenses');
					}, 3000);
				})
				.catch();
		}
	};
	return (
		<div class="practiceManagementForm practiceManagementFormNew pl_add_expenses">
			<ToastContainer position="bottom-right" style={{ zIndex: "999999999" }} />
			{isData === false ?
				<Loader />
				: ""}
			<h3 class="practiceHead">Add Hospital Expenses</h3>
			<div class="practiceManagementFormBlcok">
				<div class="taxMainContentWrap">
					<div class="taxMainWrap">
						<ul className="row">
							<li class="col-md-3 col-lg-3" style={{ paddingLeft: '0px' }}>
								<label>Select Account Head *</label>

								<select
									name="hospitalExpenseCategoryId"
									id="accountHead"
									class="fulWidthSelect ui-autocomplete-input"
									autocomplete="off"
									value={hospitalExpenseCategoryId}
									onChange={categoryChange} tabIndex="1" autoFocus tabindex="-1"
								>
									<option value="0">Select Expense Category</option>
									{categoryValues.map((item, idix) => (
										<option value={item.hospitalExpenseCategoryId}>
											{item.hospitalExpenseCategoryName}
										</option>
									))}
								</select>
							</li>
							<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
								<label>Item Name *</label>

								<input
									autocomplete="off"
									type="text"
									name="hospitalExpenseitemName"
									placeholder="Enter Name"
									id="itemName"
									value={hospitalExpenseitemName}
									onChange={itemNameChange} tabindex="-1"
								/>
							</li>
							<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
								<label>Date Incurred *</label>
								<input
									autocomplete="off"
									type="date"
									// data-date=""
									max={requiredDate}
									name="hospitalExpensecreatedOn"
									id="hospitalExpensecreatedOn"
									// autofocus="autofocus"
									value={hospitalExpensecreatedOn}
									onChange={dateChange}
									onKeyDown={(e) => e.preventDefault()} tabindex="-1"

								/>

							</li>
							<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
								<label>Price *</label>

								<input
									autocomplete="off"
									type="text"

									placeholder="Price"
									maxlength="100"
									id="price"
									name="hospitalExpenseunitPrice"
									value={hospitalExpenseunitPrice}
									onChange={priceChange}
									onKeyUp={multiplyBy} tabindex="-1"
								/>
							</li>
							<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
								<label>Quantity *</label>

								<input
									autocomplete="off"
									type="text"

									placeholder="Qty"
									maxlength="100"
									id="quantity"
									name="hospitalExpenseqty"
									value={hospitalExpenseqty}
									onChange={quantityChange}
									onKeyUp={multiplyBy} tabindex="-1"
								/>
							</li>
							<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
								<label>Total Cost</label>

								<input
									autocomplete="off"
									type="text"
									// tabindex="1"
									placeholder="Cost"
									maxlength="100"
									id="totalCost"
									name="hospitalExpensetotalCost"
									value={hospitalExpensetotalCost}
									onChange={totalCostChange} tabindex="-1"
									disabled
								/>
							</li>
							<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
								<label>Description</label>

								<input
									autocomplete="off"
									type="text"

									style={{ width: '72%' }}
									placeholder="Description"
									maxlength="100"
									id="description"
									name="hospitalExpensedescription"
									value={hospitalExpensedescription}
									onChange={descriptionChange} tabindex="-1"
								/>
								<img
									title="Add More"
									style={{ cursor: 'pointer' }}
									src={Add_img}
									onClick={(e) => addRow(e)} tabindex="-1"
								/>
							</li>
						</ul>
					</div>
				</div>
			</div>
			{hospitalExpenseDetails.map((item, idx) => (
				<div class="practiceManagementFormBlcok">
					<div class="taxMainContentWrap">
						<div class="taxMainWrap">
							<ul className="row">
								<li class="col-md-3 col-lg-3" style={{ paddingLeft: '0px' }}>
									<label>Select Account Head *</label>

									<select
										name="hospitalExpenseCategoryId"
										id="accountHead"
										class="fulWidthSelect ui-autocomplete-input"
										autocomplete="off"
										value={item.hospitalExpenseCategoryId}
										onChange={(e) => categoryTypeChange(e, idx)} tabindex="-1"
									>
										<option value="0">Select Expense Category</option>
										{categoryValues.map((item, idix) => (
											<option value={item.hospitalExpenseCategoryId}>
												{item.hospitalExpenseCategoryName}
											</option>
										))}
									</select>
								</li>
								<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
									<label>Item Name *</label>
									<input
										autocomplete="off"
										type="text"
										name="hospitalExpenseitemName"
										placeholder="Enter Name"
										id="itemName"
										value={item.hospitalExpenseitemName}
										onChange={(e) => categoryTypeChange(e, idx)} tabindex="-1"
									/>
								</li>
								<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
									<label>Date Incurred *</label>
									<input
										autocomplete="off"
										type="date"
										name="hospitalExpensecreatedOn"
										id="hospitalExpensecreatedOn"
										autofocus="autofocus"
										max={requiredDate}
										value={item.hospitalExpensecreatedOn}
										onChange={(e) => categoryTypeChange(e, idx)} tabindex="-1"
									/>
								</li>
								<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
									<label>Price *</label>

									<input
										autocomplete="off"
										type="text"
										// tabindex="1"
										placeholder="Price"
										maxlength="100"
										value={item.hospitalExpenseunitPrice}
										id="price"
										name="hospitalExpenseunitPrice"
										onChange={(e) => {
											categoryTypeChange(e, idx);
										}}
										onKeyUp={multiplyBy} tabindex="-1"
									/>
								</li>
								<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
									<label>Quantity *</label>

									<input
										autocomplete="off"
										type="text"
										// tabindex="1"
										placeholder="Qty"
										maxlength="100"
										value={item.hospitalExpenseqty}
										id="quantity"
										name="hospitalExpenseqty"
										onChange={(e) => categoryTypeChange(e, idx)}
										onKeyUp={multiplyBy} tabindex="-1"
									/>
								</li>
								<li class="col-md-1 col-lg-1" style={{ paddingLeft: '0px' }}>
									<label>Total Cost</label>
									{/* <p>{item.hospitalExpensetotalCost}</p> */}
									<input
										autocomplete="off"
										type="text"
										tabindex="-1"
										placeholder="Cost"
										maxlength="100"
										id="totalCost"
										name="hospitalExpensetotalCost"
										value={item.hospitalExpensetotalCost}
									/>
								</li>
								<li class="col-md-2 col-lg-2" style={{ paddingLeft: '0px' }}>
									<label>Description</label>

									<input
										autocomplete="off"
										type="text"

										style={{ width: '72%' }}
										placeholder="Description"
										maxlength="100"
										id="description"
										name="hospitalExpensedescription"
										value={item.hospitalExpensedescription}
										onChange={(e) => categoryTypeChange(e, idx)} tabindex="-1"
									/>

									<img
										title="Add More"
										style={{ cursor: 'pointer' }}
										src={Close}
										onClick={(e) => removeRow(e, idx)} tabindex="-1"
									/>
								</li>
							</ul>
						</div>
					</div>
				</div>
			))}

			<div class="clearfix" />
			<div
				class="selctFieldHolder"
				style={{ marginBottom: '20px', clear: 'both', overflow: 'hidden', width: '100%' }}
			>
				<div className="  dashboardSearchList" style={{ float: 'right' }}>
					<button onClick={addHospitalExpenses} class="practiceGreenButton" tabindex="-1">
						Save
					</button>
				</div>

				<div className="  dashboardSearchList" style={{ float: 'right' }}>
					<span className="dashButtonBorder" style={{ marginRight: '10px', border: '1px solid #b22222' }}>
						<Link to="/settings/hospitalExpenses" style={{ color: '#393939', textDecoration: 'none' }} tabindex="-1">
							Cancel
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
}

export default AddHospitalExpenses;

import PharmacyHeader from "./layout/header";
import Footer from "./layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "./pharmacyLeft";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import React, { useState, useEffect } from "react";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { callingAPI } from "../config";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function PrescribedMedicinebyQuantity() {
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [memberPlanData, setMemberPlanData] = useState([]);
  const [memberPlanCountData, setMemberPlanCountData] = useState();
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [dataFlag, setDataFlag] = useState(false);
  const [functionName, setFunctionName] = useState(
    "Prescribed Medicine by Quantity"
  );
  const [drugListingArr, setDrugListingArr] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [doctorList, setDoctorList] = useState([]);
  const [isData, setIsData] = useState(0);
  const [idSearch, setIdSearch] = useState("");
  const [search, setSearch] = useState("");
  const [doctorName, setDoctorName] = useState("");
  const [doctorData, setDoctordata] = useState([]);
  const doctorDropDown = () => {
    let PostData = {
      hospitalId: localStorage.getItem("hospitalId"),
      selectCombo: "",
      inputSearch: "",
      max: 100,
      offset: 0,
    };
    callingAPI("commonUserFunctions/doctorsList", PostData).then((response) => {
      if (document.getElementById("searchTextDr").value.length > 1) {
        if (response.data.success === "1") {
          setDoctordata(response.data.result.doctorsListArray);
          document.getElementById("prescriptionMedicineList").style.display =
            "block";
          document.addEventListener("mouseup", function (e) {
            var container = document.getElementById("prescriptionMedicineList");
            if (container !== null) {
              if (!container.contains(e.target)) {
                container.style.display = "none";
              }
            }
          });
        } else {
          document.getElementById("prescriptionMedicineList").style.display =
            "none";
          setDoctordata([]);
        }
      } else {
        setDoctordata([]);
        document.getElementById("prescriptionMedicineList").style.display =
          "none";
      }
    });
  };
  const setdoctorDropDown = (name, id) => {
    setSearch(name);
    setIdSearch(id);
    setDoctordata([]);
    document.getElementById("prescriptionMedicineList").style.display = "none";
  };
  const changeText = (event) => {
    setSearch(event.target.value);
    doctorDropDown();
  };

  const exportFunction = () => {
    var toastShown = false;
    if (memberPlanData.length === 0) {
      if (!toastShown) {
        toast.error("No Data to Export");
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    else {
      const csvRow = [];
      var memberPlanData1 = [["Medicine", "Doctor", "Prescription%20Quantity"]];
      var arrayDetails = memberPlanData;
      const postData = {
        fromDate: fromDate ? formatDate(fromDate) : "",
        toDate: toDate ? formatDate(toDate) : "",
        doctorName: "",
        hospitalName: "",
        medicineName: searchValue,
        userId: window.sessionStorage.getItem("userId"),
        roleName: window.sessionStorage.getItem("role"),
        max: "",
        offset: 0,
      };
      callingAPI(
        "medicinePrescriptionReport/medicinePrescriptionReport",
        postData
      ).then((data) => {
        // if (data.data.success === '0') {
        //   alert("No Data to Export")
        // }
        if (data.data.success === '1') {
          for (var item = 0; item < data.data.result.memberPlanData.length; item++) {
            memberPlanData1.push([
              data.data.result.memberPlanData[item].medicineName.replace(/ /g, "%20"),
              data.data.result.memberPlanData[item].doctorname.replace(/[^a-zA-Z0-9/g]/g, " ").split(/\s{1,}/g).join(" %20"),
              // .replace(/,/g, "|").replace(/./g, "b").replace(/ /g, "111"),
              data.data.result.memberPlanData[item].Quantiy,
            ]);
          }
          for (var i = 0; i < memberPlanData1.length; ++i) {
            csvRow.push(memberPlanData1[i].join("|"));
          }
          var csvString = csvRow.join("%0A");
          var a = document.createElement("a");
          a.href = "data:attachment/csv, " + csvString;
          a.target = "_Blank";
          a.download = functionName + ".csv";
          document.body.appendChild(a);
          a.click();
        }

      });
    }
  };
  // function noSearchData(){
  // 	alert("No Data to Export");
  // 	}
  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    let PostData = {
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
      doctorName: document.getElementById("searchTextDr").value,
      hospitalName: "",
      medicineName: searchValue,
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      max: max,
      offset: offset,
    };
    callingAPI(
      "medicinePrescriptionReport/medicinePrescriptionReport",
      PostData
    ).then((data) => {
      if (data.data.success === "1") {
        setMemberPlanData(data.data.result.memberPlanData);
        setMemberPlanCountData(data.data.result.memberPlanCountData);
        setIsData(1);
      }
      else {
        setMemberPlanData([]);
        setMemberPlanCountData([]);
        setIsData(1);
      }
    });
  };
  function formatDate(date) {
    var date = new Date(date),
      month = "" + (date.getMonth() + 1),
      day = "" + date.getDate(),
      year = date.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const searchFunction = () => {
    var toastShown = false;
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        if (!toastShown) {
          toast.error("End date must be greater than start date");
          document.getElementById("todate").focus();
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    let PostData = {
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
      doctorName: document.getElementById("searchTextDr").value,
      hospitalName: "",
      medicineName: searchValue,
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      searchValue: document.querySelector("#searchText").value,
      max: "10",
      offset: 0,
    };
    callingAPI(
      "medicinePrescriptionReport/medicinePrescriptionReport",
      PostData
    )
      .then((data) => {
        if (data.data.success === "1") {
          setMemberPlanData(data.data.result.memberPlanData);
          setMemberPlanCountData(data.data.result.memberPlanCountData);
          setIsData(1);
        } else {
          setMemberPlanData([]);
          setMemberPlanCountData([]);
          setIsData(1);
        }
      })
      .catch();
  };
  const searchValueFunction = (event) => {
    setSearchValue(event.target.value);
    getPrecriptionMedicineAutoComplete();
  };
  const getPrecriptionMedicineAutoComplete = () => {
    const postdata = {
      functionName: "medicineList",
      doctorId: window.sessionStorage.getItem("doctorId"),
      hospitalId: localStorage.getItem("hospitalId"),
      key: document.getElementById("searchText").value,
      max: "1000",
      offset: "0",
    };
    callingAPI("medicinePrescription/medicineList", postdata).then((data) => {
      if (document.getElementById("searchText").value.length > 1) {
        if (
          data.data.success === "1" &&
          data.data.result[0].medicineData.length > 0
        ) {
          setDrugListingArr(data.data.result[0].medicineData);
          document.getElementById(
            "prescriptionMedicineListDropdown"
          ).style.display = "block";
          document.addEventListener("mouseup", function (e) {
            var container = document.getElementById(
              "prescriptionMedicineListDropdown"
            );
            if (container !== null) {
              if (!container.contains(e.target)) {
                container.style.display = "none";
              }
            }
          });
        } else {
          document.getElementById(
            "prescriptionMedicineListDropdown"
          ).style.display = "none";
          setDrugListingArr([]);
        }
      } else {
        document.getElementById(
          "prescriptionMedicineListDropdown"
        ).style.display = "none";
        setDrugListingArr([]);
      }
    });
  };
  // const onBlurItemCategory = () => {
  // 	// document.getElementById('prescriptionMedicineList').style.display = 'none';
  // 	document.getElementById('prescriptionMedicineListDropdown').style.display = 'none';
  // 	setDoctordata([]);
  // 	setDrugListingArr([]);

  // };
  const setMedicineName = (name) => {
    setSearchValue(name);
    // setDrugListingArr([]);
    document.getElementById("prescriptionMedicineListDropdown").style.display =
      "none";
  };
  // const doctorDropdown = () => {
  //   const apiJson = {
  //     hospitalId: localStorage.getItem("hospitalId"),
  //     selectCombo: "2",
  //     inputSearch: document.getElementById("searchTextDr").value,
  //     max: 100,
  //     offset: 0,
  //   };
  //   document.getElementById("doctorListDiv").style.display = "block";
  //   callingAPI("commonUserFunctions/doctorsList", apiJson).then((data) => {
  //     if (data.data.success === "1") {
  //       setDoctorList(data.data.result.doctorsListArray);
  //     }
  //   });
  // };

  useEffect(() => {
    const PostData = {
      fromDate: "",
      toDate: "",
      doctorName: "",
      hospitalName: "",
      medicineName: searchValue,
      userId: window.sessionStorage.getItem("userId"),
      roleName: window.sessionStorage.getItem("role"),
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: pageOffset,
    };

    callingAPI(
      "medicinePrescriptionReport/medicinePrescriptionReport",
      PostData
    ).then((data) => {
      if (data.data.success === "1") {
        setMemberPlanData(data.data.result.memberPlanData);

        setMemberPlanCountData(data.data.result.memberPlanCountData);
        setIsData(1);
      } else {
        setMemberPlanData([]);

        setMemberPlanCountData(0);
        setIsData(1);
      }
    });
  }, []);

  const FromDateClear = () => {
    setFromDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const ToDateClear = () => {
    setToDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <ToastContainer position="bottom-right" />
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle">Prescribed Medicine by Quantity</div>
        </div>
      </div>
      {memberPlanCountData > 10 && (
        <div className="paginationSection">
          <Pagination
            // hideNavigation
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={memberPlanCountData}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={handlePagination}
          />
        </div>
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch">
            {/* onClick={onBlurItemCategory} */}
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  value={search}
                  id="searchTextDr"
                  name="searchTextDr"
                  autoComplete="off"
                  class="ui-autocomplete-input inputField"
                  placeholder="Select Doctor"
                  onChange={changeText}
                  onKeyUp={() =>
                    doctorDropDown("", "itemName", "prescriptionMedicineList")
                  }
                />
                <ul
                  className="autoSearch1 autoSearch-p0 appointmentNumberSearch bgBlueAutosearch"
                  id="prescriptionMedicineList"
                  style={{ display: "none" }}
                >
                  {doctorData.map((item, i) => (
                    <li
                      onClick={() =>
                        setdoctorDropDown(item.doctorName, item.doctorId)
                      }
                    >
                      {item.doctorName}
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  className="inputField"
                  placeholder="Select Medicine"
                  id="searchText"
                  name="searchText"
                  value={searchValue}
                  // disabled={isEnabled}
                  onChange={(event) => searchValueFunction(event)}
                />
                <ul
                  className="autoSearch autoSearch-p0 appointmentNumberSearch"
                  id="prescriptionMedicineListDropdown"
                  style={{ display: "none" }}
                >
                  {drugListingArr.map((item, i) => (
                    <li onClick={() => setMedicineName(item.medicineName)}
                    >
                      {item.medicineName}
                    </li>
                  ))}
                </ul>
                <input type="hidden" id="hiddenPrescriptionMedicineId" />
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    autoComplete="off"
                    required
                    id="fromdate"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                    className="Datepicker pa2 inputField"
                    calendarClassName="rasta-stripes"
                    // maxDate={new Date()}
                    placeholderText="From Date"
                    selected={fromDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setFromDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    autoComplete="off"
                    id="todate"
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                    required
                    className="Datepicker pa2 inputField"
                    calendarClassName="rasta-stripes"
                    // maxDate={new Date()}
                    placeholderText="To Date"
                    selected={toDate}
                    dateFormat="dd-MM-yyyy"
                    onChange={(date) => setToDate(date)}
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span className="dashButtonBorder" onClick={searchFunction}>
                  Search
                </span>
              </div>
              {/* {!memberPlanData.length ? (
                <div className="col-md-2 dashboardSearchList">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    id="export"
                    className="dashButtonBorder dashButtonBorderGrey 1"
                    onClick={noSearchData}
                  >
                    Export
                  </button>
                </div>
              ) : (
                <div className="col-md-2 dashboardSearchList">
                  <button
                    type="button"
                    style={{ width: '100%' }}
                    onClick={exportFunction}
                    id="export"
                    className="dashButtonBorder dashButtonBorderGrey 2"
                  >
                    Export
                  </button>
                </div>
              )} */}
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder dashButtonBorderGrey"
                  style={{ border: "1px solid gainsboro" }}
                  onClick={exportFunction}
                >
                  Export
                </span>
              </div>
            </div>
            <div className="row dflexend ">
              <div className="col-md-3">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total prescribed medicine :{" "}
                  {memberPlanData.length == 0 ? 0 : memberPlanCountData}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboardtable">
            {isData == 0 ? (
              <Loader />
            ) : memberPlanData.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Medicine </th>
                    <th>Doctor </th>
                    <th>Prescription Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {memberPlanData.map((index) => (
                    <tr>
                      <td>{index.medicineName}</td>
                      <td>{index.doctorname}</td>

                      <td>{index.Quantiy}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        <div className="paginationSection">
          {memberPlanCountData > 10 && (
            <Pagination
              // hideNavigation
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={memberPlanCountData}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={handlePagination}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default PrescribedMedicinebyQuantity;

import React, { useEffect, useState } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { callingAPI } from "../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import NoData from "../Common/noDataFound";
import Loader from "../Common/loader";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ReceiptTaxDetails() {
  const [fromDate, setfromtDate] = useState();
  const [toDate, settoDate] = useState();
  const [TaxDetailsListData, setTaxDetailsListData] = useState([]);
  const [TaxDetailsListdataTax, setTaxDetailsListdataTax] = useState([]);
  const downloadName = "TaxDetailsList";
  const [count, setCount] = useState("");
  const [pageOffset, setPageOffset] = useState("0");
  const [activePage, setActivePage] = useState(1);
  const [searchValue, setsearchValue] = useState("");
  const [filterName, setFilterInput] = useState("");
  const [dataFlag, setDataFlag] = useState(false);
  const ReceiptTaxDetailsList = () => {
    let PostData =
    {
      // "userId": window.sessionStorage.getItem("userId"),
      "userId": "368078",
      "roleName": window.sessionStorage.getItem("role"),
      "cmbSelect": "1",
      "txtInput": searchValue,
      "fromDate": "",
      "toDate": "",
      "max": process.env.REACT_APP_PAGINATION_COUNT,
      "offset": pageOffset
    }

    // {
    //   userId: window.sessionStorage.getItem("userId"),
    //   role: window.sessionStorage.getItem("role"),
    //   max: process.env.REACT_APP_PAGINATION_COUNT,
    //   offset: pageOffset,
    //   cmbSelect: filterName,
    //   txtInput: searchValue,
    //   hospitalId: localStorage.getItem("hospitalId"),
    // };
    callingAPI("adminReports/receiptDetails", PostData).then((response) => {
      if (response.data.success === "1") {
        setTaxDetailsListData(response.data.result.receiptTax);
        setTaxDetailsListdataTax(response.data.result.dataTax);
        setCount(response.data.result.totalCount);
        setDataFlag(true);
      } else { setDataFlag(true); }
    });
  };
  useEffect(() => {
    ReceiptTaxDetailsList();
  }, []);

  //searchfunction
  const searchFunction = () => {
    var toastShown = false;
    if (fromDate && toDate) {
      if (fromDate > toDate) {
        if (!toastShown) {
          toast.error("To date should be greater than or equal to from date");
          document.getElementById("todate").focus()
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    if (filterName !== "" && searchValue === "") {
      if (!toastShown) {
        var t = document.getElementById("selectFilterName");
        var selectedText = t.options[t.selectedIndex].text;
        document.getElementById("searchText").focus();
        toast.error("Enter" + " " + selectedText);
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }

    let PostData = {
      "userId": window.sessionStorage.getItem("userId"),
      "roleName": window.sessionStorage.getItem("role"),
      "cmbSelect": filterName,
      "txtInput": searchValue,
      // "fromDate": "2022-07-25",
      // "toDate": "2022-07-30",
      "max": 10,
      "offset": 0,
      fromDate: fromDate ? formatDate(fromDate) : "",
      toDate: toDate ? formatDate(toDate) : "",
    };
    // setDataFlag(false);
    callingAPI("adminReports/receiptDetails", PostData).then((response) => {
      if (response.data.success === "1") {
        setTaxDetailsListData(response.data.result.receiptTax)
        setCount(response.data.result.totalCount);
        setDataFlag(true);
      }
      else if (response.data.success === "0") {
        setTaxDetailsListData([]);
        setActivePage(0);
      }
    })
  };
  //Date functions
  function formatDate(date) {
    var dates = new Date(date),
      month = "" + (dates.getMonth() + 1),
      day = "" + dates.getDate(),
      year = dates.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  //list function
  function patientListDownload() {
    const csvRow = [];
    var patientlist = [
      [
        "Patient%20Name",
        "Registration%20Date",
        "MR%20Number",
        "Mobile%20Number",
        "Email",
      ],
    ];
    var name = TaxDetailsListData;
    let PostData = {
      userId: window.sessionStorage.getItem("userId"),
      role: window.sessionStorage.getItem("role"),
      max: '',
      offset: 0,
      cmbSelect: filterName,
      txtInput: searchValue,
      hospitalId: localStorage.getItem("hospitalId"),
    };
    callingAPI("adminReports/receiptDetails", PostData).then((response) => {
      for (var item = 0; item < response.data.result.patientList.length; item++) {
        patientlist.push([
          response.data.result.patientList[item].patientName.replace(/ /g, '%20'),
          response.data.result.patientList[item].registrationDate.replace(/,/g, '%20').replace(/ /g, '%20'),
          response.data.result.patientList[item].memberMrNo,
          response.data.result.patientList[item].secUsermobNumber,
          response.data.result.patientList[item].secUseremail,
        ]);
      }
      for (var i = 0; i < patientlist.length; ++i) {
        csvRow.push(patientlist[i].join(","));
      }
      var csvString = csvRow.join("%0A");
      var creat = document.createElement("a");
      creat.href = "data:attachment/csv," + csvString;
      creat.target = "_Blank";
      creat.download = downloadName + ".csv";
      document.body.appendChild(creat);
      creat.click();
    })
  }

  //filter function
  const filterByPatient = (value) => {
    var toastShown = false;
    if (filterName == "3") {
      const re = /^[0-9\b]+$/;
      if (!re.test(value)) {
        if (!toastShown) {
          toast.error('Only Numbers Accepted');
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }

    }
    setsearchValue(value);
  };
  //change text
  const changeText = (value) => {
    setFilterInput(value);
    setsearchValue("");
    if (value === "") {
      setsearchValue("");
      document.getElementById("searchText").disabled = true;
    } else {
      document.getElementById("searchText").disabled = false;
      document.getElementById("searchText").focus();
    }
  };
  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      searchFunction();
    }
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const FromDateClear = () => {
    setfromtDate("")
    //document.getElementById("dashboardCover").click()
    // document.getElementById("fromdate").style.display ='none'
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>

        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  }
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  const ToDateClear = () => {
    settoDate("")
    // document.getElementById("fromdate").style.display ='none'
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE BEGINS HERE
  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div >
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear} >Clear  </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  // CLEAR & CLOSE BUTTON IN CALANDER ENABLED CODE ENDS HERE
  return (
    <div className="dashboardCover patientDetailsTable reciptTaxDetailsWrap" id="dashboardCover">
      <div className="dashboardTitle">Receipt Tax Details</div>

      <div>

        <div className="dashboardContent">

          <div className="reportWrap">
            <div className="dashboardSearch">
              <div className="row">
                <div className="col-md-2 dashboardSearchList">
                  <select
                    className="inputField"
                    id="selectFilterName"
                    title="Select Doctor"
                    name="selectDoctor"
                    value={filterName}
                    onKeyDown={onKeyDown}
                    onChange={(e) => changeText(e.target.value)}
                  >
                    <option value="">Search In</option>
                    <option value="1">Receipt No</option>
                  </select>
                </div>

                <div className="col-md-2 dashboardSearchList">
                  {filterName == "" ? (
                    <input
                      type="text"
                      className="inputField"
                      value={searchValue}
                      autoComplete='off'
                      onKeyDown={onKeyDown}
                      onChange={(e) => filterByPatient(e.target.value)}
                      id="searchText"
                      style={{ background: "transparent" }}
                      name="searchText"
                      disabled
                    />
                  ) : (
                    <input
                      type="text"
                      className="inputField"
                      value={searchValue}
                      autoComplete='off'
                      onKeyDown={onKeyDown}
                      onChange={(e) => filterByPatient(e.target.value)}
                      id="searchText"
                      name="searchText"
                      maxLength={filterName == "3" && "10"}
                      autofocus="autofocus"
                    // disabled
                    />
                  )}
                </div>
                <div className="col-md-2 dashboardSearchList">

                  <div>
                    <DatePicker
                      className="inputField"
                      autoComplete='off'
                      selected={fromDate}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="From"
                      // maxDate={new Date()}
                      onChange={(date) => setfromtDate(date)}
                      onKeyDown={onKeyDown}
                      id="fromdate"
                      showMonthDropdown
                      showYearDropdown
                      readonly="readonly"
                      dropdownMode="select"
                      calendarContainer={MyContainer}
                      onChangeRaw={handleDateChangeRaw}
                    />
                    <label
                      className="icon iconCalendar calendar plReportsCalendar"
                      style={{ fontSize: "16px" }}
                      htmlFor="fromdate"
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </label>
                  </div>

                </div>
                <div className="col-md-2 dashboardSearchList">

                  <div>
                    <DatePicker
                      className="inputField"
                      selected={toDate}
                      dateFormat="dd-MM-yyyy"
                      placeholderText="To"
                      // maxDate={new Date()}
                      onChange={(date) => settoDate(date)}
                      onKeyDown={onKeyDown}
                      id="todate"
                      showMonthDropdown
                      autoComplete='off'
                      showYearDropdown
                      dropdownMode="select"
                      calendarContainer={MyContainerTo}
                      onChangeRaw={handleDateChangeRaw}
                    />
                    <label
                      className="icon iconCalendar calendar plReportsCalendar"
                      style={{ fontSize: "16px" }}
                      htmlFor="todate"
                    >
                      <FontAwesomeIcon icon={faCalendarAlt} />
                    </label>
                  </div>

                </div>
                <div className="col-md-2 dashboardSearchList">
                  <span
                    className="dashButtonBorder"
                    onClick={searchFunction}
                    onKeyDown={onKeyDown}
                  >
                    Search
                  </span>
                </div>

                {!TaxDetailsListData.length ? (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey"
                      disabled
                    >
                      Export
                    </button>
                  </div>
                ) : (
                  <div className="col-md-2 dashboardSearchList">
                    <button
                      type="button"
                      style={{ width: "100%" }}
                      onClick={patientListDownload}
                      id="export"
                      className="dashButtonBorder dashButtonBorderGrey"
                    >
                      Export
                    </button>
                  </div>
                )}

              </div>

            </div>
            <div className="dashboardtable">
              {/*  */}
              <div class="reportTableCvr receiptTableDiv">
                <table class="receiptTable">
                  <thead>


                    <tr>
                      <th class="tableBorderBtm tablecontentCntr" style={{ borderBottom: 'none', color: "#000" }}>Date</th>
                      <th class="tableBorderBtm tablecontentCntr" style={{ borderBottom: 'none', color: "#000" }}>Receipt No.</th>
                      {TaxDetailsListdataTax.map((list, i) => (
                        <>
                          <th class="receiptTaxBox textCenter" colspan="2" style={{ textAlign: 'center', color: "#000" }}> {list.taxPercentage}%</th>
                        </>
                      ))}
                      {/* <th class="receiptTaxBox textCenter" colspan="2" style={{ textAlign: 'center', color: "#000" }}> 12.0%</th>

                      <th class="receiptTaxBox textCenter" colspan="2" style={{ textAlign: 'center', color: "#000" }}> 18.0%</th>

                      <th class="receiptTaxBox textCenter" colspan="2" style={{ textAlign: 'center', color: "#000" }}>GST 18.0%</th> */}

                      <th class="tableBorderBtm tablecontentCntr" style={{ borderBottom: 'none', color: "#000" }}>Total T. Value </th>
                      <th class="tableBorderBtm tablecontentCntr" style={{ borderBottom: 'none', color: "#000" }}>Total Tax</th>
                      <th class="tableBorderBtm tablecontentCntr" style={{ borderBottom: 'none', color: "#000" }}>Total Invoice</th>
                    </tr>
                    <tr>
                      <th class="tableBorderTop" style={{ borderTop: 'none' }}>&nbsp;</th>
                      <th class="tableBorderTop" style={{ borderTop: 'none' }}>&nbsp;</th>
                      {TaxDetailsListdataTax.map((list, i) => (
                        <>
                          <th class="receiptTaxBox">T Value</th>
                          <th class="receiptTaxBox">{list.taxName}</th>
                        </>
                      ))}
                      {/* <th class="receiptTaxBox">T Value</th>
                      <th class="receiptTaxBox">12.0%</th>

                      <th class="receiptTaxBox">T Value</th>
                      <th class="receiptTaxBox">18.0%</th>

                      <th class="receiptTaxBox">T Value</th>
                      <th class="receiptTaxBox">18.0%</th> */}

                      <th class="tableBorderTop" style={{ borderTop: 'none' }}>&nbsp;</th>
                      <th class="tableBorderTop" style={{ borderTop: 'none' }}>&nbsp;</th>
                      <th class="tableBorderTop" style={{ borderTop: 'none' }}>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* {TaxDetailsListData.filter((objec) => objec.taxPercent===5).map((list, k) => ( */}
                    {TaxDetailsListData.map((list, i) => (
                      <tr style={{ borderBottom: " 1px solid #757575" }}>

                        <td>
                          {list.createdDate}
                        </td>
                        <td>
                          {list.receiptNo}
                        </td>
                        {list.receiptDetailsList.filter((objec) => objec.taxPercent == 5).map((listss, k) => (
                          <>

                            <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                              ₹{parseFloat(listss.taxValue).toFixed(2)}
                            </td>
                            <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                              ₹{listss.amount}
                            </td>
                          </>
                        ))}

                        <td class="receiptTaxBox">2323</td>
                        <td class="receiptTaxBox">345</td>





                        <td class="receiptTaxBox">567</td>
                        <td class="receiptTaxBox">667756</td>
                        <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                          ₹84.75
                        </td>
                        <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                          ₹1.83
                        </td>

                        <td style={{ textAlign: "right", fontSize: '13px' }}>₹{parseFloat(list.totalTaxValue).toFixed(2)}</td>
                        <td style={{ textAlign: "right", fontSize: '13px' }}>₹{parseFloat(list.totalTax).toFixed(2)}</td>
                        <td style={{ textAlign: "right", fontSize: '13px' }}>₹{parseFloat(list.totalInvoice).toFixed(2)}</td>
                      </tr>

                    ))}
                    {/* <tr style={{ borderBottom: " 1px solid #757575" }}>
                      <td>
                        03, Aug 2022
                      </td>
                      <td>
                        RCP425
                      </td>

                      <td class="receiptTaxBox"></td>
                      <td class="receiptTaxBox"></td>
                      <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                        ₹1.79
                      </td>
                      <td class="receiptTaxBox" style={{ textAlign: "right" }}>
                        ₹0.21
                      </td>
                      <td class="receiptTaxBox"></td>
                      <td class="receiptTaxBox"></td>
                      <td class="receiptTaxBox"></td>
                      <td class="receiptTaxBox"></td>

                      <td style={{ textAlign: "right", fontSize: '13px' }}  >₹1.79</td>
                      <td style={{ textAlign: "right", fontSize: '13px' }}>₹0.21</td>
                      <td style={{ textAlign: "right", fontSize: '13px' }}>₹2.00</td>
                    </tr> */}


                  </tbody>
                </table>
              </div>
              {/*  */}


            </div>
          </div>



        </div>


      </div>

    </div>
  );
}
export default ReceiptTaxDetails;

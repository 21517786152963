import React, { useEffect, useState } from "react";
import PharmacyHeader from "./layout/header";
import Footer from "./layout/footer";
import { Route } from "react-router-dom";
import PharmacyLeft from "./pharmacyLeft";
import Datepicker, { CalendarContainer } from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import Pagination from "react-js-pagination";
import { callingAPI } from "../config";
import Loader from "../Common/loader";
import NoData from "../Common/noDataFound";
import eyeTable from "../images/icons/lab/eyeTable.png";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function UnavailableMedicine() {
  const [medicineName, setMedicineName] = useState([]);
  const [totalCount, setTotalCount] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [pageOffset, setPageOffset] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [functionName] = useState("Unavailable Medicine");
  const [searchValue, setSearchValue] = useState("");
  const [medicineResult, setMedicineResult123] = useState([]);
  const [booliansearchValue, setbooliansearchValue] = useState("");
  const [boolianfilterName, setboolianfilterName] = useState("");
  const [boolianfromDate, setboolianfromDate] = useState("");
  const [booliantoDate, setbooliantoDate] = useState("");
  const [isData, setIsData] = useState(0);
  const searchValueFunction = (event) => {
    setSearchValue(event.target.value);
    getPrecriptionMedicineAutoComplete();
  };
  const medicineNameClick = (name, id) => {
    setSearchValue(name);
    // setIdSearch(id)
    setMedicineResult123([]);
    document.getElementById("prescriptionMedicineList").style.display = "none";
  };
  const getPrecriptionMedicineAutoComplete = () => {
    const postData = {
      hospitalId: localStorage.getItem("hospitalId"),
      keyWordMedicine: document.getElementById("searchText").value,
    };
    if (document.getElementById("searchText").value.length > 0) {
      callingAPI("clinicalBackground/medicinesAutoComplete", postData).then(
        (data) => {
          if (data.data.success === "1") {
            document.getElementById("prescriptionMedicineList").style.display =
              "block";
            setMedicineResult123(data.data.medicineResult);
          } else {
            document.getElementById("prescriptionMedicineList").style.display =
              "none";
            setMedicineResult123([]);
          }
        }
      );
    }
  };
  const Listing = () => {
    let postData = {
      hospitalId: localStorage.getItem("hospitalId"),
      medicineName: "",
      fromDate: "",
      toDate: "",
      max: process.env.REACT_APP_PAGINATION_COUNT,
      offset: "0",
      role: window.sessionStorage.getItem("role"),
    };
    callingAPI("clinicalBackground/medicineUnAvailability", postData).then(
      (response) => {
        if (response.data.success === "1") {
          setMedicineName(response.data.medicineResult);
          setTotalCount(response.data.totalMedicineCount);
          setIsData(1);
        } else {
          setMedicineName([]);
          setTotalCount(0);
          setIsData(1);
        }
      }
    );
  };
  useEffect(() => {
    Listing();
  }, []);
  const handlePagination = (pageNumber) => {
    setActivePage(pageNumber);
    var offset;
    var max;
    if (pageNumber === "1") {
      offset = "0";
      max = process.env.REACT_APP_PAGINATION_COUNT;
    } else {
      offset =
        parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
      max = process.env.REACT_APP_PAGINATION_COUNT;
    }
    setActivePage(pageNumber);
    setPageOffset(offset);
    // if (!boolianfilterName) { setSearchType("") }
    // if (!booliansearchValue) { setSearchValue("") }
    if (!boolianfromDate) {
      setStartDate("");
    }
    if (!booliantoDate) {
      setEndDate("");
    }
    let postData = {
      // userId: window.sessionStorage.getItem('userId'),
      // role: window.sessionStorage.getItem('role'),
      // max: max,
      // offset: offset,
      // cmbSelect: boolianfilterName,
      // txtInput: booliansearchValue,
      // fromDate: boolianfromDate ? formatDate(startDate) : '',
      // toDate: booliantoDate ? formatDate(endDate) : '',
      // cmbRoomtype: dropDown,
      hospitalId: localStorage.getItem("hospitalId"),
      medicineName: "",
      fromDate: boolianfromDate ? formatDate(startDate) : "",
      toDate: booliantoDate ? formatDate(endDate) : "",
      max: max,
      offset: offset,
      role: window.sessionStorage.getItem("role"),
    };
    callingAPI("clinicalBackground/medicineUnAvailability", postData)
      .then((response) => {
        if (response.data.success === "1") {
          setMedicineName(response.data.medicineResult);
          setTotalCount(response.data.totalMedicineCount);
          setIsData(1);
        }
      })
      .catch();
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  const searchClickFunction = () => {
    var toastShown = false;
    if (startDate && endDate) {
      if (startDate > endDate) {
        if (!toastShown) {
          toast.error(" End date must be greater than or equal to start date");
          document.getElementById("todate").focus();
          toastShown = true;
          setTimeout(() => {
            toastShown = false;
          }, 3000);
        }
        return;
      }
    }
    setActivePage(1);
    setbooliansearchValue(searchValue);
    // setboolianfilterName(searchType);
    setboolianfromDate(startDate);
    setbooliantoDate(endDate);
    const postData = {
      // userId: window.sessionStorage.getItem('userId'),
      // role: window.sessionStorage.getItem('role'),
      // max: 10,
      // offset: 0,
      // cmbSelect: searchType,
      // txtInput: searchValue,
      // fromDate: startDate ? formatDate(startDate) : '',
      // toDate: endDate ? formatDate(endDate) : '',
      // cmbRoomtype: dropDown,
      hospitalId: localStorage.getItem("hospitalId"),
      medicineName: searchValue,
      fromDate: startDate ? formatDate(startDate) : "",
      toDate: endDate ? formatDate(endDate) : "",
      max: 10,
      offset: 0,
      role: window.sessionStorage.getItem("role"),
    };
    callingAPI("clinicalBackground/medicineUnAvailability", postData)
      .then((response) => {
        if (response.data.success === "0") {
          setMedicineName([]);
          setTotalCount(0);
        }
        if (response.data.success === "1") {
          setMedicineName(response.data.medicineResult);
          setTotalCount(response.data.totalMedicineCount);
          setIsData(1);
        }
      })
      .catch();
  };
  const onBlurItemCategory = (e) => {
    if (!["react-datepicker__month-select", "react-datepicker__year-select"].includes(String(e.target.className))) {
      document.getElementById("prescriptionMedicineList").style.display = "none";
      setMedicineResult123([]);
    }
  };

  const exportFunction = () => {
    var toastShown = false;
    if (medicineName.length === 0) {
      if (!toastShown) {
        toast.error("No Data to Export");
        toastShown = true;
        setTimeout(() => {
          toastShown = false;
        }, 3000);
      }
      return;
    }
    else {
      const csvRow = [];
      var unavailableArray = [["Medicine%20Name", "Prescribed%20Quantity"]];
      let postData = {
        hospitalId: localStorage.getItem("hospitalId"),
        medicineName: searchValue,
        fromDate: startDate ? formatDate(startDate) : "",
        toDate: endDate ? formatDate(endDate) : "",
        max: "",
        offset: 0,
        role: window.sessionStorage.getItem("role"),
      };
      callingAPI("clinicalBackground/medicineUnAvailability", postData).then(
        (response) => {
          for (var item = 0; item < response.data.medicineResult.length; item++) {
            unavailableArray.push([
              response.data.medicineResult[item].medName.replace(/ /g, "%20"),
              response.data.medicineResult[item].quantity,
            ]);
          }
          for (var i = 0; i < unavailableArray.length; ++i) {
            csvRow.push(unavailableArray[i].join(","));
          }
          var csvString = csvRow.join("%0A");
          var a = document.createElement("a");
          a.href = "data:attachment/csv," + csvString;
          // a.target = '_Blank';
          a.download = functionName + ".csv";
          document.body.appendChild(a);
          a.click();
        }
      );
    }
  };
  const FromDateClear = () => {
    setStartDate("");
  };
  const MyContainer = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={FromDateClear}>
              Clear{" "}
            </a>
          </span>
        </div>
      </CalendarContainer>
    );
  };
  const handleDateChangeRaw = (e) => {
    e.preventDefault();
  };
  const ToDateClear = () => {
    setEndDate("");
  };

  const MyContainerTo = ({ className, children }) => {
    return (
      <CalendarContainer className={className}>
        <div>
          <span className="clearCloseCalander">
            {children}
            <a className="clearCalander" onClick={ToDateClear}>
              Clear{" "}
            </a>
            {/* <a  className="closeCalander">Close</a> */}
          </span>
        </div>
      </CalendarContainer>
    );
  };
  return (
    <div className="dashboardCover pharmacyManufactureWrap">
      <ToastContainer position="bottom-right" />
      <div className="row">
        <div className="col-md-10">
          <div className="dashboardTitle">Unavailable Medicines Details</div>
        </div>
      </div>
      {totalCount > 10 && (
        <div className="paginationSection">
          <Pagination
            hideDisabled
            activePage={activePage}
            itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
            totalItemsCount={totalCount}
            pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
            onChange={handlePagination}
          />
        </div>
      )}
      <div className="dashboardContent">
        <div className="reportWrap">
          <div className="dashboardSearch" onClick={onBlurItemCategory}>
            <div className="row">
              <div className="col-md-2 dashboardSearchList">
                <input
                  type="text"
                  className="inputField"
                  id="searchText"
                  value={searchValue}
                  onChange={searchValueFunction}
                  onKeyUp={() =>
                    getPrecriptionMedicineAutoComplete(
                      "",
                      "medicineId",
                      "itemListDiv"
                    )
                  }
                  name="searchText"
                  placeholder="Medicine Name"
                />
                <ul
                  className="autoSearch autoSearch-p0 appointmentNumberSearch"
                  id="prescriptionMedicineList"
                  style={{ display: "none" }}
                >
                  {medicineResult.map((item, i) => (
                    <li
                      onClick={() =>
                        medicineNameClick(item.medName, item.medicineId)
                      }
                    >
                      {item.medName}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    id="fromdate"
                    calendarContainer={MyContainer}
                    onChangeRaw={handleDateChangeRaw}
                    required
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="Datepicker pa2 inputField"
                    placeholderText="From Date"
                    autoComplete="off"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="fromdate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div>
                  <Datepicker
                    required
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    id="todate"
                    calendarContainer={MyContainerTo}
                    onChangeRaw={handleDateChangeRaw}
                    className="Datepicker pa2 inputField"
                    placeholderText="To Date"
                    autoComplete="off"
                    calendarClassName="rasta-stripes"
                    dateFormat="dd-MM-yyyy"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                  <label
                    className="icon iconCalendar calendar plReportsCalendar"
                    style={{ fontSize: "16px" }}
                    htmlFor="todate"
                  >
                    <FontAwesomeIcon icon={faCalendarAlt} />
                  </label>
                </div>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder"
                  onClick={searchClickFunction}
                >
                  Search
                </span>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <span
                  className="dashButtonBorder"
                  onClick={exportFunction}
                  style={{ border: "1px solid gainsboro" }}
                >
                  Export
                </span>
              </div>
              <div className="col-md-2 dashboardSearchList">
                <div className="searchCount" style={{ margin: "0px" }}>
                  Total Medicine : {totalCount}
                </div>
              </div>
            </div>
          </div>
          <div className="dashboardtable">
            {isData == 0 ? (
              <Loader />
            ) : medicineName.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Medicine Name</th>
                    <th>Prescribed Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  {medicineName.map((data, i) => (
                    <tr style={{ borderBottom: "1px solid #cccc" }}>
                      <td>{data.medName}</td>
                      <td>{data.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <NoData />
            )}
          </div>
        </div>
        {totalCount > 10 && (
          <div className="paginationSection">
            <Pagination
              // hideNavigation
              hideDisabled
              activePage={activePage}
              itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
              totalItemsCount={totalCount}
              pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
              onChange={handlePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default UnavailableMedicine;

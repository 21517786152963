import React, { useState, useEffect } from 'react';
import Add from '../images/icons/add.png';
import Close from '../images/icons/close-sm.png';
import Editicon from '../images/icons/edit-bg.png';
import Delete from '../images/icons/delete_btn.png';
import Pagination from 'react-js-pagination';
import $ from "jquery";
import { callingAPI, callingPostAPI, callingGetAPI } from '../config';
import Tick from '../images/icons/tick.png';
import InActive from '../images/icons/close.png';
import NoData from '../Common/noDataFound';
import Loader from '../Common/loader';
import zIndex from '@material-ui/core/styles/zIndex';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AddLabWork() {
    const [dataFlag, setDataFlag] = useState(false);
    const [saveFlag, setSaveFlag] = useState(false);
    const [addLabWork, setAddLabWork] = useState([])
    const [labName, setLabName] = useState("");
    const [labNameSearch, setLabNameSearch] = useState("");
    const [categoryValues, setCategoryValues] = useState([]);
    const [labCategoryId, setlabCategoryId] = useState('0');
    const [dentalLabWorkNameId, setDentalLabWorkNameId] = useState("");
    const [count, setCount] = useState('');
    const [activePage, setActivePage] = useState(parseInt(window.sessionStorage.getItem('labTestactivePage')));
    const [pageOffset, setPageOffset] = useState(0);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [workTypeValues, setWorkTypeValues] = useState([]);
    const [selectedWorkType, setSelectedWorkType] = useState([]);
    const [selectedWorkTypeValue, setSelectedWorkTypeValue] = useState("");
    const [workTypeId, setWorkTypeId] = useState(0);
    const [workTypeName, setWorkTypeName] = useState("");

    const statusChange = (id, status) => {
        var x = false;
        if (status === true) {
            x = window.confirm('Do you want to Enable this?');
        } else {
            x = window.confirm('Do you want to Disable this?');
        }
        if (x === true) {
            const satusData = {
                dentalLabWorkNameId: id,
                dentalLabWorkNameStatus: status == false ? false : true,

            };

            callingAPI('commonUserFunctions/changeStatusDentalLabWorkNames', satusData).then((data) => {
                if (data.data.success === '1') {
                    toast.success('Status Changed Successfully');
                    labworkList();
                }
                if (data.data.success === '0') {
                    toast.error(data.data.errorMessage);
                }
            });
        }
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        var offset;
        var max;
        if (pageNumber === '1') {
            offset = '0';
            max = process.env.REACT_APP_PAGINATION_COUNT;
        } else {
            offset = parseInt(pageNumber - 1) * process.env.REACT_APP_PAGINATION_COUNT;
            max = process.env.REACT_APP_PAGINATION_COUNT;
        }
        // setActivePage(pageNumber);
        // setPageOffset(offset);
        window.sessionStorage.setItem("labTestactivePage", pageNumber)
        window.sessionStorage.setItem('labTestoffset', offset)
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": labNameSearch === "" ? "" : labNameSearch,
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": offset,
            "max": max
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {

            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);
            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();
    };
    const categoryChange = (event) => {
        const selectedCategoryId = event.target.value;
        const selectedWorkType = workTypeValues.find((item) => item.id === parseInt(selectedCategoryId));

        // Check if a work type is found
        if (selectedWorkType) {
            const selectedWorkTypeName = selectedWorkType.name;
            console.log("Selected Category ID:", selectedCategoryId);
            console.log("Selected Category Name:", selectedWorkTypeName);

            setlabCategoryId(selectedCategoryId);
            setWorkTypeId(selectedCategoryId);
            setWorkTypeName(selectedWorkTypeName);
        } else {
            console.log("No work type found for the selected category ID:", selectedCategoryId);
        }
    };

    const labWorkAddSave = () => {
        setIsButtonDisabled(true);
        let flags = true;
        var toastShown = false;
        if (document.getElementById('accountHead').value == '0') {
            if (!toastShown) {
                toast.error('Select Work Type');
                document.getElementById('accountHead').focus();
                setIsButtonDisabled(false);
                flags = false;
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        else if (!labName) {
            if (!toastShown) {
                toast.error('Enter New Work Name');
                document.getElementById('labName').focus();
                setIsButtonDisabled(false);
                flags = false;
                toastShown = true;
                setTimeout(() => {
                    toastShown = false;
                }, 3000);
            }
            return;
        }
        else {
            if (flags === true) {

                var a = selectedWorkType
                let postData = {
                    "dentalLabWorkNameId": dentalLabWorkNameId,
                    "dentalLabWorkName": labName,
                    "dentalLabWorkTypeId": workTypeId,
                    "workType": workTypeName,
                    "dentalLabWorkNameStatus": true,
                    "hospitalId": localStorage.getItem("hospitalId"),
                    "isActive": true
                }
                console.log("postData", postData)
                callingAPI('commonUserFunctions/saveDentalLabWorkNames', postData).then((response) => {

                    if (response.data.success === "1") {
                        labworkList();
                        setSelectedWorkType([])
                        // setSelectedWorkTypeValue("")
                        setlabCategoryId(0);
                        setWorkTypeId(0);
                        setWorkTypeName("");
                        setLabName("")
                        closePopup();
                        setDentalLabWorkNameId("")
                        if (dentalLabWorkNameId === "") {
                            toast.success("Added Successfully");
                            setIsButtonDisabled(false);
                        }
                        else {
                            toast.success("Updated Successfully");
                            setIsButtonDisabled(false);
                        }

                    }
                    else {
                        toast.error(response.data.errorMessage);
                    }
                })
            }
        }
    }

    const enterKey = (e) => {
        if (e.key === 'Enter') {
            searchValueChange();
        }
    }

    const searchValueChange = () => {
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": labNameSearch === "" ? "" : labNameSearch,
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": 0,
            "max": 10
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                window.sessionStorage.setItem("labTestactivePage", 1)
                window.sessionStorage.setItem('labTestoffset', 0)
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);


            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();

    }

    const labworkList = () => {
        const PostData = {
            "dentalLabWorkNameId": "",
            "dentalLabWorkName": "",
            "dentalLabWorkType": "",
            "dentalLabWorkNameStatus": "",
            "hospitalId": localStorage.getItem("hospitalId"),
            "offset": window.sessionStorage.getItem("labTestoffset"),
            "max": 10
        };
        callingAPI('commonUserFunctions/getDentalLabWorkNames', PostData).then((response) => {


            if (response.data.success === '1') {
                setDataFlag(true);
                setAddLabWork(response.data.result.dentalLabWorkNames);
                setCount(response.data.result.totalCount);


            }
            else {
                setDataFlag(true);
                setAddLabWork([]);
                setCount("");
            }
        })
            .catch();
    }
    const addLabWorkClick = () => {
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setSelectedWorkType("")
        //  setSelectedWorkTypeValue("")
        setLabName("")
        setlabCategoryId(0);
        setWorkTypeId(0);
        setWorkTypeName("");
        setDentalLabWorkNameId("")
    }
    const closePopup = () => {
        setSaveFlag(false)
        $("#plpopup1").css("visibility", "hidden");
        $("#plpopup1").css("opacity", "0");
    }
    const editLablist = (data) => {
        setSaveFlag(true)
        $("#plpopup1").css("visibility", "visible");
        $("#plpopup1").css("opacity", "1");
        setLabName(data.dentalLabWorkName);
        setlabCategoryId(data.dentalLabWorkTypeId);
        setSelectedWorkType(data.dentalLabWorkTypeId)
        //  setSelectedWorkTypeValue(data.dentalLabWorkTypeName)
        setDentalLabWorkNameId(data.dentalLabWorkNameId)
        setWorkTypeId(data.dentalLabWorkTypeId)
        setWorkTypeName(data.dentalLabWorkTypeName)


    }

    useEffect(() => {
        const PostData = {};
        callingAPI('commonUserFunctions/getDentalLabWorkTypes', PostData)
            .then((data) => {
                if (data.data.success === '1') {

                    setCategoryValues(data.data.result.dentalLabWorkNames);
                }
                else {
                    setCategoryValues([]);
                }

            })
            .catch();
        labworkList();
        workTypeOnchange();

    }, []);
    const workTypeOnchange = () => {
        // setSelectedWorkTypeValue(e)
        const PostData = {
            "searchInput": "",
            "max": "",
            "offset": "",
            "hospitalId": localStorage.getItem("hospitalId"),
        }
        callingAPI('commonUserFunctions/LabworkTypeList', PostData)
            .then((data) => {
                if (data.data.success === '1') {
                    if (data.data.result.labWorkTypeList.length > 0) {

                        var dataFormat = data.data.result.labWorkTypeList.filter((data) => data.is_active === true)
                        setWorkTypeValues(dataFormat)
                        // if (dataFormat.length > 0) {
                        //     document.getElementById("selectedWorkTypeValue").style.display = "block"
                        // }
                        // else {
                        //     document.getElementById("selectedWorkTypeValue").style.display = "none"
                        // }
                    }
                }
            })
    }
    const selectedWorkTypeOnChange = (e) => {
        setSelectedWorkType(e)
        setSelectedWorkTypeValue(e.name)
        document.getElementById("selectedWorkTypeValue").style.display = "none"

    }

    return (
        <div className="dashboardCover">
            <ToastContainer position="bottom-right" style={{ zIndex: "999999999" }} />
            <div className="row">
                <div className="col-md-10">
                    <div className="dashboardTitle">Work Name</div>
                </div>

                <div className="col-md-2">
                    <div onClick={() => { addLabWorkClick(); }} style={{ color: '#fff', textDecoration: 'none' }}>
                        <span
                            className="dashButtonBorder"
                            style={{ background: '#22a2fd', border: 'none', color: '#fff', padding: "6px 4px", fontSize: "13px" }}
                        >
                            Add New Work Name
                        </span>
                    </div>
                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}
            <div className="dashboardContent">
                <div className="reportWrap">
                    <div className="dashboardSearch">
                        <div className="row">
                            <div className="col-md-2 dashboardSearchList">
                                <input
                                    id="labNameSearch"
                                    type="text"
                                    className="inputField"
                                    placeholder='Enter Work Name'
                                    autoComplete="off"
                                    name='labNameSerach'
                                    value={labNameSearch}
                                    onChange={(e) => setLabNameSearch(e.target.value)}
                                />
                            </div>
                            <div className="col-md-2 dashboardSearchList">
                                <span className="dashButtonBorder"
                                    onClick={searchValueChange}
                                    title="Search"
                                    onKeyDown={enterKey}>
                                    Search
                                </span>
                            </div>
                        </div>
                    </div>
                    {dataFlag == false ? (
                        <Loader />
                    ) : addLabWork.length > 0 ? (
                        <div className="dashboardtable">
                            <table>
                                <thead>
                                    <th>Work Type</th>
                                    <th>Work Name</th>
                                    <th>Status</th>

                                    <th style={{ width: "10%" }}></th>
                                </thead>
                                <tbody>
                                    {addLabWork.map((data) => (
                                        <tr>
                                            <td className='vertical-middle'>{data.dentalLabWorkTypeName}</td>
                                            <td className='vertical-middle'>{data.dentalLabWorkName}</td>
                                            {data.dentalLabWorkNameStatus === 1 ? (<td className='vertical-middle'>Active</td>) : (<td className='vertical-middle'>Inactive</td>)}
                                            <td className='vertical-middle' style={{ width: "10%" }}>
                                                <img src={Editicon} onClick={() => editLablist(data)} alt="Edit" className='img-fluid' title='Edit' />
                                                {data.dentalLabWorkNameStatus === 1 ? (
                                                    <img
                                                        src={Tick}
                                                        alt="Active"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Deactivate"
                                                        onClick={() => statusChange(data.dentalLabWorkNameId, false)} />
                                                ) : (
                                                    <img
                                                        src={InActive}
                                                        alt="InActive"
                                                        className='img-fluid'
                                                        width={20}
                                                        title="Click to Activate"
                                                        onClick={() => statusChange(data.dentalLabWorkNameId, true)} />
                                                )}
                                            </td>

                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                            <br />
                        </div>
                    ) : (
                        <p>
                            <NoData />
                        </p>
                    )}
                </div>
            </div>
            {count > 10 && (
                <div className="paginationSection">
                    <Pagination
                        // hideNavigation
                        hideDisabled
                        activePage={activePage}
                        itemsCountPerPage={process.env.REACT_APP_PAGINATION_COUNT}
                        totalItemsCount={count}
                        pageRangeDisplayed={process.env.REACT_APP_PAGINATION_RANGE}
                        onChange={handlePageChange}
                    />
                </div>
            )}

            {/* Popup Begins */}
            <div id="plpopup1" class="ploverlay">
                <div class="plpopup addLabpopup">
                    <h2>Add New Work Name</h2>
                    <div class="close" onClick={closePopup}><span class="popUpClose">x</span></div>
                    <div class="plcontent">
                        <div class="addDataContent">

                            <div class="row">
                                <div className="col-md-3">
                                    <label>Work Type<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <select name="labCategoryId"
                                        id="accountHead"
                                        className='inputField'
                                        value={labCategoryId}
                                        onChange={categoryChange}
                                    >
                                        <option value="0">Select</option>
                                        {workTypeValues.map((item, idix) => (
                                            <option value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* <input type="text" id="accountHead" autoComplete='off' className='inputField' value={selectedWorkTypeValue} onChange={(e) => { workTypeOnchange(e.target.value) }} />
                                    <ul className='typeDropdwon-ul' id="selectedWorkTypeValue" style={{ display: "none" }}>
                                        {workTypeValues.map((data) => (
                                            <li onClick={(e) => { selectedWorkTypeOnChange(data) }}>{data.name}</li>))}
                                    </ul> */}
                                </div>
                            </div>
                            <div class="row">
                                <div className="col-md-3">
                                    <label>Work Name<span class="mandatory">*</span></label>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        className='inputField'
                                        placeholder="Enter New Work Name"
                                        id="labName"
                                        autoComplete="off"
                                        name='labName'
                                        value={labName}
                                        onChange={(e) => setLabName(e.target.value)} />
                                </div>

                            </div>
                            <div class="summaryBtnDiv">
                                {saveFlag === true ? <button type="submit" class="practiceGreenButton" onClick={labWorkAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Update</button> :
                                    <button type="submit" class="practiceGreenButton" onClick={labWorkAddSave} style={{ color: '#046b29' }} disabled={isButtonDisabled}>Save</button>}
                                <button type="submit" class="practiceGreenButton" onClick={closePopup} style={{ color: 'red', border: "1px solid red" }}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Popup Ends */}

        </div>
    );
}
export default AddLabWork;
